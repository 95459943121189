import { authConstants } from "../constants";
import moment from "moment";
import axios from "axios";
import { toast } from "react-toastify";

export const login = (formData) => {
  return {
    type: authConstants.LOGIN_REQUEST,
    formData,
  };
};

export const getDataObj = val => {
  if (val === 3) {
    return `<p>Veriflex :  100U</p>
    <p>Position :  A </p>
    <p>Runs : 1</p>
    <p>Width : 5700(mm)  </p>
    <p>Height : 2720(mm) </p>
    <p>VE: 4</p>
    <p>TE: 1</p>
    <p>Type of Operation : Manual</p>
    <p>Sound Rating : 50db</p>
    <p>Suspension : 2P</p>
    <p>Track :  R</p>
    <p>Surface Finish : MDF </p>
    <p>Parking Finish : Natural Anodized</p>
    <p>Parking Layout :  As per the drawing </p>`
  } else if (val === 4) {
    return ` <p>Fire Doors : Single leaf 2 Hours fire rated</p>
    <p>Profile : 100 x 57 x 1.6mm</p>
       <p>Shutter : 48mm x 1.2mm</p>
       <p>Infill : Honeycomb</p>
       <p>Material : Galvanised Steel</p>
       <p>Finish: Painted</p>`
  } else {
    return ""
  }
}

export const forgotPassword = (formData) => {
  return {
    type: authConstants.FORGOT_PASSWORD_REQUEST,
    formData,
  };
};

export const baseUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL;
// export const S3_root =   process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_S3_URL : process.env.REACT_APP_DEV_S3_URL;
// console.log(apiUrl);

export const S3_root = "https://idsgroup-uat.s3.ap-southeast-1.amazonaws.com/";

let charge = [];
let heading = '';
const header = [];
let title = '';
let groupDiscount = 0;


export const getRf = () => localStorage.getItem("validate");


export const setTitle = (str) => {
  title = str;
}

export const getTitle = () => title;
export const getTitleStr = () => localStorage.getItem("title");



export const setGroupDiscount = (val) => {
  groupDiscount = val;
}

export const getGroupDiscount = () => groupDiscount;

export const getHeading = (head) => {
  header.push(head);
  heading = head;
}

export const getHeader = () => {
  return heading;
}

export const getCharges = (charges) => {
  let arr = [];
  if (heading !== "" && header[0] !== "") {
    arr.push(charges);
    charge = arr;
  }
}


export const getCharge = () => {
  return charge;
}

let total;
let amount, gst;
export const getTotal = (val) => {
  total = val;
}

export const totalValue = () => {
  return total;
}

export const setTotal = (val) => {
  amount = val;
}


export const setGst = (val) => {
  gst = val;
}
export const getGst = () => gst;


export const getAmount = () => {
  return amount;
}

let pro = {};
export const setproduct = (values) => {
  pro = values;
}

export const getproduct = () => {
  return pro;
}

export const getIgnoreFields = (val) => {
  localStorage.setItem("ignore_fields", JSON.stringify(val));
}

export const ignoreData = () => {
  var retrievedData = localStorage.getItem("ignore_fields");
  var arr = JSON.parse(retrievedData);
  return arr;
}

export const changeDateFormat = (date) =>
  moment(date).format("DD MMM YYYY , hh:mm A");

export const changePoDateFormat = (date) =>
  moment(date).format("DD-MM-YYYY");


export const verifyDateFormat = (date) =>
  moment(date).format("DD/MM/YYYY");

export const effectiveDateFormat = (date) =>
  moment(date).format("MM/DD/YYYY");

export const dateFormat = (date) =>
  moment(date).format("YYYY,M,DD");

export const filterDateFormat = (date) =>
  moment(date).format("DD-MM-YYYY");

export const qutationDateFormat = (date) =>
  moment(date).format("MM/DD/YYYY");

export const changeStockName = (val) => {
  if (val <= 10 && val !== 0) {
    return "yelll_btn"
  } else if (val === 0) {
    return "redd_btn";
  } else {
    return "greenn_btn";
  }
}
export const checkStockName = (item) => {
  if (item.deliveredQuantity <= item.availableStock) {
    return "yelll_btn"
  } else if (item.availableStock === 0) {
    return "redd_btn";
  } else {
    return "greenn_btn";
  }
}

export const changePoClassName = (value) => {
  if (value === "DRAFT") {
    return "blue-btn";
  } else if (value === "SENT") {
    return "violet_btn";
  } else if (value === "CANCELLED") {
    return "cancelled-btn";
  } else if (value === "VERIFIED" || value === "CONVERTED") {
    return "sky_blue_btn";
  } else if (value === "PARTIALLY RECEIVED" || value === "RECEIVED") {
    return "lt_pink_btn";
  } else if (value === "PARTIALLY VERIFIED") {
    return "lt_sky_blue_btn";
  } else if (value === "GENERATED") {
    return "green-btn";
  } else if (value === "REVISED") {
    return "yellow-btn";
  }
  else {
    return "green-btn";
  }
};
export const changeClassName = (value) => {
  if (value === "DRAFT") {
    return "blue-btn";
  } else if (value === "CANCELLED") {
    return "cancelled-btn";
  } else if (value === "APPROVED") {
    return "green-btn";
  } else if (value === "SENT TO CUSTOMER") {
    return "royal-btn";
  } else if (value === "CONFIRMED") {
    return "pink-btn";
  } else if (value === "GENERATED") {
    return "green-btn";
  } else if (value === "REVISED") {
    return "yellow-btn";
  }
  else {
    return "green-btn";
  }
};
export const changeDashboardClassName = (value) => {
  if (value === "DRAFT") {
    return "draft-btn";
  } else if (value === "CANCELLED") {
    return "red-btn";
  } else if (value === "APPROVED") {
    return "green-btn";
  } else if (value === "SENT TO CUSTOMER") {
    return "royal-btn";
  } else if (value === "CONFIRMED") {
    return "pink-btn";
  } else if (value === "GENERATED") {
    return "green-btn";
  } else {
    return "green-btn";
  }
};
export const changeProjectClassName = (value) => {
  if (value === "Yet To Receive" || value === "Yet to Receive") {
    return "yellowd_btn";
  } else if (value === "PACKED") {
    return "sky_bluee_btn";
  } else if (value === "PARTIALLY PACKED") {
    return "lt_sky_blue_btn";
  } else if (value === "DELIVERED") {
    return "green-btn";
  } else {
    return "yellowd_btn";
  }
};
export const changeDeliverClassName = (value) => {
  if (value === "PARTIALLY RECEIVED") {
    return "partial_btn";
  } else if (value === "RECEIVED") {
    return "receive_btn";
  } else {
    return "yell_btn";
  }
};
export const projectProductClassName = (value) => {
  if (value === "Yet To Receive" || value === "Yet to Receive") {
    return "yell_btn";
  } else if (value === "PACKED") {
    return "sky_blue_btn";
  } else if (value === "PARTIALLY PACKED") {
    return "lt_sky_blue_btn";
  } else if (value === "DELIVERED") {
    return "green-btn";
  } else {
    return "yell_btn";
  }
};
export function camelize(str) {
  return str?.toLowerCase()
    .split(' ')
    .map(
      (word) => word[0]?.toUpperCase() + word?.slice(1)
    )
    .join(' ');
}
export function shortStr(str) {
  if (str?.includes("V")) {
    return str?.substr(str?.length - 9)
  } else {
    return str?.substr(str?.length - 6)
  }
}
let obj = {};

export const authToken = localStorage.getItem("authToken");
export const setProduct = (val) => obj = val;
export const getProduct = () => obj;


export const setProjet = (val) => obj = val;
export const getProjet = () => obj;


export const role = localStorage.getItem("userRole");

export const displayCurrency = (val = 0) => {
  return "₹ " + val.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
}


export const getExecutiveUrl = (pageNo, searchKey, filterData, sortField, sortType) => {
  filterData.page = pageNo;
  if (sortField !== "" && sortField !== undefined) {
    filterData.sortField = sortField;
    filterData.sortMethod = sortType;
  }
  if (searchKey !== "" && sortField !== undefined) {
    filterData.searchKey = searchKey;
  } else {
    filterData.searchKey = "";
  }
  let newurl = "";
  Object.keys(filterData).map(function (key) {
    if (filterData[key] !== "") {
      newurl += key + "=" + filterData[key] + "&";
    }
  });
  let updated_url = newurl.slice(0, -1);
  return updated_url;
}

export const downloadFile = ({ data, fileName, fileType }) => {
  const blob = new Blob([data], { type: fileType })
  const a = document.createElement('a')
  a.download = fileName
  a.href = window.URL.createObjectURL(blob)
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  })
  a.dispatchEvent(clickEvt)
  a.remove()
}

export const exportToJson = (dataCsv, fileName) => {
  downloadFile({
    data: dataCsv,
    fileName: fileName,
    fileType: 'text/csv',
  })
}
let inwardData = [];
// settingInwardData()
export const settingInwardData = (data) => {
  inwardData = data;
}

export const changeTitleStr = (val) => {
  if (val === "edit-quotation") {
    return "Edit Quotation"
  } else {
    return "Create Quotation";
  }
}

export const cancelBoq = async (id) => {
  const config = {
    headers: { Authorization: `Bearer ${authToken}` }
  };
  try {
    const response = await axios.put(`${baseUrl}staff/billOfQuantity/${id}/cancel`, {}, config);
    return response;
  } catch (error) {
    if (error.response) {
      toast(error.response);
      return error.response;
    } else if (error.request) { // no response
      return error.request;
    } else {
      console.log('Error', error.message);
    }
    console.log(error.config);
  }
};

export const gettingInwardData = () => inwardData;





