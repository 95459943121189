import React from 'react'
import { reduxForm, SubmissionError, reset } from 'redux-form';
import {
    getCustomersList,
    getBulkProducts,
    changeCoPage,
    setProduct,
    getCoProductList,
    getCoProjects,
    changeCoEditPage,
    createCustomer,
    updateCoOutward,
    getVendorList,
    createVendor,
    getVendorProductList,
    updateOutwardReturnproduct,
    getVendorProjects,
    getBoqProductDetails,
    previewPackingSlip
} from '../../../actions';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Row, Col, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import plus from "../../../assets/images/pl.svg";
import plus2 from "../../../assets/images/plus-circle2.svg";
import CustomerForm from '../../customers/CustomerForm';
import AddProduct from './AddProduct';
import Select from 'react-select';
import ViewProducts from '../../purchaseOrder/createpurchaseorder/productdetails/ViewProducts';
import { toast } from 'react-toastify';
import VendorForm from '../../vendor/VendorForm';
import PackingSlip from '../../purchaseOrder/billofquantity/PackingSlip';

class CounterSale extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            customerId: "",
            vendorId: "",
            customerShow: false,
            counterId: "",
            sample: false,
            vendorShow: false,
            checked: false,
            productPopup: false,
            load: false,
            deleteStr: "co",
            str: "co",
            projects: [],
            projectId: null,
            remark: "",
            empty: "",
            productList: [],
            open: false,
            productItem: [],
            returnId: "",
            packingPopup: false,
        }
        this.customerChange = this.customerChange.bind(this);
        this.inputChange = this.inputChange.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.productOpen = this.productOpen.bind(this);
        this.productClose = this.productClose.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.getCounterList = this.getCounterList.bind(this);
        this.closeProductBody = this.closeProductBody.bind(this);
        this.productHandler = this.productHandler.bind(this);
        this.addCustomerHandler = this.addCustomerHandler.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
        this.selectHandler = this.selectHandler.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.remarkHandler = this.remarkHandler.bind(this);
        this.sampleHandler = this.sampleHandler.bind(this);
        this.packingSlipShow = this.packingSlipShow.bind(this);
        this.packingSlipClose = this.packingSlipClose.bind(this);
    }
    handleSubmit(formData, dispatch) {
        if (!formData.firstName) {
            throw new SubmissionError({
                firstName: 'Please fill the field *',
            })
        } else if (formData?.firstName?.trim().length === 0) {
            throw new SubmissionError({
                firstName: 'Please fill the field *',
            })
        }
        if (formData) {
            this.props.dispatch(createVendor(formData));
            dispatch(reset("vendorForm"));
        } else {
            toast("Please fill all the required fields");
        }
    }
    closeProductBody() {
        this.setState({
            open: !this.state.open
        })
    }
    changeHandler(e) {
        this.setState({
            projectId: e.value
        })
    }
    remarkHandler(e) {
        this.setState({
            remark: e.target.value
        })
    }
    selectHandler(e) {
        this.setState({
            checked: e.target.checked,
        })
        if (e.target.checked) {
            this.setState({
                deleteStr: "return",
                productList: []
            })
        } else {
            this.setState({
                deleteStr: "co",
                productList: []
            })
        }
    }
    sampleHandler(e) {
        this.setState({
            sample: e.target.checked,
        })
    }
    productOpen() {
        this.props.dispatch(changeCoPage());
        setProduct({});
        const { sample, remark, customerId, vendorId, checked } = this.state;
        if (checked) {
            if (vendorId === "") {
                toast("Please Select Vendor");
                return false;
            }
        } else {
            if (customerId === "") {
                toast("Please Select Customer");
                return false;
            }
        }
        if (sample) {
            if (remark === "") {
                toast("Pls enter remarks..!!");
            } else {
                this.setState({
                    str: "add",
                    productPopup: true,
                    deleteStr: "return"
                })
            }
        } else {
            this.setState({
                str: "add",
                productPopup: true,
                deleteStr: "co"
            })
        }
        this.props.dispatch(getBulkProducts(""));
    }
    productClose() {
        this.setState({
            productPopup: false,
        })
    }
    productHandler(values) {
        const { checked } = this.state;
        if (checked) {
            let data = {
                'articleId': values?.articleId,
                'productName': values?.productName,
                'quantity': values.outwardReturnQuantity,
                'unit': values?.unit,
                'productId': values?.productId,
                'counterSaleProductId': values?.outwardReturnProductId,
                'availableStock': values?.totalStock,
            }
            setProduct(data);
            this.setState({
                productPopup: true,
                str: "edit",
                productItem: data,
                deleteStr: "return"
            })
        } else {
            setProduct(values);
            this.setState({
                productPopup: true,
                str: "edit",
                productItem: values,
                deleteStr: "co"
            })
        }
        this.props.dispatch(changeCoEditPage());

    }
    handleShow() {
        const { checked } = this.state;
        if (checked) {
            this.setState({
                vendorShow: true,
                empty: "create"
            });
        } else {
            this.setState({
                customerShow: true,
                empty: "create"
            });
        }
    }
    handleClose() {
        const { checked } = this.state;
        if (checked) {
            this.setState({
                vendorShow: false,
                empty: ""
            });
        } else {
            this.setState({
                customerShow: false,
                empty: ""
            });
        }
    }
    packingSlipShow() {
        this.setState({ packingPopup: true });
    }
    packingSlipClose() {
        this.props.onHide();
        this.setState({ packingPopup: false });
    }
    getCounterList(id) {
        const { vendorId, checked } = this.state;
        if (checked) {
            let formData = {
                vendorId: vendorId
            }
            this.props.dispatch(getVendorProductList(formData));
        } else {
            let formData = {
                customerId: id
            }
            this.props.dispatch(getCoProductList(formData));
        }
    }
    addCustomerHandler(formData) {
        if (!formData.customerInitials) {
            throw new SubmissionError({
                customerInitials: 'Please fill the field *',
            })
        } else if (!formData.firstName) {
            throw new SubmissionError({
                firstName: 'Please fill the field *',
            })
        }

        this.props.dispatch(createCustomer(formData));
    }
    customerChange(e) {
        const { checked } = this.state;
        if (checked) {
            this.props.allData?.content?.map((item) => {
                if (item.name === e[0]) {
                    this.setState({
                        vendorId: item.vendorId
                    })
                    let formData = {
                        vendorId: item.vendorId
                    }
                    this.props.dispatch(getVendorProductList(formData));
                    this.props.dispatch(getVendorProjects(item.vendorId));
                }
            });
        } else {
            this.props.customerList?.map((item) => {
                if (item.customerName === e[0]) {
                    this.getCounterList(item.customerId);
                    this.props.dispatch(getCoProjects(item.customerId))
                    this.setState({
                        customerId: item.customerId
                    })
                }
            });
        }
    }
    inputChange(text, e) {
        const { checked } = this.state;
        if (checked) {
            this.props.dispatch(getVendorList(0, text));
        } else {
            this.props.dispatch(getCustomersList(0, text));
        }
    }
    submitHandler() {
        const { productList, customerId, projectId, vendorId, checked, remark, counterId, returnId } = this.state;
        if (productList?.length === 0 || productList === null) {
            toast("No products Added");
            return false;
        }
        let pro = [];
        if (checked) {
            productList?.map((item, i) => {
                let items = {};
                items["productId"] = item.productId;
                items["outwardReturnQuantity"] = item.outwardReturnQuantity;
                pro.push(items);
            });
            let formData = {
                "vendorId": vendorId,
                "returnRemark": remark,
                "purchaseOrderProjectId": projectId,
                productDetail: pro
            };
            this.setState({
                load: true
            })
            this.props.dispatch(updateOutwardReturnproduct(returnId));
        } else {
            productList?.map((item, i) => {
                let items = {};
                items["projectProductId"] = projectId
                items["counterSaleProductId"] = item.counterSaleProductId;
                items["productId"] = item.productId;
                items["outwardQuantity"] = item.quantity;
                pro.push(items);
            });
            let formData = {
                "customerId": customerId,
                "counterId": counterId,
                "purchaseOrderProjectId": projectId,
                productDetail: pro
            };
            this.setState({
                load: true
            })
            this.props.dispatch(updateCoOutward(formData));
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.coProductList !== this.props.coProductList) {
            const { coProductList } = this.props;
            this.setState({
                productList: coProductList?.productResDtoList,
                counterId: coProductList?.counterId,
                projectId: coProductList?.projectId
            })
        } if (prevProps.coProjectList !== this.props.coProjectList) {
            const { coProjectList } = this.props;
            let items = [];
            coProjectList !== undefined && coProjectList?.length > 0 && coProjectList.map((item) => {
                let data = {};
                data["value"] = item.purchaseProjectId;
                data["label"] = item.projectName
                items.push(data);
            })
            this.setState({
                projects: items
            })
        } if (prevProps.vendorProjectList !== this.props.vendorProjectList) {
            const { vendorProjectList } = this.props;
            let items = [];
            vendorProjectList !== undefined && vendorProjectList?.length > 0 && vendorProjectList.map((item) => {
                let data = {};
                data["value"] = item.purchaseProjectId;
                data["label"] = item.projectName
                items.push(data);
            })
            this.setState({
                projects: items
            })
        } if (prevProps.customer_msg !== this.props.customer_msg) {
            this.setState({
                customerShow: false,
            });
            if (this.props.customer_msg.status === 200) {
                toast(this.props.customer_msg.data);
                this.props.dispatch(getCustomersList(0));
            } else {
                toast(this.props.customer_msg.data);
            }
        } if (prevProps.success_msg !== this.props.success_msg) {
            this.setState({
                vendorShow: false,
            });
            if (this.props.success_msg.status === 200) {
                toast(this.props.success_msg.data);
                this.props.dispatch(getVendorList(0));
            } else {
                toast(this.props.success_msg.data);
            }
        } if (prevProps.outwardSuccess !== this.props.outwardSuccess) {
            const { onHide, outwardSuccess } = this.props;
            this.setState({
                load: false,
            })
            if (this.props.outwardSuccess.status === 200) {
                this.setState({
                    productList: []
                })
                this.props.dispatch(getBoqProductDetails(outwardSuccess?.data?.packingReferenceId));
                this.props.dispatch(previewPackingSlip(outwardSuccess?.data?.packingReferenceId));
                this.packingSlipShow();
                toast(this.props.outwardSuccess.data.message);
            } else {
                toast(this.props.outwardSuccess.data);
            }
        } if (prevProps.outwardProductSuccess !== this.props.outwardProductSuccess) {
            const { onHide, outwardProductSuccess } = this.props;
            this.setState({
                load: false,
            })
            if (this.props.outwardProductSuccess.status === 200) {
                this.setState({
                    productList: []
                })
                this.packingSlipShow();
                this.props.dispatch(getBoqProductDetails(outwardProductSuccess?.data?.packingReferenceId));
                this.props.dispatch(previewPackingSlip(outwardProductSuccess?.data?.packingReferenceId));
                toast(this.props.outwardProductSuccess.data);
            } else {
                toast(this.props.outwardProductSuccess.data);
            }
        } if (prevProps.vendorProductList !== this.props.vendorProductList) {
            const { vendorProductList } = this.props;
            this.setState({
                productList: vendorProductList?.productResDtoList,
                returnId: vendorProductList?.outwardReturnId,
                projectId: vendorProductList?.projectId,
                remark: vendorProductList?.remark
            })
        }
    }

    render() {
        const { handleShow, onHide, show, customerList, allData } = this.props;
        const { customerShow, productPopup, str, customerId, projects, projectId, productList, open, checked, vendorShow, vendorId, remark, deleteStr, sample, load, returnId, packingPopup } = this.state;

        return (
            <>
                <button className="add-stock" onClick={handleShow}><img src={plus} alt=".." />Counter Sale</button>
                <Modal
                    show={show}
                    size="xl"
                    onHide={() => {
                        onHide();
                        this.setState({
                            productList: [],
                            checked: false,
                        })
                    }}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <div className="modal-staff">
                        <div className="head">
                            <div>
                                <i class="fas fa-arrow-left" onClick={() => {
                                    onHide();
                                }}></i>
                                <h4>OUTWARD PRODUCT</h4>
                            </div>
                            <div>
                                <div className="customer-check">
                                    <input type="checkbox" onChange={this.selectHandler} style={{ margin: "8px" }} />
                                    <label className="stock_labell">Return</label>
                                </div>
                            </div>
                        </div>
                        <Row>
                            <Col md={8}>
                                {checked ?
                                    allData?.content?.length >= 0 ? <Typeahead
                                        onChange={this.customerChange}
                                        id="async-example"
                                        style={{ margin: "20px" }}
                                        labelKey={allData?.content?.map(item => item.name)}
                                        onInputChange={this.inputChange}
                                        options={allData?.content?.map(item => item.name)}
                                        placeholder="Choose a Vendor..."
                                    /> : null
                                    : customerList?.length >= 0 ? <Typeahead
                                        onChange={this.customerChange}
                                        id="async-example"
                                        style={{ margin: "20px" }}
                                        labelKey={customerList?.map(item => item.customerName)}
                                        onInputChange={this.inputChange}
                                        options={customerList?.map(item => item.customerName)}
                                        placeholder="Choose a Customer..."
                                    /> : null}
                                <div className="triangle-bottom" style={{ margin: "-3px 20px 0 0" }}></div>
                            </Col>
                            <span className="customer-span" style={{ margin: "26px 10px 0 -10px" }}>Or</span>
                            <Col md={3}>
                                {checked ? <button className="modal-btn" style={{ marginTop: "20px", width: "310px" }} onClick={(e) => this.handleShow()}>
                                    <img src={plus} alt="" />
                                    Add New Vendor
                                </button>
                                    : <button className="modal-btn" style={{ marginTop: "20px", width: "310px" }} onClick={(e) => this.handleShow()}>
                                        <img src={plus} alt="" />
                                        Add New Customer
                                    </button>}
                            </Col>
                        </Row>
                        <div className="table-class add-padd" >
                            <Row>
                                <Col md={1}>
                                    <div className="customer-check txt-center" >
                                        <input type="checkbox" onChange={this.sampleHandler} />
                                        <label className="stock_label">Sample</label>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    {sample ?
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder='Enter Remark...'
                                            defaultValue={remark}
                                            onChange={this.remarkHandler}
                                        />
                                        : <Select
                                            placeholder="Select Project"
                                            value={projects?.filter(({ value }) => value === projectId)}
                                            onChange={this.changeHandler}
                                            options={projects}
                                            isSearchable={true}
                                        />}
                                </Col>
                                <Col md={3} className='co_span'>
                                    <span>No.Of Products</span>
                                    <p >{productList?.length > 0 ? productList?.length : 0}</p>
                                </Col>
                                <Col md={2}>
                                    <button className='add_po_btn' style={{ width: "100%" }} onClick={() => this.productOpen()}>
                                        <img src={plus} alt=".../" />
                                        Add Product
                                    </button>
                                </Col>
                                <Col md={2}>
                                    <button className='view_po_btn' style={{ width: "100%" }} onClick={this.closeProductBody}>
                                        {open ? "Hide Products" : "View Products"}
                                    </button>
                                </Col>

                                <Col md={12} >
                                    {open ? <>
                                        <ViewProducts
                                            data={productList}
                                            str={deleteStr}
                                            handleShow={this.productHandler}
                                        />
                                        <div className='blue_body' >
                                            <button onClick={() => {
                                                this.productOpen();
                                            }} className="group_product_btn" style={{ marginRight: "30px" }}>
                                                <img src={plus2} alt="" />
                                                Add Product</button>
                                        </div>
                                    </> : null}
                                </Col>
                            </Row>
                        </div>
                        <center style={{ padding: "15px" }}>
                            {!load ? <button className="customer-btn col-md-6" onClick={() => this.submitHandler()}>Outward</button> :
                                <button className="customer-btn col-md-6">Loading....</button>}
                        </center>
                        <CustomerForm
                            show={customerShow}
                            onHide={this.handleClose}
                            onSubmit={this.addCustomerHandler} />
                        <div style={{ display: "none" }}>
                            <VendorForm
                                // handleShow={this.handleShow}
                                show={vendorShow}
                                onHide={this.handleClose}
                                vendorbutton="vendorbutton"
                                onSubmit={this.handleSubmit}
                            />
                        </div>
                        <AddProduct
                            show={productPopup}
                            onHide={this.productClose}
                            id={customerId}
                            vendorId={vendorId}
                            str={str}
                            getData={this.getCounterList}
                            projectId={projectId}
                            checked={checked}
                            remark={remark}
                            returnId={returnId}
                        />
                        <PackingSlip
                            show={packingPopup}
                            onHide={this.packingSlipClose}
                        />
                    </div>
                </Modal>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        customerList: state.customer.customers_list,
        coProductList: state.outward.coProductList,
        vendorProductList: state.outward.vendorProductList,
        coProjectList: state.outward.coProjectList,
        vendorProjectList: state.outward.vendorProjectList,
        customer_msg: state.customer.success_msg,
        success_msg: state.vendor.success_msg,
        outwardSuccess: state.outward.outwardSuccess,
        outwardProductSuccess: state.outward.outwardProductSuccess,
        allData: state.vendor.allData,
    }
}

const Form = reduxForm({
    form: "CounterSale",
    destroyOnUnmount: false,
    enableReinitialize: true,
    // validate
})(CounterSale);

export default connect(mapStateToProps)(Form);
