import React from 'react';
import validate from '../validate';
import { Field, reduxForm } from 'redux-form';
import { Row, Col, Form } from "react-bootstrap";
import { connect } from "react-redux";
import {
    createProduct,
    getAllUnits,
    addQuotationProducts,
} from "../../../../actions";
import { SubmissionError } from 'redux-form';
import { Editor } from "@tinymce/tinymce-react";
import plus from "../../../../assets/images/plus-circle2.svg";
import back from "../../../../assets/images/out.svg";
import { toast } from 'react-toastify';
import { editGroupCustomProduct, getUnitItems } from '../services/servics';

const renderField = ({ input, placeholder, maxLength, type, readOnly, meta: { touched, error } }) => (
    <div>
        <label></label>
        <div>
            <input {...input} placeholder={placeholder} type={type} className="form-control" maxLength={maxLength} readOnly={readOnly} />
            {touched && error && <p className="require" >{error}</p>}
        </div>
    </div>
);

const renderSelectField = ({ input, meta: { touched, error }, children }) => (
    <div>
        <div>
            <select {...input} className='form-control form-select mt-4'>
                {children}
            </select>
            {touched && error && <p className="require">{error}</p>}
        </div>
    </div>
)

class CustomProducts extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            quantity: 0,
            discount: 0,
            total: 0,
            productId: "",
            dataObj: "",
            productId: "",
            height: 200,
            units_list: [],
            unitCost: 0,
            articleId: "",
            edit: false,
            unitId: "",
            disabled: true
        }
        this.getQuantity = this.getQuantity.bind(this);
        this.getDiscount = this.getDiscount.bind(this);
        this.addProduct = this.addProduct.bind(this);
        this.getUnits = this.getUnits.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getUnitCost = this.getUnitCost.bind(this);
        this.scrollToDiv = this.scrollToDiv.bind(this);
    }
    async getUnits() {
        var footerData = await getUnitItems();
        if (footerData?.data?.status === 200) {
            this.setState({
                units_list: footerData?.data?.data
            })
            if (this.props.id == 3) {
                this.setState({
                    height: 370,
                    total: 0,
                    dataObj:
                        `<p>Veriflex :  100U</p>
                    <p>Position :  A </p>
                    <p>Runs : 1</p>
                    <p>Width : 5700(mm)  </p>
                    <p>Height : 2720(mm) </p>
                    <p>VE: 4</p>
                    <p>TE: 1</p>
                    <p>Type of Operation : Manual</p>
                    <p>Sound Rating : 50db</p>
                    <p>Suspension : 2P</p>
                    <p>Track :  R</p>
                    <p>Surface Finish : MDF </p>
                    <p>Parking Finish : Natural Anodized</p>
                    <p>Parking Layout :  As per the drawing </p>`
                })
            } else if (this.props.id == 4) {
                this.setState({
                    height: 270,
                    total: 0,
                    dataObj:
                        ` <p>Fire Doors : Single leaf 2 Hours fire rated</p>
                    <p>Profile : 100 x 57 x 1.6mm</p>
                       <p>Shutter : 48mm x 1.2mm</p>
                       <p>Infill : Honeycomb</p>
                       <p>Material : Galvanised Steel</p>
                       <p>Finish: Painted</p>`
                })
            } else {
                this.setState({
                    dataObj: ""
                })
            }
        }
    }
    getUnitCost(e) {
        this.setState({
            unitCost: e.target.value
        })
        const { initialValues } = this.props;
        const { discount, quantity } = this.state;
        let qua = quantity === 0 ? initialValues.quantity : quantity;
        let dis = discount === 0 ? initialValues?.discount : discount;
        let discount1 = (e.target.value * Number(dis * qua)) / 100;
        this.setState({
            total: (e.target.value * qua) - discount1
        })
    }
    getQuantity(e) {
        this.setState({
            quantity: e.target.value
        })
        const { initialValues } = this.props;
        const { discount, unitCost } = this.state;
        let unit = unitCost === 0 ? initialValues.unitCost : unitCost;
        let dis = discount === 0 ? initialValues?.discount : discount;
        let discount1 = (unit * Number(dis * e.target.value)) / 100;
        this.setState({
            total: (unit * e.target.value) - discount1
        })
    }
    getDiscount(e) {
        this.setState({
            discount: e.target.value
        })
        const { quantity, unitCost } = this.state;
        const { initialValues } = this.props;
        let amount = unitCost === 0 ? initialValues?.unitCost : unitCost;
        let qua = quantity === 0 ? initialValues?.quantity : quantity;
        let discount1 = (amount * Number(e.target.value * qua)) / 100;
        this.setState({
            total: (amount * qua) - discount1
        })
    }
    handleChange(content) {
        this.setState({
            dataObj: content,
            edit: true
        })
    }
    scrollToDiv = () => {
        const div = document.getElementById('table-bluee');
        if (div !== null) {
            document.getElementById('table-bluee').scrollIntoView({ behavior: 'smooth', block: "start" });
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.units_list !== this.props.units_list) {
            this.setState({
                units_list: this.props.units_list,
            });
            if (!this.state.edit) {
                if (this.props.id === 3) {
                    this.setState({
                        height: 370,
                        total: 0,
                        dataObj:
                            `<p>Veriflex :  100U</p>
                    <p>Position :  A </p>
                    <p>Runs : 1</p>
                    <p>Width : 5700(mm)  </p>
                    <p>Height : 2720(mm) </p>
                    <p>VE: 4</p>
                    <p>TE: 1</p>
                    <p>Type of Operation : Manual</p>
                    <p>Sound Rating : 50db</p>
                    <p>Suspension : 2P</p>
                    <p>Track :  R</p>
                    <p>Surface Finish : MDF </p>
                    <p>Parking Finish : Natural Anodized</p>
                    <p>Parking Layout :  As per the drawing </p>`
                    })

                } else if (this.props.id === 4) {
                    this.setState({
                        height: 270,
                        total: 0,
                        dataObj:
                            ` <p>Fire Doors : Single leaf 2 Hours fire rated</p>
                    <p>Profile : 100 x 57 x 1.6mm</p>
                       <p>Shutter : 48mm x 1.2mm</p>
                       <p>Infill : Honeycomb</p>
                       <p>Material : Galvanised Steel</p>
                       <p>Finish: Painted</p>`
                    })
                } else {
                    this.setState({
                        dataObj: ""
                    })
                }
            }
        } if (prevProps.groupProductsSuccess !== this.props.groupProductsSuccess) {
            this.props.getAmounts();
            this.props.reset();
            this.getUnits();
            this.setState({
                groupProductsSuccess: this.props.groupProductsSuccess,
                disabled: true,
                edit: false,
                total: 0,
                dataObj: "",
                discount: 0,
                articleId: "",
                unitId: "",
                unitCost: 0,
            });
            if (this.props.id == 3) {
                this.setState({
                    height: 370,
                    total: 0,
                    dataObj:
                        `<p>Veriflex :  100U</p>
                    <p>Position :  A </p>
                    <p>Runs : 1</p>
                    <p>Width : 5700(mm)  </p>
                    <p>Height : 2720(mm) </p>
                    <p>VE: 4</p>
                    <p>TE: 1</p>
                    <p>Type of Operation : Manual</p>
                    <p>Sound Rating : 50db</p>
                    <p>Suspension : 2P</p>
                    <p>Track :  R</p>
                    <p>Surface Finish : MDF </p>
                    <p>Parking Finish : Natural Anodized</p>
                    <p>Parking Layout :  As per the drawing </p>`
                })
            } else if (this.props.id == 4) {
                this.setState({
                    height: 270,
                    total: 0,
                    dataObj:
                        ` <p>Fire Doors : Single leaf 2 Hours fire rated</p>
                    <p>Profile : 100 x 57 x 1.6mm</p>
                       <p>Shutter : 48mm x 1.2mm</p>
                       <p>Infill : Honeycomb</p>
                       <p>Material : Galvanised Steel</p>
                       <p>Finish: Painted</p>`
                })
            } else {
                this.setState({
                    dataObj: ""
                })
            }
        } if (prevProps.success_msg !== this.props.success_msg) {
            const { success_msg } = this.props;
            if (success_msg.status == 200) {
                let submitData = success_msg.data;
                this.setState({
                    edit: false
                })
                if (submitData?.productId !== undefined) {
                    let data = {
                        "groupId": this.props.groupId,
                        "productId": submitData.productId,
                        "quantity": this.state.quantity,
                        "discount": this.state.discount,
                        "unitCost": this.state.unitCost,
                        "articleId": this.state.articleId,
                        "totalCost": this.state.total,
                        "unitId": this.state.unitId
                    }
                    this.props.dispatch(getAllUnits());
                    this.props.dispatch(addQuotationProducts(data, this.props.groupId));
                }
            } else {
                toast(success_msg.data);
            }
        } if (prevProps.singleProduct !== this.props.singleProduct && this.props.str === "edit") {
            if (this.props.str === "edit") {
                this.setState({
                    dataObj: this.props.initialValues?.productName
                })
            }
        }
    }
    async addProduct(data) {
        var formData = new FormData();
        if (this.state.dataObj === "") {
            toast("Please Enter a Product Name");
            return false;
        }
        if (this.state.dataObj.length === 0) {
            toast("Please Enter a Product Name");
            return false;
        }
        if (data.articleId !== undefined) {
            formData.append('articleId', data.articleId);
        } if (!data.quantity) {
            throw new SubmissionError({
                quantity: 'Please fill the field *',
            })
        } else if (!data.unitCost) {
            throw new SubmissionError({
                unitCost: 'Please fill the field *',
            })
        }
        if (this.props.str === "edit") {
            let formData = {
                "productGroupId": this.props.initialValues?.groupProductId,
                "productName": this.state.dataObj,
                "unitCost": Number(data.unitCost),
                "quantity": Number(data.quantity),
                "discount": Number(data.discount),
            }
            var productData = await editGroupCustomProduct(formData);
            if (productData?.data?.status === 200) {
                toast(productData.data.data);
                this.setState({
                    dataObj: "",
                    total: 0,
                    unitCost: 0,
                    discount: 0,
                })
                this.props.onHide();
                this.props.getList();
            } else {
                toast(productData.data.data);
            }
        } else {
            if (!data.unitId) {
                throw new SubmissionError({
                    unitId: 'Please fill the field *',
                })
            }
            let name = this.state.dataObj;
            formData.append('groupId', this.props.groupId);
            formData.append('quantity', data.quantity);
            formData.append('discount', data.discount);
            formData.append('productName', name);
            formData.append("unitCost", data.unitCost);
            formData.append('totalCost', this.state.total);
            formData.append('productType', 'dynamic');
            formData.append("unitId", data.unitId);
            this.props.dispatch(createProduct(formData));
            this.setState({
                articleId: data.articleId,
                unitId: data.unitId,
                unitCost: data.unitCost,
                discount: data.discount,
                disabled: false,
            })
        }
    }
    componentDidMount() {
        this.props.dispatch(getAllUnits());
    }
    render() {
        const { handleSubmit, show, onHide, reset, openPopup, id, initialValues, str } = this.props;
        const { total, units_list, height, disabled } = this.state;

        return (
            <>
                <button onClick={() => {
                    reset();
                    this.setState({
                        total: 0,
                        quantity: 0,
                        discount: 0,
                        dataObj: ""
                    })
                    openPopup();
                }} className="group_product_btns" style={{ display: "none" }} ><img src={plus} alt="" />Add Custom Product</button>
                {show ? <div
                    className='mini-popup product_pop'
                    onHide={() => {
                        this.setState({
                            total: 0,
                            quantity: 0,
                            discount: 0,
                            dataObj: ""
                        })
                        reset();
                        onHide();
                        this.getUnits();
                    }}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <div className="modal-staff main">
                        <form onSubmit={handleSubmit(this.addProduct)}>
                            <div className="head">
                                <div>
                                    <i class="fas fa-arrow-left" onClick={() => {
                                        this.setState({
                                            total: 0,
                                            quantity: 0,
                                            discount: 0,
                                            dataObj: ""
                                        })
                                        this.getUnits();
                                        reset();
                                        onHide();
                                    }}></i>
                                    <h4>{str === "edit" ? "EDIT" : "ADD"} CUSTOM PRODUCT</h4>
                                </div>
                                <img src={back} alt=""
                                    onClick={() => {
                                        this.setState({
                                            total: 0,
                                            quantity: 0,
                                            discount: 0,
                                            dataObj: ""
                                        })
                                        reset();
                                        this.getUnits();
                                        onHide();
                                    }}

                                    className="back_img" style={{ marginTop: "5px" }} />
                            </div>
                            <Row className="modal-row-form">
                                <Col md={12}>
                                    <Editor
                                        apiKey="2j6wvm4x2pa25n680e9pbtskcfhyi2qdsvjut2c24rm83049"
                                        value={this.state.dataObj}
                                        init={{
                                            height: height,
                                            menubar: false,
                                        }}
                                        onEditorChange={this.handleChange}
                                    />
                                </Col>
                                <Col md={6}>
                                    <label class="form-control-placeholder" for="name" >  Article ID </label>
                                    <Field
                                        readOnly={str === "edit" ? true : false}
                                        name="articleId"
                                        type="text"
                                        component={renderField}
                                    />
                                </Col>
                                {/* isInStock */}
                                <Col md={3}>
                                    <label class="form-control-placeholder" for="name" > Quantity <span>*</span> </label>
                                    <Field
                                        name="quantity"
                                        type="text"
                                        component={renderField}
                                        onChange={this.getQuantity}
                                        maxLength="4"
                                    />
                                </Col>
                                <Col md={3}>
                                    <label class="form-control-placeholder" for="name" > Unit Name </label>
                                    {str !== "edit" ? <Field name="unitId" component={renderSelectField} className='form-control form-select  mt-4' >
                                        <option value="" disabled={true} hidden={true}> </option>
                                        {units_list ?? units_list.length > 0 ? units_list.map((unit, i) => (
                                            <option value={unit?.unitId} key={i}>{unit?.unitName}</option>)) : ""}
                                    </Field>
                                        :
                                        <Field
                                            name="unit"
                                            component={renderField}
                                            readOnly
                                        />}
                                    <div className="triangle-bottom"></div>
                                </Col>
                                <Col md={3}>
                                    <label class="form-control-placeholder" for="name" > Unit Cost <span>*</span> </label>
                                    <Field
                                        name="unitCost"
                                        onChange={this.getUnitCost}
                                        type="text"
                                        component={renderField}
                                    />
                                </Col>
                                <Col md={3}>
                                    <label class="form-control-placeholder" for="name" >Discount </label>
                                    <Field
                                        name="discount"
                                        type="text"
                                        component={renderField}
                                        onChange={this.getDiscount}
                                        maxLength="4"
                                    />
                                </Col>
                                <Col md={3}>
                                    <label class="form-control-placeholder" for="name">Total Cost </label>
                                    <Form.Label></Form.Label>
                                    <Form.Control
                                        type="text"
                                        disabled
                                        name="totalCost"
                                        readOnly
                                        value={total === 0 ? isNaN(initialValues?.totalCost) ? "" : initialValues?.totalCost : total}
                                    />
                                </Col>
                                <Col md={3}>
                                    {disabled ? <button type="submit" className="modal-btn sub-modal-btn">
                                        {str === "edit" ? "Update" : "Create"} Custom Product
                                    </button> : <button type="button" className="modal-btn sub-modal-btn">Loading..</button>}
                                </Col>
                                <Col md={5}></Col>
                            </Row>
                        </form>
                    </div>
                </div> : null}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        products_list: state.product.products_list,
        singleProduct: state.product.singleProduct,
        page: state.product.page,
        success_msg: state.product.success_msg,
        groupProductsSuccess: state.qutation.groupProductsSuccess,
        units_list: state.unit.units_list,
        singleProduct: state.product.singleProduct,
    }
}

const CustomProductsForm = reduxForm({
    form: "QuoteCustomProductsForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate
})(CustomProducts);

export default connect(mapStateToProps)(CustomProductsForm);
