import React from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import Navbar from "../../../layouts/Navbar";
import Sidebar from "../../../layouts/Sidebar";
import Orange from "../../../assets/images/green2.svg";
import plus from "../../../assets/images/pl.svg";
import back from "../../../assets/images/back (3).png";
import { Typeahead } from 'react-bootstrap-typeahead';
import { toast } from "react-toastify";
import CustomerForm from "../../customers/CustomerForm";
import {
    changePage,
    getCustomersList,
    createCustomer,
    getCustomer,
    getQutation,
    createQuotation,
    updateQuotation,
    addIgnoreFields,
    changeTitleStr
} from "../../../actions";
const role = localStorage.getItem('userRole');

const textField = ({ input, name, label, placeholder, defaultValue, disabled, type, meta: { touched, error } }) => (
    <Form.Group className="mb-3" controlId={label}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
            {...input}
            name={name}
            type={type}
            placeholder={placeholder}
            disabled={disabled}
            defaultValue={defaultValue}
        />
        {touched && error && <p className="require">{error}</p>}
    </Form.Group>
)
class QuotCustomerDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            empty: "",
            ignoredFields: [],
        }
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.customerChange = this.customerChange.bind(this);
        this.inputChange = this.inputChange.bind(this);
        this.createQuotationHandler = this.createQuotationHandler.bind(this);
        this.addCustomerHandler = this.addCustomerHandler.bind(this);
        this.hideColumnHandler = this.hideColumnHandler.bind(this);
        this.prevPage = this.prevPage.bind(this);
    }
    prevPage() {
        const { history } = this.props;
        history.push(`/${role}/quotations`)
    }
    handleShow() {
        this.props.dispatch(changePage());
        this.setState({ show: true, empty: "create" });
    }
    handleClose() {
        this.setState({ show: false, empty: "" });
    }
    createQuotationHandler(postData) {
        const { quotationData, history } = this.props;
        let check = this.props.match.params.quotId !== "null" ? true : false;
        if (check) {
            if ((postData.firstName !== quotationData.customerFirstName) || (postData.projectName !== quotationData.projectName) || (postData.option !== quotationData.option)) {
                let formData = {
                    customerId: postData.customerId,
                    quotationId: this.props.match.params.quotId,
                    option: postData.option,
                    projectName: postData.projectName,
                }
                this.props.dispatch(updateQuotation(formData));
            } else {
                history.push(`/${role}/quotations/${this.props.match.params.add}/contentBody/${this.props.match.params.id}/${this.props.match.params.quotId}`)
            }
            let data = {
                quotationId: this.props.match.params.quotId,
                ignoredFields: this.state.ignoredFields,
            }
            this.props.dispatch(addIgnoreFields(data));
        } else {
            if (postData.customerId) {
                let formData = {
                    customerId: postData.customerId,
                    templateId: this.props.match.params.id,
                    option: postData.option,
                    projectName: postData.projectName,
                }
                this.props.dispatch(createQuotation(formData));
            } else {
                toast("Choose a customer");
            }
        }
    }
    inputChange(text, e) {
        this.props.dispatch(getCustomersList(0, text));
    }
    customerChange(e) {
        this.props.customerList?.map((item) => {
            if (item.customerName === e[0]) {
                this.props.dispatch(getCustomer(item?.customerId));
            }
        });
    }
    hideColumnHandler(event) {
        const target = event.target;
        var value = target.value;
        let fields = this.state.ignoredFields;
        if (fields.includes(value)) {
            let newColumns = fields.filter(item => item !== value);
            this.setState({
                ignoredFields: newColumns
            });
        } else {
            fields.push(value);
            this.setState({
                ignoredFields: fields
            });
        }
    }
    addCustomerHandler(formData) {
        if (!formData.firstName) {
            throw new SubmissionError({
                firstName: 'Please fill the field *',
            })
        } else if (formData?.firstName?.trim().length === 0) {
            throw new SubmissionError({
                firstName: 'Please fill the field *',
            })
        }
        this.props.dispatch(createCustomer(formData));
    }
    componentDidUpdate(prevProps) {
        if (prevProps.customer_msg !== this.props.customer_msg) {
            this.setState({
                show: false,
                empty: ''
            });
            if (this.props.customer_msg.status === 200) {
                toast(this.props.customer_msg.data);
                this.props.dispatch(getCustomersList(0));
            } else {
                toast(this.props.customer_msg.data);
            }
        } if (prevProps.newQuotation !== this.props.newQuotation) {
            const { history } = this.props;
            let data = {
                quotationId: this.props.newQuotation?.data.quotationId,
                ignoredFields: this.state.ignoredFields,
            }
            this.props.dispatch(addIgnoreFields(data));
            history.push(`/${role}/quotations/${this.props.match.params.add}/contentBody/${this.props.match.params.id}/${this.props.newQuotation?.data.quotationId}`);
            toast(this.props.newQuotation?.message);
        } if (prevProps.updateQuotation !== this.props.updateQuotation) {
            const { history } = this.props;
            history.push(`/${role}/quotations/${this.props.match.params.add}/contentBody/${this.props.match.params.id}/${this.props.updateQuotation?.data.quotationId}`)
            toast(this.props.updateQuotation?.message);
        } if (prevProps.quotationData !== this.props.quotationData) {
            this.setState({
                ignoredFields: this.props.match.params.add === "create-quotation" ? ["QUOTATION_OPTION", "ARTICLE_ID", "PRODUCT_IMAGE"] : this.props.quotationData?.ignoredFields
            });
        }
    }
    componentDidMount() {
        this.props.dispatch(getCustomersList(0));
        if (this.props.match.params.quotId !== "null") {
            this.props.dispatch(getQutation(this.props.match.params.quotId));
        }
    }
    render() {
        const { handleSubmit, customerList } = this.props;
        const { show, ignoredFields } = this.state;
        const { history } = this.props;
        return (
            <div>
                <Navbar />
                <div className="dashboard-page">
                    <div className="dashboard-sidebar">
                        <Sidebar />
                    </div>
                    <div className="dashboard-main">
                        <Card className="staff-page">
                            <img src={Orange} alt="..." className="head-img" />
                            <h5 className='po_link' onClick={() => history.push("/" + role + "/quotations")}>Quotations</h5>
                            <i className="fas fa-arrow-right" style={{ margin: "10px 0 0 -10px" }}></i>
                            <h6>{changeTitleStr(this.props.match.params.add)}</h6>
                            <hr className="line-new"></hr>
                            <div className="order-page-title">
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/customerDetails/${this.props.match.params.id}/${this.props.match.params.quotId}`} className="active">
                                    Customer Details
                                </Link>
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/contentBody/${this.props.match.params.id}/${this.props.match.params.quotId}`}  >
                                    Content of the Letter
                                </Link>
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/location/${this.props.match.params.id}/${this.props.match.params.quotId}`}  >
                                    Location
                                </Link>
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/quotationBody/${this.props.match.params.id}/${this.props.match.params.quotId}`}  >
                                    Quotation Body
                                </Link>
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/footer/${this.props.match.params.id}/${this.props.match.params.quotId}`}  >
                                    Footer
                                </Link>
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/termsAndConditions/${this.props.match.params.id}/${this.props.match.params.quotId}`}  >
                                    Terms & Conditions
                                </Link>
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/prepared/${this.props.match.params.id}/${this.props.match.params.quotId}`}  >
                                    Prepared by
                                </Link>
                            </div>
                            <form onSubmit={handleSubmit(this.createQuotationHandler)}>
                                <Row className="vendor-details">
                                    <Col md={9}>
                                        <label className="span-name">Select Customer</label>
                                        {customerList?.length >= 0 ? <Typeahead
                                            onChange={this.customerChange}
                                            id="async-example"
                                            labelKey={customerList.map(item => item.customerName)}
                                            onInputChange={this.inputChange}
                                            options={customerList.map(item => item.customerName)}
                                            placeholder="Select Customer by Name"
                                        /> : null}

                                    </Col>
                                    <span className="customer-span" style={{ marginTop: "40px" }}>Or</span>
                                    <Col md={2}>
                                    </Col>
                                    <Col md={3} className="top-space">
                                        <Form.Label>Initials</Form.Label>
                                        <Field name="customerInitials" component="select" className="form-control form-select" disabled={true} >
                                            <option value="" disabled={true}>Select One</option>
                                            <option value="Mr" >Mr</option>
                                            <option value="M/s" >M/s</option>
                                            <option value="Mrs">Mrs</option>
                                            <option value="Miss" >Miss</option>
                                        </Field>
                                    </Col>
                                    <Col md={3} className="top-space">
                                        <Field
                                            name="firstName"
                                            type="text"
                                            component={textField}
                                            label="First Name"
                                            placeholder="Enter First Name"
                                            required={true}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md={3} className="top-space">
                                        <Field
                                            name="lastName"
                                            type="text"
                                            component={textField}
                                            label="Last Name"
                                            placeholder="Enter Last Name"
                                            required={true}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md={3} className="top-space">
                                        <Field
                                            name="phone"
                                            type="text"
                                            component={textField}
                                            label="Contact Number"
                                            placeholder="Enter Contact Number"
                                            required={true}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Field
                                            name="email"
                                            type="text"
                                            component={textField}
                                            label="Email"
                                            placeholder="Enter Email"
                                            required={true}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field
                                            name="address"
                                            type="text"
                                            component={textField}
                                            label="Address"
                                            placeholder="Enter Address"
                                            required={true}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Field
                                            name="city"
                                            type="text"
                                            component={textField}
                                            label="City"
                                            placeholder="Enter City"
                                            required={true}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Field
                                            name="state"
                                            type="text"
                                            component={textField}
                                            label="State"
                                            placeholder="Enter State"
                                            required={true}
                                            disabled={true}
                                        />
                                    </Col>

                                    <Col md={3}>
                                        <Field
                                            name="pincode"
                                            type="text"
                                            component={textField}
                                            label="Pincode"
                                            placeholder="Enter Pincode"
                                            required={true}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md={3}></Col>
                                    <Col md={4}>
                                        <Field
                                            name="projectName"
                                            type="text"
                                            component={textField}
                                            label="Project Name"
                                            placeholder="Enter Project Name"
                                            required={true}
                                        />
                                        <div className="customer-check">
                                            <Form.Check
                                                checked={ignoredFields && ignoredFields?.includes("PROJECT_NAME") ? true : false}
                                                onChange={this.hideColumnHandler}
                                                inline label="Click here to hide this field on quotation"
                                                name="group1"
                                                defaultValue="PROJECT_NAME"
                                                type="checkbox"
                                                className="form-check-label"
                                            />
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <Field
                                            name="option"
                                            type="text"
                                            component={textField}
                                            label="Option"
                                            placeholder="Enter Option"
                                            required={true}
                                        />
                                        <div className="customer-check">
                                            <Form.Check
                                                checked={ignoredFields && ignoredFields?.includes("QUOTATION_OPTION") ? true : false}
                                                onChange={this.hideColumnHandler}
                                                inline label="Click here to hide this field on quotation"
                                                name="group1"
                                                defaultValue="QUOTATION_OPTION"
                                                type="checkbox"
                                                className="form-check-label"
                                            />
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <Field name="customerId" type="hidden" component={textField} />
                                    </Col>
                                    <Col md={3} >
                                        <button className="cancel-btn" onClick={this.prevPage} style={{ marginTop: "20px" }}>
                                            <img style={{ marginRight: "10px" }} src={back} />
                                            Back</button>
                                    </Col>
                                    <Col md={6} >
                                    </Col>
                                    <Col md={3} >
                                        <button type="submit" className="modal-btn save" style={{ marginTop: "20px" }}>Save & Proceed</button>
                                    </Col>
                                </Row>
                            </form>
                            <div className="modal-btn boq_btn" onClick={(e) => this.handleShow()}>
                                <img src={plus} alt="" />
                                Add New Customer
                            </div>
                            <br></br>
                        </Card>
                        <CustomerForm show={show} onHide={this.handleClose} onSubmit={this.addCustomerHandler} />
                    </div>
                </div>
            </div>
        );
    }


}

const mapStateToProps = (state) => {
    let initValues;
    let quote = state.qutation.quotationData;
    let customer = state.customer.customer;
    let newCustomer = quote?.quotationId !== undefined ? {
        'customerInitials': quote ? quote.customerInitials : '',
        'firstName': quote ? quote.customerFirstName : '',
        'lastName': quote ? quote.customerLastName : '',
        'phone': quote ? quote.customerPhone : '',
        'email': quote ? quote.customerEmail : '',
        'address': quote ? quote.customerAddress : '',
        'addressLine2': quote ? quote.customerAddressLine2 : '',
        'city': quote ? quote.customerCity : '',
        'state': quote ? quote.customerState : '',
        'pincode': quote ? quote.customerPincode : '',
        'projectName': quote ? quote.projectName : '',
        'option': quote ? quote.option : ''
    } : {};

    if (Object.keys(customer).length !== 0) {
        customer["projectName"] = quote?.projectName || "";
        customer["option"] = quote?.option || "";
    }
    initValues = Object.keys(customer).length !== 0 ? customer : newCustomer;

    return {
        initialValues: initValues,
        customer_msg: state.customer.success_msg,
        hideColumns: state.qutation.hideColumns,
        customerList: state.customer.customers_list,
        newQuotation: state.qutation.newQuotation,
        updateQuotation: state.qutation.updateQuotation,
        hideColumns: state.qutation.hideColumns,
        customerList: state.customer.customers_list,
        customer: state.customer.customer,
        hideColumnsStatus: state.qutation.hideColumnsStatus,
        quotationData: state.qutation.quotationData,
    }
}
const VendorDetailsForm = reduxForm({
    form: 'CustomerDetailsForm',
    destroyOnUnmount: false,
    enableReinitialize: true,
    // validate
})(QuotCustomerDetails)

export default connect(mapStateToProps)(VendorDetailsForm);