import { poConstants } from "../constants"

export const getPoProjectDetail = (id) => {
    return {
        type: poConstants.GET_PO_PRODUCTS,
        id
    }
}
export const getVerticesDropdown = () => {
    return {
        type: poConstants.GET_VERTICS,
    }
}
export const getBoqProjectDropdown = () => {
    return {
        type: poConstants.GET_BOQ_PROJECTS,
    }
}
export const createPoProject = (formData) => {
    return {
        type: poConstants.CREATE_PO_PROJECT,
        formData
    }
}
export const editPoProject = (formData) => {
    return {
        type: poConstants.UPDATE_PO_PROJECT,
        formData
    }
}
export const deletePoProject = (id) => {
    return {
        type: poConstants.DELETE_PO_PROJECT,
        id
    }
}
export const createPoProduct = (formData) => {
    return {
        type: poConstants.CREATE_PO_PRODUCT,
        formData
    }
}
export const editPoProduct = (formData) => {
    return {
        type: poConstants.UPDATE_PO_PRODUCT,
        formData
    }
}
export const deletePoProduct = (id) => {
    return {
        type: poConstants.DELETE_PO_PRODUCT,
        id
    }
}
export const deletePo = (id) => {
    return {
        type: poConstants.DELETE_PO,
        id
    }
}
export const updatePoIgnoreFields = (formData) => {
    return {
        type: poConstants.UPDATE_PO_IGNORE_FIELDS,
        formData
    }
}
export const getPoFooter = (id) => {
    return {
        type: poConstants.GET_PO_FOOTER,
        id
    }
}
export const createPoFooter = (formData) => {
    return {
        type: poConstants.CREATE_PO_FOOTER,
        formData
    }
}
export const editPoFooter = (formData) => {
    return {
        type: poConstants.UPDATE_PO_FOOTER,
        formData
    }
}
export const deletePoFooter = (id) => {
    return {
        type: poConstants.DELETE_PO_FOOTER,
        id
    }
}
export const previewPo = (id) => {
    return {
        type: poConstants.PREVIEW_PO,
        id
    }
}
export const generatePo = (id) => {
    return {
        type: poConstants.GENERATE_PO,
        id
    }
}
export const sendPo = (id) => {
    return {
        type: poConstants.SENT_PO,
        id
    }
}
export const clonePo = (id) => {
    return {
        type: poConstants.CLONE_PO,
        id
    }
}
export const revisePo = (id) => {
    return {
        type: poConstants.REVISE_PO,
        id
    }
}
export const cancelPo = (id) => {
    return {
        type: poConstants.CANCEL_PO,
        id
    }
}
export const verifyPo = (formData) => {
    return {
        type: poConstants.VERIFY_PO,
        formData
    }
}
export const getConfirmProduts = () => {
    return {
        type: poConstants.GET_CONVERT_PRODUCTS,
    }
}
export const getPoVerifyProduts = (id) => {
    return {
        type: poConstants.GET_PO_VERIFIED_PRODUCTS,
        id
    }
}
export const getPoInwardList = () => {
    return {
        type: poConstants.GET_PO_INWARD,
    }
}
export const createPoInwardProducts = (formData) => {
    return {
        type: poConstants.CREATE_PO_INWARD_PRODUCTS,
        formData
    }
}
export const getPoConvertProductList = (formData) => {
    return {
        type: poConstants.GET_PO_CONVERTED_PRODUCTS,
        formData
    }
}
export const createPoInward = (formData) => {
    return {
        type: poConstants.ADD_PO_INWARD,
        formData
    }
}
export const getPoById = (id) => {
    return {
        type: poConstants.GET_PO_BY_ID,
        id
    }
}

export const updateCustomPoProduct = (formData) => {
    return {
        type: poConstants.UPDATE_CUSTOM_PRODUCT,
        formData
    }
}

export const convertToPo = (formData) => {
    return {
        type: poConstants.CONVERT_TO_PO,
        formData
    }
}
export const getPoMailDetails = (id) => {
    return {
        type: poConstants.GET_MAIL_DETAILS,
        id
    }
}
export const sendPoMail = (formData) => {
    return {
        type: poConstants.SENT_PO_MAIL,
        formData
    }
}

export const clearPo = () => {
    return {
        type: poConstants.CLEAR_PO_DETAILS
    }
}
export const clearPreview = () => {
    return {
        type: poConstants.CLEAR_PREVIEW
    }
}

export const changePoPage = () => {
    return {
        type: poConstants.CHANGE_PAGE
    }
}

export const changePoAddPage = () => {
    return {
        type: poConstants.CHANGE_ADD_PAGE
    }
}

export const clearPoProjectData = () => {
    return {
        type: poConstants.CLEAR_PO_PROJECT
    }
}

export const clearPoVerifyProducts = () => {
    return {
        type: poConstants.CLEAR_VERIFY_PRODUCTS
    }
}

export const clearMailData = () => {
    return {
        type: poConstants.CLEAR_MAIL_DETAILS
    }
}
