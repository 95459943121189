import React from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { Row, Col, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import {
    getProductById,
    clearSingleProduct,
    editCoProduct,
    getBulkProducts,
    createCoProduct,
    getProduct,
    changeCoPage,
    createVendorProduct,
    editVendorProduct,
} from "../../../actions";
import { Typeahead } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';
// import validate from '../../../createpurchaseorder/productdetails/validate';

const renderField = ({ input, placeholder, maxLength, defaultValue, type, readOnly, meta: { touched, error } }) => (
    <div>
        <label></label>
        <div>
            <input {...input} placeholder={placeholder} type={type} className="form-control" maxLength={maxLength}
                defaultValue={defaultValue}
                readOnly={readOnly} />
            {touched && error && <p className="require">{error}</p>}
        </div>
    </div>
);

class AddProduct extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            quantity: 0,
            discount: 0,
            productId: 0,
            items: [],
            total: 0,
            groupId: this.props.groupId,
            singleProduct: this.props.singleProduct,
        }
        this.myRef = React.createRef();
        this.customerChange = this.customerChange.bind(this);
        this.inputChange = this.inputChange.bind(this);
        this.addGroupProduct = this.addGroupProduct.bind(this);
        this.clearSingleProductTrigger = this.clearSingleProductTrigger.bind(this);
        this.clearData = this.clearData.bind(this);
    }
    customerChange(e) {
        this.state.items?.map((item) => {
            if (item?.productName === e[0]) {
                this.props.dispatch(getProductById(item?.productId));
            }
        });
    }
    inputChange(text) {
        this.props.dispatch(getBulkProducts(text));
    }
    clearData() {
        this.props.dispatch(changeCoPage());
    }
    componentDidUpdate(prevProps) {
        if (prevProps.bulkProducts !== this.props.bulkProducts) {
            this.setState({
                items: this.props.bulkProducts.content,
            });
        } if (prevProps.coProductSucces !== this.props.coProductSucces) {
            const { coProductSucces, getData, id, str, onHide } = this.props;
            if (coProductSucces.status === 200) {
                toast(coProductSucces.message);
                if (str === "edit") {
                    onHide();
                }
                this.props.reset();
                getData(id);
                this.clearData();
            } else {
                toast(coProductSucces.data);
            }
        } if (prevProps.vendorProductSucces !== this.props.vendorProductSucces) {
            const { vendorProductSucces, id, str, onHide, getData } = this.props;
            if (vendorProductSucces.status === 200) {
                toast(vendorProductSucces.data);
                if (str === "edit") {
                    onHide();
                }
                this.props.reset();
                getData(id);
                this.clearData();
            } else {
                toast(vendorProductSucces.data);
            }
        }
    }
    addGroupProduct(formData) {
        if (!formData.Quantity) {
            throw new SubmissionError({
                Quantity: 'Please fill the field *',
            })
        }
        if (formData.Quantity > formData.totalStock) {
            throw new SubmissionError({
                Quantity: 'Quantity must be less than total stock *',
            })
        }
        if (formData.productId !== undefined) {
            const { str, id, checked, vendorId, projectId, remark, returnId } = this.props;
            if (str === "edit") {
                if (checked) {
                    let data = {
                        "outwardReturnProductId": formData.counterSaleProductId,
                        "productId": formData.productId,
                        "outwardReturnQuantity": formData.Quantity
                    }
                    this.props.dispatch(editVendorProduct(data));
                } else {
                    let data = {
                        "counterSaleId": formData.counterSaleProductId,
                        "productId": formData.productId,
                        "quantity": formData.Quantity
                    }
                    this.props.dispatch(editCoProduct(data));
                }

            }
            else {
                if (checked) {
                    let data = {
                        "vendorId": vendorId,
                        "returnRemark": remark,
                        productReqDtoList: [{
                            "productId": formData.productId,
                            "outwardReturnQuantity": Number(formData.Quantity),
                        }]
                    };
                    document.getElementsByClassName("rbt-close")[0].click();
                    this.props.dispatch(createVendorProduct(data));
                } else {
                    let data = {
                        "customerId": id,
                        "remark": remark,
                        "purchaseOrderProjectId": projectId !== undefined ? projectId : null,
                        "productReqDtoList": [
                            {
                                "productId": formData.productId,
                                "quantity": formData.Quantity
                            }
                        ]
                    }
                    document.getElementsByClassName("rbt-close")[0].click();
                    this.props.dispatch(createCoProduct(data));
                }
            }
        } else {
            toast("Choose a product")
        }
    }
    clearSingleProductTrigger() {
        this.props.dispatch(clearSingleProduct());
    }
    render() {
        const { handleSubmit, show, onHide, reset, initialValues, load, str, checked } = this.props;

        return (
            <>
                <Modal
                    dialogClassName="modal-90w"
                    // aria-labelledby="example-custom-modal-styling-title"
                    contentClassName='po_modall'
                    show={show}
                    onHide={() => {
                        initialValues.articleId = "";
                        initialValues.unitName = "";
                        reset();
                        onHide();
                    }}
                    size="lg"
                    className="blur"
                >
                    <div className="modal-staff">
                        <form onSubmit={handleSubmit(this.addGroupProduct)}>
                            <div className="head">
                                <div>
                                    <i class="fas fa-arrow-left" onClick={() => {
                                        initialValues.articleId = "";
                                        initialValues.unitName = "";
                                        reset();
                                        onHide();
                                    }}></i>
                                    <h4>{str === "edit" ? "EDIT" : "ADD"} PRODUCT</h4>
                                </div>
                            </div>
                            <Row className="modal-row-form">
                                <Col md={12} style={{ marginTop: "22px" }}>
                                    <label class="form-control-placeholder type-form" for="name" >Product Name <span>*</span></label>
                                    {this.state.items?.length >= 0 && str !== "edit" ? <Typeahead
                                        onChange={this.customerChange}
                                        ref={(ref) => this._typeahead = ref}
                                        id="async-example"
                                        labelKey={this.state.items.map(item => item.productName)}
                                        onInputChange={this.inputChange}
                                        options={this.state.items.map(item => item.productName)}
                                        clearButton={true}
                                        instanceRef={this.myRef}
                                        className="new-bot"
                                    /> : <div>
                                        <p className="border-area">{initialValues?.productName ?? "-"}</p>
                                    </div>}
                                </Col>

                                <Col md={3}>
                                <label class="form-control-placeholder" for="name">Article ID </label>
                                    <Field
                                        name="articleId"
                                        type="text"
                                        component={renderField}
                                        readOnly
                                    />
                                </Col>
                                <Col md={3}>
                                <label class="form-control-placeholder" for="name">Quantity <span>*</span></label>
                                    <Field
                                        name="Quantity"
                                        type="text"
                                        component={renderField}
                                        maxLength="6"
                                    />
                                </Col>
                                <Col md={3}>
                                <label class="form-control-placeholder" for="name">Unit Name</label>
                                    <Field
                                        name="unitName"
                                        component={renderField}
                                        readOnly
                                    />
                                </Col>
                                <Col md={3}>
                                    <label class="form-control-placeholder" for="name">Available Stock </label>
                                    <Field
                                        name="totalStock"
                                        type="number"
                                        component={renderField}
                                        readOnly
                                    />
                                </Col>
                                <Field
                                    name="productId"
                                    type="hidden"
                                    component={renderField}
                                    placeholder=""
                                />
                                <Field
                                    name="counterSaleProductId"
                                    type="hidden"
                                    component={renderField}
                                    placeholder=""
                                />
                                <Col md={4}></Col>
                                <Col md={4}>
                                    {!load ? <button type="submit" className="modal-btn sub-modal-btn">
                                        {str === "edit" ? "Update" : "Add"} Product
                                    </button> : <button type="button" className="modal-btn sub-modal-btn">Adding..</button>}
                                </Col>
                                <Col md={4}></Col>
                            </Row>
                        </form>
                    </div>
                </Modal>
            </>
        );
    }
}

function mapStateToProps(state) {
    let item = state.product.singleProduct;
    let page = state.outward.page;
    let newItem = page === "edit" ? getProduct() : {};
    let data = {
        'articleId': newItem?.articleId,
        'productName': newItem?.productName,
        'Quantity': newItem.quantity,
        'unitName': newItem?.unit?.unitName,
        'productId': newItem?.productId,
        'counterSaleProductId': newItem?.counterSaleProductId,
        'totalStock': newItem?.availableStock,
    };
    let initValues = item !== null ?
        {
            'articleId': item?.articleId,
            'unitName': item?.unitName,
            'productName': item?.productName,
            'productId': item?.productId,
            'Quantity': item.quantity,
            'totalStock': item?.totalStock
        } : data;

    return {
        initialValues: initValues,
        load: state.outward.load,
        bulkProducts: state.product.bulkProducts,
        singleProduct: state.product.singleProduct,
        coProductSucces: state.outward.coProductSucces,
        vendorProductSucces: state.outward.vendorProductSucces
    }
}

const BoqProductForm = reduxForm({
    form: "AddPoProduct",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    // validate
})(AddProduct);

export default connect(mapStateToProps)(BoqProductForm);