import { qutationConstants } from "../constants";

export const getQutationList = (pageNo, searchKey, sort, filterData) => {
    return {
        type: qutationConstants.GET_QUTATION,
        pageNo,
        searchKey,
        sort,
        filterData
    }
}
export const getQutation = (quotationId) => {
    return {
        type: qutationConstants.GET_QUTATION_BY_ID,
        quotationId
    }
}
export const getIgnoreFieldsForQuotation = (quotationId) => {
    return {
        type: qutationConstants.GET_IGNORE_FILEDS,
        quotationId
    }
}
export const getQutationTemplates = () => {
    return {
        type: qutationConstants.GET_QUTATION_TEMPLATES
    }
}
export const createQuotation = (formData) => {
    return {
        type: qutationConstants.CREATE_QUOTATION_INIT,
        formData
    }
}
export const updateQuotation = (formData) => {
    return {
        type: qutationConstants.UPDATE_QUOTATION_INIT,
        formData
    }
}
export const deleteQuotation = (quotationId) => {
    return {
        type: qutationConstants.DELETE_QUTATION,
        quotationId
    }
}
export const getGroupListQuotation = (quotationId) => {
    return {
        type: qutationConstants.GET_GROUPS_QUOTATION_INIT,
        quotationId
    }
}
export const createGroupsQuotation = (formData) => {
    return {
        type: qutationConstants.ADD_GROUP_QUOTATION_INIT,
        formData
    }
}
export const updateGroupsQuotation = (formData) => {
    return {
        type: qutationConstants.UPDATE_GROUP_QUOTATION_INIT,
        formData
    }
}
export const deleteGroupListQuotation = (groupId) => {
    return {
        type: qutationConstants.DELETE_GROUP_QUOTATION_INIT,
        groupId
    }
}
export const createFooterQuotation = (formData) => {
    return {
        type: qutationConstants.CREATE_FOOTER_QUOTATION_INIT,
        formData
    }
}
export const addTermsQuotation = (quotationId, formData) => {
    return {
        type: qutationConstants.ADD_TERMS_QUOTATION_INIT,
        quotationId,
        formData
    }
}
export const getExecutives = () => {
    return {
        type: qutationConstants.GET_EXECUTIVES_INIT,
    }
}
export const getQutationStatus = () => {
    return {
        type: qutationConstants.GET_QUOTATION_STATUS,

    }
}
export const updateQutationStatus = (quotationId, statusId) => {
    return {
        type: qutationConstants.UPDATE_QUOTATION_STATUS,
        quotationId,
        statusId
    }
}
export const addSalesExecutives = (quotationId, formData) => {
    return {
        type: qutationConstants.ADD_SALESEXE_QUOTATION_INIT,
        quotationId,
        formData
    }
}
export const previewQuotation = (quotationId) => {
    return {
        type: qutationConstants.PREVIEW_QUOTATION_INIT,
        quotationId
    }
}
export const cloneQuotation = (quotationId, status) => {
    return {
        type: qutationConstants.CLONE_QUTATION,
        quotationId,
        status
    }
}
export const cloneLocation = (id) => {
    return {
        type: qutationConstants.CLONE_LOCATION,
        id,
    }
}
export const cloneGroup = (formData) => {
    return {
        type: qutationConstants.CLONE_GROUP,
        formData,
    }
}
export const generateQuotation = (quotationId) => {
    return {
        type: qutationConstants.GENERATE_QUOTATION_INIT,
        quotationId
    }
}
export const getLocationListOfQuotations = (quotationId) => {
    return {
        type: qutationConstants.GET_LOCATIONLIST_INIT,
        quotationId
    }
}
export const addLocationToQuotation = (formData) => {
    return {
        type: qutationConstants.ADD_LOCATION_QUOTATION_INIT,
        formData
    }
}
export const editLocationToQuotation = (formData) => {
    return {
        type: qutationConstants.EDIT_LOCATION_QUOTATION_INIT,
        formData
    }
}
export const deleteLocationToQuotation = (deleteId) => {
    return {
        type: qutationConstants.DELETE_LOCATION_QUOTATION_INIT,
        deleteId
    }
}
export const getFooterListOfQuotations = (quotationId) => {
    return {
        type: qutationConstants.GET_FOOTERLIST_INIT,
        quotationId
    }
}
export const addIgnoreFields = (formData) => {
    return {
        type: qutationConstants.ADD_IGNORE_FIELDS_INIT,
        formData
    }
}
export const quotationProducts = (groupId) => {
    return {
        type: qutationConstants.GET_GROUP_PRODUCTS,
        groupId
    }
}
export const addQuotationProducts = (formData) => {
    return {
        type: qutationConstants.ADD_GROUP_PRODUCTS,
        formData
    }
}
export const deleteQuotationProducts = (groupId) => {
    return {
        type: qutationConstants.DELETE_GROUP_PRODUCTS,
        groupId
    }
}
export const changeGroupQutationPage = () => {
    return {
        type: qutationConstants.CHANGE_GROUP_QUOTATION_INIT,
    }
}
export const updateGroupQutationPage = () => {
    return {
        type: qutationConstants.UPDATE_GROUP_QUOTATION_INIT,
    }
}
export const downloadQutation = (quotationId) => {
    return {
        type: qutationConstants.DOWNLOAD_QUTATION,
        quotationId
    }
}
export const changeProductPage = () => {
    return {
        type: qutationConstants.CHANGE_PAGE,
    }
}
export const clearSingleCustomer = () => {
    return {
        type: qutationConstants.CLEAR_CUSTOMER_DETAILS,
    }
}

export const clearQuotationBody = () => {
    return {
        type: qutationConstants.CLEAR_QUOTATION_BODY,
    }
}


