import { put, call, takeEvery } from "redux-saga/effects";
import { getProductApi, getProductUnitApi, createProductApi, getProductByIdApi, createStockApi, updateProductApi, getProductHistoryApi, getBulkProductsApi } from "../services";
import { productConstants } from "../constants";

export function* productlistSaga(payload) {
    try {
        const response = yield call(getProductApi, payload);
        // const response = yield call(getProductApi, payload.pageNo, payload.searchKey, payload.sort, payload.filterData, payload.size);
        yield put({ type: productConstants.GET_PRODUCTS_SUCCESS, response });
    } catch (error) {
        yield put({ type: productConstants.GET_PRODUCTS_FAILURE, error });
    }
}
export function* createproductSaga(payload) {
    try {
        const response = yield call(createProductApi, payload.formData);
        yield put({ type: productConstants.CREATE_PRODUCT_SUCCESS, response });

        // yield put({ type: productConstants.GET_PRODUCTS, productlistSaga })
    } catch (error) {
        yield put({ type: productConstants.CREATE_PRODUCT_FAILURE, error });
    }
}
export function* updateProductSaga(payload) {
    try {
        const response = yield call(updateProductApi, payload.formData);
        yield put({ type: productConstants.UPDATE_PRODUCT_SUCCESS, response });

        // yield put({ type: productConstants.GET_PRODUCTS, productlistSaga })
    } catch (error) {
        yield put({ type: productConstants.UPDATE_PRODUCT_FAILURE, error });
    }
}
export function* productUnitSaga(payload) {
    try {
        const response = yield call(getProductUnitApi, payload);
        yield put({ type: productConstants.GET_PRODUCT_UNIT_SUCCESS, response });
    } catch (error) {
        yield put({ type: productConstants.GET_PRODUCT_UNIT_FAILURE, error });
    }
}
export function* productByIdSaga(payload) {
    try {
        const response = yield call(getProductByIdApi, payload.productId);
        yield put({ type: productConstants.GET_SINGLE_PRODUCT_SUCCESS, response });
    } catch (error) {
        yield put({ type: productConstants.GET_SINGLE_PRODUCT_FAILURE, error });
    }
}
export function* productHistorySaga(payload) {
    try {
        const response = yield call(getProductHistoryApi, payload.productId);
        yield put({ type: productConstants.GET_PRODUCTS_HISTORY_SUCCESS, response });
    } catch (error) {
        yield put({ type: productConstants.GET_PRODUCTS_HISTORY_FAILURE, error });
    }
}
export function* bulkProductsSaga(payload) {
    try {
        const response = yield call(getBulkProductsApi, payload.searchKey);
        yield put({ type: productConstants.GET_BLUK_PRODUCTS_SUCCESS, response });
    } catch (error) {
        yield put({ type: productConstants.GET_BLUK_PRODUCTS_FAILURE, error });
    }
}
export function* createStockSaga(payload) {
    try {
        const response = yield call(createStockApi, payload.formData);
        yield put({ type: productConstants.ADD_STOCK_SUCCESS, response });

        // yield put({ type: productConstants.GET_PRODUCTS, productlistSaga })
    } catch (error) {
        yield put({ type: productConstants.ADD_STOCK_FAILURE, error });
    }
}

export default function* productSaga() {
    yield takeEvery(productConstants.GET_PRODUCTS, productlistSaga);
    yield takeEvery(productConstants.CREATE_PRODUCT_INIT, createproductSaga);
    yield takeEvery(productConstants.GET_PRODUCT_UNIT, productUnitSaga);
    yield takeEvery(productConstants.GET_SINGLE_PRODUCT, productByIdSaga);
    yield takeEvery(productConstants.UPDATE_PRODUCT_INIT, updateProductSaga);
    yield takeEvery(productConstants.GET_PRODUCTS_HISTORY, productHistorySaga);
    yield takeEvery(productConstants.GET_BLUK_PRODUCTS, bulkProductsSaga);
    yield takeEvery(productConstants.ADD_STOCK, createStockSaga);
}