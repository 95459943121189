import { authToken, baseUrl } from "../actions";

export const getQutationApi = (pageNo, searchKey, sort = {}, filterData = {}) => {
    let QUTATION_LIST;

    if (Object.keys(filterData).length === 3 || Object.keys(sort).length === 2) {
        if (Object.keys(sort).length === 2 && Object.keys(filterData).length !== 3) {
            QUTATION_LIST = `${baseUrl}staff/quotations?sortField=${sort.sortField}&sortMethod=${sort.sortType}&page=${pageNo}&searchKey=${searchKey}`;
        }
        else {
            if ((filterData.status !== "") && (filterData.fromDate === "" && filterData.toDate === "")) {
                QUTATION_LIST = `${baseUrl}staff/quotations?status=${filterData.status}&page=${pageNo}&searchKey=${searchKey}`;
            } if ((filterData.fromDate !== "" || filterData.toDate !== "") && filterData.status === "") {
                QUTATION_LIST = `${baseUrl}staff/quotations?fromDate=${filterData.fromDate}&toDate=${filterData.toDate}&page=${pageNo}&searchKey=${searchKey}`;
            } if ((filterData.fromDate !== "" || filterData.toDate !== "") && filterData.status !== "") {
                QUTATION_LIST = `${baseUrl}staff/quotations?status=${filterData.status}&fromDate=${filterData.fromDate}&toDate=${filterData.toDate}&page=${pageNo}&searchKey=${searchKey}`;
            } if ((filterData.fromDate !== "" || filterData.toDate !== "") && Object.keys(sort).length === 2) {
                QUTATION_LIST = `${baseUrl}staff/quotations?sortField=${sort.sortField}&sortMethod=${sort.sortType}&fromDate=${filterData.fromDate}&toDate=${filterData.toDate}&page=${pageNo}&searchKey=${searchKey}`;
            } if ((filterData.status !== "") && (Object.keys(sort).length === 2)) {
                QUTATION_LIST = `${baseUrl}staff/quotations?sortField=${sort.sortField}&sortMethod=${sort.sortType}&status=${filterData.status}&page=${pageNo}&searchKey=${searchKey}`;
            } if (!Object.values(filterData).includes("") && Object.keys(sort).length === 2) {
                QUTATION_LIST = `${baseUrl}staff/quotations?sortField=${sort.sortField}&sortMethod=${sort.sortType}&fromDate=${filterData.fromDate}&toDate=${filterData.toDate}&status=${filterData.status}&page=${pageNo}&searchKey=${searchKey}`;
            }
        }
    } else {
        QUTATION_LIST = searchKey !== undefined ? `${baseUrl}staff/quotations?searchKey=${searchKey}&page=${pageNo}` : `${baseUrl}staff/quotations?page=${pageNo}`;
    }

    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(QUTATION_LIST, parameters).then((response) => {
        return response.json();
    }).then((json) => {
        return json;
    });
}
export const getQuotationByIdApi = (quotationId) => {
    const TEMPLATE_ID = `${baseUrl}staff/quotations/${quotationId}`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return fetch(TEMPLATE_ID, parameters).then((response) => {
        return response.json();
    }).then((json) => {
        return json;
    });
}
export const getIgnoreFieldsApi = (quotationId) => {
    const TEMPLATE_ID = `${baseUrl}staff/quotation/ignoredFields/${quotationId}`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(TEMPLATE_ID, parameters).then((response) => {
        return response.json();
    }).then((json) => {
        return json;
    });
}
export const cloneQuotationApi = (quotationId, status) => {
    const CLONE_QUTATION = `${baseUrl}staff/quotations/${quotationId}/clone?revised=${status}`;

    const parameters = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return fetch(CLONE_QUTATION, parameters).then((response) => {
        return response.json();
    }).then((json) => {
        return json;
    });
}
export const cloneLocationApi = (id) => {
    const CLONE_QUTATION = `${baseUrl}staff/quotationLocation/${id}/clone`;
    const parameters = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return fetch(CLONE_QUTATION, parameters).then((response) => {
        return response.json();
    }).then((json) => {
        return json;
    });
}
export const cloneGroupApi = (request) => {
    const CLONE_QUTATION = `${baseUrl}staff/quotationGroup/clone`;
    const parameters = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(CLONE_QUTATION, parameters).then((response) => {
        return response.json();
    }).then((json) => {
        return json;
    });
}
export const getQuotationTemplatesApi = () => {
    const QUTATION_TEMPLATES = `${baseUrl}staff/templates`;
    // "https://estimationapi.corpfield.com/staff/templates";
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return fetch(QUTATION_TEMPLATES, parameters).then((response) => {
        return response.json();
    }).then((json) => {
        return json;
    });
}
export const createQuotationApi = (request) => {
    const CREATE_QUTOTION = `${baseUrl}staff/quotations`;
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(CREATE_QUTOTION, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const updateQuotationApi = (request) => {
    const UPDATE_QUTOTION = `${baseUrl}staff/quotations`;
    const parameters = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(UPDATE_QUTOTION, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const getQutationGroupsApi = (quotationId) => {
    const GROUP_LIST = `${baseUrl}staff/quotations/${quotationId}/quotationGroups`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return fetch(GROUP_LIST, parameters).then((response) => {
        return response.json();
    }).then((json) => {
        return json;
    });
}
export const deleteQuotationApi = (quotationId) => {
    const GROUP_LIST = `${baseUrl}staff/quotations/${quotationId}`;

    const parameters = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return fetch(GROUP_LIST, parameters).then((response) => {
        return response.json();
    }).then((json) => {
        return json;
    });
}
export const createQuotationGroupsApi = (request) => {
    const CREATE_GROUPS = `${baseUrl}staff/quotationGroups`;
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(CREATE_GROUPS, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const updateQuotationGroupsApi = (request) => {
    const CREATE_GROUPS = `${baseUrl}staff/quotationGroups`;
    const parameters = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(CREATE_GROUPS, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const deleteQutationGroupsApi = (groupId) => {
    const GROUP_DELETE = `${baseUrl}staff/quotationGroups/${groupId}`;
    const parameters = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return fetch(GROUP_DELETE, parameters).then((response) => {
        return response.json();
    }).then((json) => {
        return json;
    });
}
export const createFooterGroupsApi = (request) => {
    const CREATE_GROUPS = `${baseUrl}staff/quotations/footer`;

    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(CREATE_GROUPS, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const addTermsGroupsApi = (request) => {
    const CREATE_GROUPS = `${baseUrl}staff/quotations/${request.quotationId}/termsAndConditions`;
    const parameters = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: request.formData
    };

    return fetch(CREATE_GROUPS, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const getExecutivesApi = () => {
    const EXECUTIVES = `${baseUrl}staff/dropdown/salesExecutives`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return fetch(EXECUTIVES, parameters).then((response) => {
        return response.json();
    }).then((json) => {
        return json;
    });
}
export const addSalesExecutiveApi = (request) => {
    const CREATE_GROUPS = `${baseUrl}staff/quotations/${request.quotationId}/salesExecutive/${request.formData.salesExecutiveId}`;
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(CREATE_GROUPS, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const previewQuotationApi = (request) => {
    const PREVIEW_QUOTATION = `${baseUrl}public/quotations/${request.quotationId}/html`;
    return fetch(PREVIEW_QUOTATION).then(response => {
        return response.text() // response.json()
    }).then((page) => {
        return page;
    }).catch((error) => {
        console.error(error)
    })
}
export const generateQuotationApi = (request) => {
    const GENERATE_QUOTATION = `${baseUrl}staff/quotations/${request.quotationId}/generate`;

    const parameters = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        }
    };

    return fetch(GENERATE_QUOTATION, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const getLocationsOfQuotationsApi = (request) => {
    const LOCATIONS = `${baseUrl}staff/quotations/${request}/quotationLocations`;
    const parameters = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        }
    };

    return fetch(LOCATIONS, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const getQuotationStatusApi = (request) => {
    const STATUS = `${baseUrl}public/dropdown/quotationStatus`;
    const parameters = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        }
    };

    return fetch(STATUS, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const updateQuotationStatusApi = (request, res) => {

    const STATUS = `${baseUrl}staff/quotations/${Number(request)}/status/${Number(res)}`;
    const parameters = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        }
    };

    return fetch(STATUS, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const addLocationToQuotationApi = (request) => {
    const ADD_LOCATION = `${baseUrl}staff/quotationLocations`;
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(ADD_LOCATION, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const editLocationToQuotationApi = (request) => {
    const EDIT_LOCATION = `${baseUrl}staff/quotationLocations`;
    const parameters = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(EDIT_LOCATION, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const deleteLocationToQuotationApi = (request) => {
    const DELETE_LOCATION = `${baseUrl}staff/quotationLocations/${request.deleteId}`;
    const parameters = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
    };

    return fetch(DELETE_LOCATION, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const getFooterOfQuotationsApi = (request) => {
    const FOOTER = `${baseUrl}staff/quotations/${request}/footer/v2`;
    const parameters = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        }
    };

    return fetch(FOOTER, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const addIgnoreFieldsApi = (request) => {
    const IGNORE = `${baseUrl}staff/quotation/ignoredFields`;
    const parameters = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(IGNORE, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const getQuotationProductsApi = (groupId) => {
    const PRODUCTS = `${baseUrl}staff/quotations/groups/${groupId}/products`;
    const parameters = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        }
    };

    return fetch(PRODUCTS, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const addQuotationProductApi = (request) => {
    const ADD_PRODUCT = `${baseUrl}staff/quotationGroups/products`;
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(ADD_PRODUCT, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const deleteQuotationProductApi = (request) => {
    const DELETE_PRODUCT = `${baseUrl}staff/quotationGroups/products/${request}`;
    const parameters = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(DELETE_PRODUCT, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const downloadQuotationApi = (request) => {
    const PREVIEW_QUOTATION = `${baseUrl}staff/quotations/${request.quotationId}/preview`;
    const parameters = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        }
    };

    return fetch(PREVIEW_QUOTATION, parameters).then(response => {
        return response.blob();
    }).then(json => {
        return json;
    });
}