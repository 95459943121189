import React from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import {
    getProductById,
    getPoProjectDetail,
    dateFormat,
    clearSingleProduct,
    getBulkProducts,
    createPoProduct,
    getProduct,
    editPoProduct,
    displayCurrency,
    setProduct
} from "../../../../actions";
import { Typeahead } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';
import validate from './validate';
// import parse from 'html-react-parser';

const renderField = ({ input, placeholder, maxLength, defaultValue, type, readOnly, meta: { touched, error } }) => (
    <div>
        <label></label>
        <div>
            <input {...input} placeholder={placeholder} type={type} className="form-control" maxLength={maxLength}
                defaultValue={defaultValue}
                readOnly={readOnly} />
            {touched && error && <p className="require">{error}</p>}
        </div>
    </div>
);

class AddPoProduct extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            quantity: 0,
            discount: 0,
            productId: 0,
            unitCost: 0,
            items: [],
            total: 0,
            groupId: this.props.groupId,
            singleProduct: this.props.singleProduct,
            clicked: false,
            changed: false,
        }
        this.myRef = React.createRef();
        this.customerChange = this.customerChange.bind(this);
        this.inputChange = this.inputChange.bind(this);
        this.getQuantity = this.getQuantity.bind(this);
        this.addGroupProduct = this.addGroupProduct.bind(this);
        this.clearSingleProductTrigger = this.clearSingleProductTrigger.bind(this);
        this.scrollToDiv = this.scrollToDiv.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.getUnitCost = this.getUnitCost.bind(this);
    }
    clickHandler() {
        this.setState({
            clicked: true
        })
    }
    getUnitCost(e) {
        this.setState({
            unitCost: e.target.value
        })
        const { initialValues } = this.props;
        const { quantity } = this.state;
        let qua = quantity === 0 ? initialValues.Quantity : quantity;
        this.setState({
            total: Number(e.target.value * qua)
        })
    }
    changeHandler() {
        setProduct({});
        this.setState({
            changed: true,
            total: 0
        })
    }
    scrollToDiv = () => {
        // window.scrollTo(0, window.pageYOffset + 100);
        window.scroll({
            top: window.pageYOffset + 100,
            left: 0,
            behavior: 'smooth'
        });
        // const div = document.getElementById('table-bluee');
        // if (div !== null) {
        //     document.getElementById('table-bluee').scrollIntoView({ behavior: 'smooth', block: "start" });
        // }
    }
    getQuantity(e) {
        this.setState({
            quantity: e.target.value
        })
        const { initialValues } = this.props;
        const { unitCost } = this.state;
        let date1 = new Date();
        let oldDate = dateFormat(initialValues?.effectiveOnDate);
        let currentDate = dateFormat(date1);
        let checkDate = currentDate < oldDate;
        let price = 0;
        if (initialValues.unitPrice) {
            price = unitCost === 0 ? initialValues.unitPrice : unitCost;
        } else {
            price = checkDate ? initialValues.currentPrice : initialValues.effectivePrice;
        }
        let discount1 = Number(e.target.value * price);
        this.setState({
            total: discount1,
            unitCost: price
        })
    }
    customerChange(e) {
        this.state.items?.map((item) => {
            if (item?.productName === e[0]) {
                this.props.dispatch(getProductById(item?.productId));
            }
        });
    }
    inputChange(text) {
        const ele = document.getElementById("async-example");
        if (ele !== null) {
            ele.style.transform = "translate3d(0px, -310px, 0px)";
            ele.style.height = "300px";
        }
        this.props.dispatch(getBulkProducts(text));
    }
    addGroupProduct(formData) {
        this.scrollToDiv();
        const { initialValues } = this.props;
        const { unitCost } = this.state;
        let date1 = new Date();
        let oldDate = dateFormat(initialValues?.effectiveOnDate);
        let currentDate = dateFormat(date1);
        let checkDate = currentDate < oldDate;
        let a;
        if (formData.unitPrice !== undefined) {
            a = formData.unitPrice
        } else {
            a = checkDate ? formData.currentPrice : formData.effectivePrice;
        }

        if (!formData.Quantity) {
            throw new SubmissionError({
                Quantity: 'Please fill the field *',
            })
        }
        if (formData.productId !== undefined) {
            const { str, } = this.props;
            if (str !== "edit") {
                let data = {
                    "projectId": this.props.projectId,
                    "productId": formData.productId,
                    "quantity": +formData.Quantity,
                    "unitCost": unitCost === 0 ? Number(formData.unitPrice) : unitCost
                }
                this.props.dispatch(createPoProduct(data));
                document.getElementsByClassName("rbt-close")[0].click();
            } else {
                let data = {
                    "projectProductId": this.props.data.purchaseProjectProductId,
                    "productId": formData?.productId,
                    "quantity": +formData.Quantity,
                    "unitCost": unitCost === 0 ? Number(formData.unitPrice) : unitCost
                }
                this.props.dispatch(editPoProduct(data));
            }
        } else {
            toast("Choose a product")
        }
    }
    clearSingleProductTrigger() {
        this.props.dispatch(clearSingleProduct());
    }
    componentDidUpdate(prevProps) {
        if (prevProps.poProductSuccess !== this.props.poProductSuccess) {
            const { initialValues, poProductSuccess, onHide, str, data } = this.props;
            if (poProductSuccess.status === 200) {
                if (str === "edit") {
                    this.setState({
                        changed: false,
                    })
                    onHide();
                }
                this.setState({
                    total: 0,
                    unitCost: 0
                })
                toast(poProductSuccess.data);
                this.props.dispatch(getPoProjectDetail(this.props.purchaseId));
            }
            else {
                toast(poProductSuccess.data);
                this.setState({
                    total: 0,
                    quantity: 0,
                    discount: 0,
                    unitCost: 0
                })
            }
            initialValues.articleId = "";
            initialValues.unitName = "";
            initialValues.currentPrice = "";
            initialValues.effectivePrice = "";
            data.totalPrice = "";
            this.props.dispatch(clearSingleProduct());
            this.props.reset();
            //clear typehead
            // document.getElementsByClassName("rbt-close")[0].click();
        }
        if (prevProps.bulkProducts !== this.props.bulkProducts) {
            this.setState({
                items: this.props.bulkProducts.content,
            });
        }
    }
    componentDidMount() {
        this.props.dispatch(getBulkProducts(""));
    }
    render() {
        const { handleSubmit, show, onHide, reset, initialValues, productLoad, str, data } = this.props;
        const { total, changed } = this.state;

        let date1 = new Date();
        let oldDate = dateFormat(initialValues?.effectiveOnDate);
        let currentDate = dateFormat(date1);
        let checkDate = currentDate < oldDate;
        let a = '';
        if (initialValues.unitPrice !== undefined) {
            a = 'unitPrice'
        } else {
            a = checkDate ? "currentPrice" : "effectivePrice"
        }


        return (
            <>
                {show ? <div
                    className='mini-popup product_pop'
                    show={show}
                    onHide={() => {
                        this.setState({
                            total: 0,
                            quantity: 0,
                            discount: 0,
                            changed: false
                        })
                        initialValues.articleId = "";
                        initialValues.unitName = "";
                        initialValues.currentPrice = "";
                        initialValues.effectivePrice = "";
                        data.totalPrice = "";
                        reset();
                        onHide();
                    }}
                >
                    <div className="modal-staff-here main" >
                        <form onSubmit={handleSubmit(this.addGroupProduct)}>
                            <Row className="modal-row">
                                <Col md={1}>
                                    <i class="fas fa-arrow-left" style={{ marginTop: "20px" }} onClick={() => {
                                        this.setState({
                                            total: 0,
                                            quantity: 0,
                                            discount: 0,
                                            changed: false
                                        })
                                        initialValues.articleId = "";
                                        initialValues.unitName = "";
                                        initialValues.currentPrice = "";
                                        initialValues.effectivePrice = "";
                                        reset();
                                        onHide();
                                    }}></i>
                                </Col>
                                <Col md={str !== "edit" ? 11 : 10} style={{ marginTop: "10px", marginLeft: "-70px", width: str !== "edit" ? "100%" : "120%" }}>
                                    <label class="form-control-placeholder type-form" for="name" >Product Name <span>*</span></label>
                                    {str === "edit" && !changed ?
                                        <div >
                                            <p className="border-area" dangerouslySetInnerHTML={{ __html: initialValues?.productName }}></p>
                                        </div> :
                                        this.state.items?.length >= 0 ?
                                            <div>
                                                <Typeahead
                                                    onChange={this.customerChange}
                                                    ref={(ref) => this._typeahead = ref}
                                                    id="async-example"
                                                    style={{ width: !changed ? "105%" : "100%" }}
                                                    labelKey={this.state.items.map(item => item.productName)}
                                                    onInputChange={this.inputChange}
                                                    options={this.state.items.map(item => item.productName)}
                                                    clearButton={true}
                                                    instanceRef={this.myRef}
                                                    className="new-bot"
                                                />
                                                <div className="triangle-bottom" style={{ marginTop: "-22px", right: !changed ? "-22px" : "30px" }}></div>
                                            </div>
                                            : <></>}
                                </Col>
                                {str === "edit" && !changed ? <Col md={1}>
                                    <button type='button' className='change-pro-btn' onClick={this.changeHandler}>Change Product </button>
                                </Col> : str !== "add" && <Col md={1}></Col>}
                                <Col md={1} style={{ marginLeft: "40px" }}>
                                    <label class="form-control-placeholder" for="name">Qty <span>*</span></label>
                                    <Field
                                        name="Quantity"
                                        type="text"
                                        component={renderField}
                                        onChange={this.getQuantity}
                                        maxLength="4"
                                    />
                                </Col>
                                {str === "edit" && !changed && <Col md={1}>
                                    <label class="form-control-placeholder" for="name">Unit Cost <span>*</span></label>
                                    <Field
                                        name="unitPrice"
                                        type="text"
                                        component={renderField}
                                        onChange={this.getUnitCost}
                                    />
                                </Col>}
                                <Col md={6}>
                                    <div className='add_product_new'>
                                        <div>
                                            <p>Article ID</p>
                                            <h3>{initialValues?.articleId || 0}</h3>
                                        </div>
                                        <div>
                                            <p>Available Stock</p>
                                            <h3>{initialValues?.totalStock}</h3>
                                        </div>
                                        <div>
                                            <p>Unit Name</p>
                                            <h3>{initialValues?.unitName}</h3>
                                        </div>
                                        {changed && <div>
                                            <p>Unit Cost</p>
                                            <h3 style={{ textAlign: "right" }}>{str === "edit" && !changed ? data?.unitPrice : checkDate ? initialValues?.currentPrice : initialValues?.effectivePrice}</h3>
                                        </div>}
                                        <div>
                                            <p>Total Cost</p>
                                            <h3 style={{ textAlign: "right" }}>{total === 0 ? str === "edit" && !changed && displayCurrency(data?.totalPrice) : displayCurrency(total)}</h3>
                                        </div>
                                    </div>
                                </Col>
                                <Field
                                    name="productId"
                                    type="hidden"
                                    component={renderField}
                                    placeholder=""
                                />
                                <Col md={3}>
                                    {!productLoad ? <button type="submit" className="modal-btn sub-modal-btn" style={{ width: "120%" }}>
                                        {str === "edit" ? "Update" : "Add"} Product
                                    </button> : <button type="button" className="modal-btn sub-modal-btn" style={{ width: "120%" }}>Loading..</button>}
                                </Col>
                            </Row>
                        </form>
                    </div>
                </div> : null}
            </>
        );
    }
}

function mapStateToProps(state) {
    let item = state.product.singleProduct;
    let page = state.po.page;
    let newItem = page === "edit" ? getProduct() : {};
    let data = {
        'articleId': newItem?.articleId,
        'productName': newItem.productName,
        'Quantity': newItem.quantity,
        'unitName': newItem?.unit?.unitName,
        'productId': newItem?.productId,
        'unitPrice': newItem?.unitPrice,
        'totalStock': newItem?.availableStock,
        'totalCost': newItem.totalPrice
    };
    let initValues = item !== null ?
        {
            'articleId': item?.articleId,
            'discount': 0,
            'unitName': item?.unitName,
            'productId': item?.productId,
            'currentPrice': isNaN(item?.currentPrice) ? 0 : item?.currentPrice * (50 / 100),
            'effectivePrice': isNaN(item?.effectivePrice) ? 0 : item?.effectivePrice * (50 / 100),
            'totalStock': item?.totalStock
        } : data;

    return {
        initialValues: initValues,
        productLoad: state.po.productLoad,
        bulkProducts: state.product.bulkProducts,
        singleProduct: state.product.singleProduct,
        poProductSuccess: state.po.poProductSuccess,
    }
}

const AddPoProductForm = reduxForm({
    form: "AddPoProduct",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate
})(AddPoProduct);

export default connect(mapStateToProps)(AddPoProductForm);