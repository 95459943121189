import React from 'react'
import { Field, reduxForm } from 'redux-form';
import Plus from "../../assets/images/pl.svg";
import { useParams } from "react-router-dom";
import { Row, Col, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import validate from "./validate";
import "antd/dist/antd.css";


const renderField = ({ input, placeholder, maxLength, type, meta: { touched, error } }) => (
    <div>
        <label></label>
        <div>
            <input  {...input} placeholder={placeholder} type={type} className="form-control" maxLength={maxLength} />
            {/* {type === "tel" ? <span className="call-now" > + 91 </span> : ""} */}
            {touched && error && <p className="require">{error}</p>}
        </div>
    </div>
);

const renderSelectField = ({ input, meta: { touched, error }, children }) => (
    <div>
        <div>
            <select {...input} className='form-control form-select mt-4'>
                {children}
            </select>
            {touched && error && <p className="require">{error}</p>}
        </div>
    </div>
)

const CustomerForm = props => {
    const { error, handleSubmit, show, onHide, page, str, reset, handleShow } = props;
    const showModal = () => {
        reset();
        handleShow();
    }

    return (
        <>
            {/* {quotationId === undefined ? <button className="add-staff" onClick={showModal}>
                <img src={Plus} alt="" /> Add Customer
            </button> : <></>} */}
            <Modal
                show={show}
                size="lg"
                onHide={() => {
                    reset();
                    onHide();
                }}
                aria-labelledby="contained-modal-title-vcenter"
                centered >
                <div className="modal-staff">
                    <div className="head">
                        <div>
                            <i class="fas fa-arrow-left" onClick={() => {
                                reset();
                                onHide();
                            }}></i>
                            <h4>{str === "edit" ? "UPDATE" : "ADD NEW"} CUSTOMER</h4>
                        </div>
                    </div>
                    <h4 className="customer-head">Customer Details</h4>
                    <Row>
                        <form onSubmit={handleSubmit} className="customer">
                            <Row>
                                <Col md={4}>
                                    <div className='initial'>
                                        <label class="form-control-placeholder" style={{ marginTop: "3px" }} for="name">Select one initial <span>*</span></label>
                                        <Field name="customerInitials" component={renderSelectField} className="form-control form-select" placeholder="Select one initial">
                                            <option value="" disabled={true} hidden={true}></option>
                                            <option value="Mr" >Mr</option>
                                            <option value="M/s" >M/s</option>
                                            <option value="Mrs">Mrs</option>
                                            <option value="Miss" >Miss</option>
                                        </Field>
                                        <div className="triangle-bottom"></div>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <label class="form-control-placeholder" for="name">First Name <span>*</span></label>
                                    <Field name="firstName" type="text" component={renderField} maxLength="25" />
                                </Col>
                                <Col md={4}>
                                    <label class="form-control-placeholder" for="name">Last Name </label>
                                    <Field name="lastName" type="text" component={renderField} maxLength="25" />
                                </Col>
                                <Col md={4} className="call-field">
                                    <label class="form-control-placeholder" for="name">Contact Number</label>
                                    <span className="call-now" > + 91 </span>
                                    <Field name="phone" type="tel" component={renderField} maxLength="10" />
                                </Col>
                                <Col md={4}>
                                    <label class="form-control-placeholder" for="name">Email Address</label>
                                    <Field name="email" type="email" component={renderField} />
                                </Col>

                                <Col md={12}>
                                    <label class="form-control-placeholder" for="name">Address Line 1</label>
                                    <Field name="address" type="text" component={renderField} />
                                </Col>
                                <Col md={12}>
                                    <label class="form-control-placeholder" for="name">Address Line 2</label>
                                    <Field name="addressLine2" type="text" component={renderField} />
                                </Col>
                                <Col md={4}>
                                    <label class="form-control-placeholder" for="name">City</label>
                                    <Field name="city" type="text" component={renderField} maxLength="25" />
                                </Col>
                                <Col md={4}>
                                    <label class="form-control-placeholder" for="name">State</label>
                                    <Field name="state" type="text" component={renderField} maxLength="25" />
                                </Col>
                                <Col md={4}>
                                    <label class="form-control-placeholder" for="name">Pincode</label>
                                    <Field name="pincode" type="text" component={renderField} maxLength="6" />
                                    <Field name="customerId" type="hidden" component={renderField} placeholder="Enter Email Address" />
                                </Col>
                                {error && <strong>{error}</strong>}
                                <Col md={6}>
                                    <button type="submit" className="customer-btn">
                                        {str === "edit" ? "Update" : "Create"} Customer
                                    </button>
                                </Col>
                            </Row>
                        </form>
                    </Row>
                </div>
            </Modal>
        </>
    )
}

function mapStateToProps(state) {
    let page = state.customer.page;
    let initValues = page === "edit" ? state.customer.customer : {};

    return {
        initialValues: initValues,
        customer: state.customer.customer,
        status: state.customer.status,
        page: state.customer.page
    }
}

const Form = reduxForm({
    form: "customerForm",
    enableReinitialize: true,
    validate
})(CustomerForm);

export default connect(mapStateToProps)(Form);


