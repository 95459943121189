import { productConstants } from "../constants";

export const getProductList = (data) => {
    console.log(data);
    return {
        type: productConstants.GET_PRODUCTS,
        data
    };
};


// export const getProductList = (pageNo, searchKey, sort, filterData, size) => {
//     return {
//         type: productConstants.GET_PRODUCTS,
//         pageNo,
//         searchKey,
//         sort,
//         filterData,
//         size
//     };
// };

export const createProduct = (formData) => {
    return {
        type: productConstants.CREATE_PRODUCT_INIT,
        formData
    };
};

export const updateProduct = (formData) => {
    return {
        type: productConstants.UPDATE_PRODUCT_INIT,
        formData
    };
};

export const getProductUnits = () => {
    return {
        type: productConstants.GET_PRODUCT_UNIT,
    };
};


export const getProductById = (productId) => {
    return {
        type: productConstants.GET_SINGLE_PRODUCT,
        productId
    };
};


export const getProductHistory = (productId) => {
    return {
        type: productConstants.GET_PRODUCTS_HISTORY,
        productId
    };
};

export const getBulkProducts = (searchKey) => {
    return {
        type: productConstants.GET_BLUK_PRODUCTS,
        searchKey
    };
};
export const createStock = (formData) => {
    return {
        type: productConstants.ADD_STOCK,
        formData
    };
};

export const changePage = () => {
    return {
        type: productConstants.CHANGE_PAGE,
    }
}

export const clearStockProduct = () => {
    return {
        type: productConstants.CLEAR_STOCK_PRODUCT,
    }
}

export const clearSingleProduct = () => {
    return {
        type: productConstants.CLEAR_SINGLE_PRODUCT,
    }
}

export const getStockProduct = () => {
    return {
        type: productConstants.GET_STOCK,
    }
}