export const customerConstants = {
    GET_CUSTOMERS: "GET_CUSTOMERS_LIST",
    GET_CUSTOMERS_SUCCESS: "GET_CUSTOMERS_LIST_SUCCESS",
    GET_CUSTOMERS_FAILURE: "GET_CUSTOMERS_LIST_FAILURE",

    CREATE_CUSTOMER_INIT: "CREATE_CUSTOMER_INIT",
    CREATE_CUSTOMER_SUCCESS: "CREATE_CUSTOMER_SUCCESS",
    CREATE_CUSTOMER_FAILURE: "CREATE_CUSTOMER_FAILURE",

    GET_CUSTOMER_INIT: "GET_CUSTOMER_INIT",
    GET_CUSTOMER_SUCCESS: "GET_CUSTOMER_SUCCESS",
    GET_CUSTOMER_FAILURE: "GET_CUSTOMER_FAILURE",

    UPDATE_CUSTOMER_INIT: "UPDATE_CUSTOMER_INIT",
    UPDATE_CUSTOMER_SUCCESS: "UPDATE_CUSTOMER_SUCCESS",
    UPDATE_CUSTOMER_FAILURE: "UPDATE_CUSTOMER_FAILURE",

    CHANGE_PAGE: "CHANGE_PAGE",
    CLEAR_CUSTOMER_VALUES: "CLEAR_CUSTOMER_VALUES"
}