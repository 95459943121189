import { reportPurchaseConstants } from "../constants";

const initialState = {
    loading: false,
    data: [],
    error: [],
    token: "",
    status: 0,
};

export default function reportPurchaseReducer(state = initialState, { type, response }) {
    switch (type) {
        case reportPurchaseConstants.GET_REPORT_PURCHASE:
            return {
                ...state,
                loading: true,
            };
        case reportPurchaseConstants.GET_REPORT_PURCHASE_SUCCESS:
            return {
                ...state,
                loading: false,
                report_Purchase: response.data,
                allData: response.data,
            };
        case reportPurchaseConstants.GET_REPORT_PURCHASE_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            };
        default:
            return state;
    }
}
