import { authToken, baseUrl } from "../actions";

export const getBillOfQuantityApi = (item) => {
    let BILL_OF_QUALITY_LIST = `${baseUrl}staff/billOfQuantity?${item}`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(BILL_OF_QUALITY_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};
export const createBoqApi = (request) => {
    const CREATE_BILL_OF_QUALITY = `${baseUrl}staff/billOfQuantity`;
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(CREATE_BILL_OF_QUALITY, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const getConfirmQuotDropdownApi = () => {
    let BILL_OF_QUALITY_LIST = `${baseUrl}staff/dropdown/confirmedQuotation`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(BILL_OF_QUALITY_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};
export const getConfirmQuotProductsApi = (quotationId) => {
    let BILL_OF_QUALITY_LIST = `${baseUrl}staff/confirmedQuotationProducts/${quotationId}`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(BILL_OF_QUALITY_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};
export const getConvertBoqToPoList = () => {
    const BILL_OF_QUALITY_LIST = `${baseUrl}staff/listOfBoqForConvertToPo`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(BILL_OF_QUALITY_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
}
export const createBillOfQuantityApi = (request) => {
    const CREATE_BILL_OF_QUALITY = `${baseUrl}staff/billOfQuantity/createBillOfQuantity`;
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(CREATE_BILL_OF_QUALITY, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const getOneBillOfQuantityApi = (billofquantityId) => {
    const BILL_OF_QUALITY_LIST = `${baseUrl}staff/purchaseOrder/${billofquantityId}`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return fetch(BILL_OF_QUALITY_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
}
export const updateBillOfQuantityPoApi = (request) => {
    const UPDATE_BILL_OF_QUALITY = `${baseUrl}staff/billOfQuantity`;
    const parameters = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(UPDATE_BILL_OF_QUALITY, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const getBoqProjectDetailApi = (id) => {
    let BILL_OF_QUALITY_LIST = `${baseUrl}staff/billOfQuantity/${id}/projectsProductDetail`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(BILL_OF_QUALITY_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};
export const createBoqProjectApi = (request) => {
    const CREATE_BILL_OF_QUALITY = `${baseUrl}staff/billOfQuantityProject`;
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(CREATE_BILL_OF_QUALITY, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const updateBoqProjectApi = (request) => {
    const CREATE_BILL_OF_QUALITY = `${baseUrl}staff/billOfQuantityProject`;
    const parameters = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(CREATE_BILL_OF_QUALITY, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const createBoqProductApi = (request) => {
    const CREATE_CUSTOMER = `${baseUrl}staff/billOfQuantityProduct`;
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(CREATE_CUSTOMER, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const updateBoqProductApi = (request) => {
    const CREATE_CUSTOMER = `${baseUrl}staff/billOfQuantityProduct`;
    const parameters = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(CREATE_CUSTOMER, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const deleteBoqProductApi = (id) => {
    let BILL_OF_QUALITY_LIST = `${baseUrl}staff/billOfQuantityProduct/${id}`;
    const parameters = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(BILL_OF_QUALITY_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};
export const previewBoqApi = (id) => {
    const PREVIEW_QUOTATION = `${baseUrl}public/billOfQuantity/${id}/html`;
    return fetch(PREVIEW_QUOTATION).then(response => {
        return response.text() // response.json()
    }).then((page) => {
        return page;
    }).catch((error) => {
        console.error(error)
    })
}
export const getBoqByIdApi = (id) => {
    let BILL_OF_QUALITY_LIST = `${baseUrl}staff/billOfQuantity/${id}`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(BILL_OF_QUALITY_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};
export const generateBoqApi = (id) => {
    const PREVIEW_QUOTATION = `${baseUrl}staff/billOfQuantity/${id}/generate`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(PREVIEW_QUOTATION, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
}
export const sendBoqApi = (id) => {
    const PREVIEW_QUOTATION = `${baseUrl}staff/billOfQuantity/${id}/markSent`;
    const parameters = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(PREVIEW_QUOTATION, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
}
export const cloneBoqApi = (id) => {
    const PREVIEW_QUOTATION = `${baseUrl}staff/billOfQuantity/${id}/clone`;
    const parameters = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(PREVIEW_QUOTATION, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
}
export const deleteBoqApi = (id) => {
    const PREVIEW_QUOTATION = `${baseUrl}staff/billOfQuantity/${id}`;
    const parameters = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(PREVIEW_QUOTATION, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
}
export const reviseBoqApi = (id) => {
    const PREVIEW_QUOTATION = `${baseUrl}staff/billOfQuantity/${id}/clone?revised=true`;
    const parameters = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(PREVIEW_QUOTATION, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
}
export const editBoqCustomProductApi = (request) => {
    const CREATE_CUSTOMER = `${baseUrl}staff/billOfQuantityProduct/editCustomProduct`;
    const parameters = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };
    return fetch(CREATE_CUSTOMER, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const getProjectOutwardListApi = () => {
    let BILL_OF_QUALITY_LIST = `${baseUrl}staff/listOfProjectsForOutwards`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(BILL_OF_QUALITY_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};
export const getInternalTransferProductApi = (id) => {
    const PREVIEW_QUOTATION = `${baseUrl}staff/billOfQuantity/${id}/internalTransfer/productDetails`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(PREVIEW_QUOTATION, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
}
export const deliverBoqApi = (request) => {
    const CREATE_CUSTOMER = `${baseUrl}staff/billOfQuantity/deliver`;
    const parameters = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(CREATE_CUSTOMER, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const updateInternalTransferApi = (request) => {
    const CREATE_BILL_OF_QUALITY = `${baseUrl}staff/billOfQuantity/internalTransfer`;
    const parameters = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(CREATE_BILL_OF_QUALITY, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const previewPackingSlipApi = (id) => {
    const PREVIEW_QUOTATION = `${baseUrl}public/packingSlip/${id}/html`;
    return fetch(PREVIEW_QUOTATION).then(response => {
        return response.text() // response.json()
    }).then((page) => {
        return page;
    }).catch((error) => {
        console.error(error)
    })
}
export const getBoqProductDetailsApi = (id) => {
    let BILL_OF_QUALITY_LIST = `${baseUrl}staff/outward/packingSlip/${id}`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(BILL_OF_QUALITY_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};
export const getBoqMailDetailsApi = (id) => {
    let BILL_OF_QUALITY_LIST = `${baseUrl}staff/billOfQuantity/sendMail/${id}`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(BILL_OF_QUALITY_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};
export const sendBoqMailApi = (request) => {
    const CREATE_CUSTOMER = `${baseUrl}staff/billOfQuantity/sendMail`;
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };
    return fetch(CREATE_CUSTOMER, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}