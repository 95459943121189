import React from 'react';
import validate from './validate';
import { Field, reduxForm } from 'redux-form';
import back from "../../../../assets/images/arrow-back.svg";
import Back from "../../../../assets/images/out.svg";
import { Row, Col, Form } from "react-bootstrap";
import { connect } from "react-redux";
import {
    getProductList,
    getProductById,
    createProduct,
    getAllUnits,
    createPoProduct,
    getPoProjectDetail,
    updateCustomPoProduct
} from "../../../../actions";
import { SubmissionError } from 'redux-form';
import { Editor } from "@tinymce/tinymce-react";
import { toast } from 'react-toastify';

const renderField = ({ input, placeholder, maxLength, type, readOnly, meta: { touched, error } }) => (
    <div>
        <label></label>
        <div>
            <input {...input} placeholder={placeholder} type={type} className="form-control" maxLength={maxLength} readOnly={readOnly} />
            {touched && error && <p className="require">{error}</p>}
        </div>
    </div>
);

const renderSelectField = ({ input, meta: { touched, error }, children }) => (
    <div>
        <div>
            <select {...input} className='form-control form-select mt-4'>
                {children}
            </select>
            {touched && error && <p className="require">{error}</p>}
        </div>
    </div>
)

class PoCustomProducts extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            quantity: "",
            total: 0,
            productId: "",
            dataObj: this.props.initialValues?.productName || "",
            productId: "",
            height: 200,
            units_list: [],
            unitCost: "",
            articleId: "",
            unitId: "",
        }
        this.customerChange = this.customerChange.bind(this);
        this.inputChange = this.inputChange.bind(this);
        this.getQuantity = this.getQuantity.bind(this);
        this.addProduct = this.addProduct.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getUnitCost = this.getUnitCost.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(getAllUnits());
    }
    getUnitCost(e) {
        this.setState({
            unitCost: e.target.value
        })
        const { initialValues } = this.props;
        const { quantity } = this.state;

        let a = quantity === "" ? initialValues.Quantity : quantity;
        console.log(a);
        let discount1 = (Number(e.target.value) * a);
        this.setState({
            total: discount1
        })
    }
    getQuantity(e) {
        this.setState({
            quantity: e.target.value
        })
        const { unitCost } = this.state;
        const { data } = this.props;
        let a = unitCost === "" ? data.unitCost : unitCost;
        let discount1 = (a * Number(e.target.value));
        this.setState({
            total: discount1
        })
    }
    customerChange(e) {
        this.props.products_list?.map((item) => {
            if (item.productName === e[0]) {
                this.props.dispatch(getProductById(item?.productId));
            }
        });
    }
    inputChange(text, e) {
        this.props.dispatch(getProductList(0, text));
    }
    handleChange(content) {
        this.setState({
            dataObj: content
        })
    }
    componentDidUpdate(prevProps) {
        if (prevProps.units_list !== this.props.units_list) {
            this.setState({
                units_list: this.props.units_list,
                dataObj: this.props.initialValues?.productName
            });
        } if (prevProps.groupProductsSuccess !== this.props.groupProductsSuccess) {
            this.setState({
                groupProductsSuccess: this.props.groupProductsSuccess,
                disabled: true,
                total: 0,
            });
            this.props.reset();
        } if (prevProps.success_msg !== this.props.success_msg) {
            const { success_msg } = this.props;
            if (success_msg.status == 200) {
                let submitData = success_msg.data;
                if (submitData?.productId !== undefined) {
                    this.props.reset();
                    this.setState({
                        total: 0,
                        dataObj: ""
                    })
                    let data = {
                        "projectId": this.props.projectId,
                        "productId": submitData.productId,
                        "quantity": this.state.quantity
                    }
                    this.props.dispatch(createPoProduct(data));
                }
            } else {
                toast(success_msg.data);
            }
        } if (prevProps.customProductSuccess !== this.props.customProductSuccess) {
            const { initialValues, customProductSuccess, onHide, str, data } = this.props;
            if (customProductSuccess.status === 200) {
                if (str === "edit") {
                    onHide();
                }
                this.setState({
                    total: 0,
                    dataObj: ""
                })
                toast(customProductSuccess.data);
                this.props.dispatch(getPoProjectDetail(this.props.purchaseId));
            }
            else {
                toast(customProductSuccess.data);
            }
            //clear typehead
            // document.getElementsByClassName("rbt-close")[0].click();
        } if (prevProps.singleProduct !== this.props.singleProduct) {
            this.setState({
                dataObj: this.props.singleProduct?.productName
            })
        }
    }
    addProduct(data) {
        const { str, initialValues } = this.props;
        var formData = new FormData();
        if (this.state.dataObj === "") {
            toast("Please enter a Product Name");
            return false;
        }
        if (str === "edit") {
            let formData = {
                "purchaseProjectProductId": this.props.data?.purchaseProjectProductId,
                "productName": this.state.dataObj,
                "unitCost": data.unitCost,
                "quantity": data.Quantity
            }
            this.props.dispatch(updateCustomPoProduct(formData))
        } else {
            if (data.articleId !== undefined) {
                formData.append('articleId', data.articleId);
            }
            if (!data.Quantity) {
                throw new SubmissionError({
                    Quantity: 'Please fill the field *',
                })
            } else if (!data.unitCost) {
                throw new SubmissionError({
                    unitCost: 'Please fill the field *',
                })
            } else if (!data.unitId) {
                throw new SubmissionError({
                    unitId: 'Please fill the field *',
                })
            }
            formData.append('projectId', this.props.projectId);
            formData.append('quantity', data.Quantity);
            formData.append('productName', this.state.dataObj);
            formData.append("unitId", data.unitId);
            formData.append("price", data.unitCost);
            formData.append('totalCost', this.state.total);
            formData.append('productType', 'dynamic');
            this.props.dispatch(createProduct(formData));
            this.setState({
                // total: "",
                articleId: data.articleId,
                unitId: data.unitId,
                unitCost: data.unitCost,
                disabled: false,
            })
        }
    }

    render() {
        const { handleSubmit, show, onHide, reset, productLoad, initialValues, str, data } = this.props;
        const { total, units_list, dataObj, height } = this.state;


        return (
            <>
                {/* <button onClick={() => {
                    reset();
                    this.setState({
                        total: 0,
                        quantity: 0,
                        discount: 0,
                    })
                    openPopup();
                }} className="group_product_btns"><img src={plus} alt="" />Add Custom Product</button> */}
                {show ? <div
                    show={show}
                    className='mini-popup product_pop'
                    onHide={() => {
                        this.setState({
                            total: 0,
                            quantity: 0,
                            discount: 0,
                            dataObj: ""
                        })
                        reset();
                        onHide();
                    }}
                >
                    <div className="modal-staff main">
                        <form onSubmit={handleSubmit(this.addProduct)}>
                            <div className="head">
                                <div>
                                    <img src={back}
                                        width="13px"
                                        onClick={() => {
                                            this.setState({
                                                total: 0,
                                                quantity: 0,
                                                discount: 0,
                                            })
                                            reset();
                                            onHide();
                                        }} alt="" />
                                    <h4>{str === "edit" ? "EDIT" : "ADD"} CUSTOM PRODUCT</h4>
                                </div>
                                <img src={Back} alt="" onClick={onHide} className="back_img" style={{ marginTop: "5px" }} />
                            </div>
                            <Row className="modal-row-form">
                                <Col md={12}>
                                    <Editor
                                        apiKey="2j6wvm4x2pa25n680e9pbtskcfhyi2qdsvjut2c24rm83049"
                                        value={dataObj}
                                        init={{
                                            height: height,
                                            menubar: false,
                                        }}
                                        onEditorChange={this.handleChange}
                                    />
                                </Col>
                                <Col md={6}>
                                    <label class="form-control-placeholder" for="name">Article ID </label>
                                    <Field
                                        name="articleId"
                                        type="text"
                                        component={renderField}
                                    />
                                </Col>
                                <Col md={3}>
                                    <label class="form-control-placeholder" for="name">Quantity <span>*</span></label>
                                    <Field
                                        name="Quantity"
                                        type="text"
                                        component={renderField}
                                        onChange={this.getQuantity}
                                        maxLength="4"
                                    />
                                </Col>
                                <Col md={3}>
                                    <label class="form-control-placeholder" for="name">Select One Unit <span>*</span></label>
                                    <Field name="unitId" component={renderSelectField} className='form-control form-select mt-4' readOnly>
                                        <option value="" disabled={true} hidden={true}>Select One Unit </option>
                                        {units_list ?? units_list.length > 0 ? units_list.map((unit, i) => (
                                            <option value={unit?.unitId} key={i}>{unit?.unitName}</option>)) : ""}
                                    </Field>
                                    <div className="triangle-bottom"></div>
                                </Col>
                                <Col md={3}>
                                    <label class="form-control-placeholder" for="name">Unit Cost <span>*</span></label>
                                    <Field
                                        name="unitCost"
                                        onChange={this.getUnitCost}
                                        type="text"
                                        component={renderField}
                                    />
                                </Col>
                                <Col md={3}>
                                    <label class="form-control-placeholder" for="name">Total Cost <span>*</span></label>
                                    <Form.Label></Form.Label>
                                    <Form.Control
                                        type="text"
                                        disabled
                                        name="totalCost"
                                        value={total === 0 ? isNaN(data?.totalCost) ? "" : data?.totalCost : total}
                                    />
                                </Col>
                                <Col md={6}>
                                    {!productLoad ?
                                        <button type="submit" className="modal-btn sub-modal-btn">
                                            {str === "edit" ? "Update" : "Create"} Custom Product
                                        </button>
                                        : <button type="button" className="modal-btn sub-modal-btn">Adding..</button>}
                                </Col>
                            </Row>
                        </form>
                    </div>
                </div> : null}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        products_list: state.product.products_list,
        productLoad: state.po.productLoad,
        singleProduct: state.product.singleProduct,
        page: state.product.page,
        success_msg: state.product.success_msg,
        groupProductsSuccess: state.qutation.groupProductsSuccess,
        units_list: state.unit.units_list,
        customProductSuccess: state.po.customProductSuccess,
        singleProduct: state.product.singleProduct,
    }
}

const CustomProductsForm = reduxForm({
    form: "CustomProductsForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate
})(PoCustomProducts);

export default connect(mapStateToProps)(CustomProductsForm);