import { authToken ,baseUrl} from "../actions";

export const getreportPurchaseApi = (pageNo, filterData = {}) => {

    let REPORT_CUSTOMER;

    if (Object.keys(filterData).length > 0) {
        let fn = filterData.firstName ?? "";
        let userId = filterData.userId ?? "";
        let fd = filterData.fromDate ?? "";
        let td = filterData.toDate ?? "";
        REPORT_CUSTOMER = `${baseUrl}staff/reports/purchaseOrder?firstName=${fn}&userId=${userId}&fromDate=${fd}&toDate=${td}&page=${pageNo}`;
    } else {
        REPORT_CUSTOMER = `${baseUrl}staff/reports/purchaseOrder?page=${pageNo}`;
    }

    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return fetch(REPORT_CUSTOMER, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};