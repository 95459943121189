import { reportOaConstants } from "../constants";

const initialState = {
    loading: false,
    data: [],
    error: [],
    status: 0,
};

export default function reportOaReducer(state = initialState, { type, response }) {
    switch (type) {
        case reportOaConstants.GET_REPORT_OA:
            return {
                ...state,
                loading: true,
            };
        case reportOaConstants.GET_REPORT_OA_SUCCESS:
            return {
                ...state,
                loading: false,
                data: response.data,
            };
        case reportOaConstants.GET_REPORT_OA_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            };
        default:
            return state;
    }
}
