import { authToken, baseUrl } from "../actions";

export const getPoProjectDetailApi = (id) => {
    let BILL_OF_QUALITY_LIST = `${baseUrl}staff/purchaseOrder/${id}/projectsProductDetail`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(BILL_OF_QUALITY_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};
export const getVerticesDropdownApi = () => {
    let BILL_OF_QUALITY_LIST = `${baseUrl}public/vertex`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(BILL_OF_QUALITY_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};
export const getBoqProjectDropdownApi = () => {
    let BILL_OF_QUALITY_LIST = `${baseUrl}staff/boqProjects`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(BILL_OF_QUALITY_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};
export const createPoProjectApi = (request) => {
    const CREATE_CUSTOMER = `${baseUrl}staff/purchaseOrderProject`;
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(CREATE_CUSTOMER, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const updatePoProjectApi = (request) => {
    const CREATE_CUSTOMER = `${baseUrl}staff/purchaseOrderProject`;
    const parameters = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(CREATE_CUSTOMER, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const deletePoProjectApi = (id) => {
    let BILL_OF_QUALITY_LIST = `${baseUrl}staff/purchaseOrderProject/${id}`;
    const parameters = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(BILL_OF_QUALITY_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};
export const updatePoProductApi = (request) => {
    const CREATE_CUSTOMER = `${baseUrl}staff/purchaseOrderProject/projectProducts`;
    const parameters = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(CREATE_CUSTOMER, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const deletePoProductApi = (id) => {
    let BILL_OF_QUALITY_LIST = `${baseUrl}staff/purchaseOrderProject/projectProducts/${id}`;
    const parameters = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(BILL_OF_QUALITY_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};
export const deletePoApi = (id) => {
    let BILL_OF_QUALITY_LIST = `${baseUrl}staff/purchasedOrder/${id}`;
    const parameters = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(BILL_OF_QUALITY_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};
export const createPoProductApi = (request) => {
    const CREATE_CUSTOMER = `${baseUrl}staff/purchaseOrderProject/products`;
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };
    return fetch(CREATE_CUSTOMER, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const updatePoIgnoreFieldsApi = (request) => {
    const CREATE_CUSTOMER = `${baseUrl}staff/purchaseOrder/ignoredFields`;
    const parameters = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };
    return fetch(CREATE_CUSTOMER, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const getPoFooterApi = (id) => {
    let BILL_OF_QUALITY_LIST = `${baseUrl}staff/purchaseOrder/${id}/footer`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(BILL_OF_QUALITY_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};
export const createPoFooterApi = (request) => {
    const CREATE_CUSTOMER = `${baseUrl}staff/purchaseOrder/footer`;
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(CREATE_CUSTOMER, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const updatePoFooterApi = (request) => {
    const CREATE_CUSTOMER = `${baseUrl}staff/purchaseOrder/footer`;
    const parameters = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(CREATE_CUSTOMER, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const deletePoFooterApi = (id) => {
    let BILL_OF_QUALITY_LIST = `${baseUrl}staff/purchaseOrder/footer/${id}`;
    const parameters = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(BILL_OF_QUALITY_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};
export const previewPoApi = (id) => {
    const PREVIEW_QUOTATION = `${baseUrl}public/purchaseOrder/${id}/html`;
    return fetch(PREVIEW_QUOTATION).then(response => {
        return response.text() // response.json()
    }).then((page) => {
        return page;
    }).catch((error) => {
        console.error(error)
    })
}
export const generatePoApi = (id) => {
    const PREVIEW_QUOTATION = `${baseUrl}staff/purchaseOrder/${id}/generate`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(PREVIEW_QUOTATION, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
}
export const sendPoApi = (id) => {
    const PREVIEW_QUOTATION = `${baseUrl}staff/purchaseOrder/${id}/markSent`;
    const parameters = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(PREVIEW_QUOTATION, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
}
export const clonePoApi = (id) => {
    const PREVIEW_QUOTATION = `${baseUrl}staff/purchaseOrder/${id}/clone`;
    const parameters = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(PREVIEW_QUOTATION, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
}
export const revisePoApi = (id) => {
    const PREVIEW_QUOTATION = `${baseUrl}staff/purchaseOrder/${id}/clone?revised=true`;
    const parameters = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(PREVIEW_QUOTATION, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
}
export const cancelPoApi = (id) => {
    const PREVIEW_QUOTATION = `${baseUrl}staff/purchaseOrder/${id}/cancel`;
    const parameters = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(PREVIEW_QUOTATION, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
}
export const verifyPoApi = (request) => {
    const CREATE_CUSTOMER = `${baseUrl}staff/purchaseOrder/verify`;
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(CREATE_CUSTOMER, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const getConfirmProductsApi = () => {
    let BILL_OF_QUALITY_LIST = `${baseUrl}staff/billOfQuantity/listOfBoqForConvertToPo`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(BILL_OF_QUALITY_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};
export const getPoByIdApi = (id) => {
    let BILL_OF_QUALITY_LIST = `${baseUrl}staff/purchaseOrder/${id}`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(BILL_OF_QUALITY_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};
export const getPoVerifiedProductsApi = (id) => {
    let BILL_OF_QUALITY_LIST = `${baseUrl}staff/purchaseOrder/${id}/verifiedProductList`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(BILL_OF_QUALITY_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};
export const getPoInwardListApi = () => {
    let BILL_OF_QUALITY_LIST = `${baseUrl}staff/inward/verifiedListForInward`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(BILL_OF_QUALITY_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};
export const addPoInwardApi = (request) => {
    const CREATE_CUSTOMER = `${baseUrl}staff/products/stock`;
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };
    return fetch(CREATE_CUSTOMER, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const addPoInwardProductsApi = (request) => {
    const CREATE_CUSTOMER = `${baseUrl}staff/products/inward`;
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };
    return fetch(CREATE_CUSTOMER, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const getPoConvertProductsApi = (request) => {
    const CREATE_CUSTOMER = `${baseUrl}staff/billOfQuantity/listOfBoqProductsForConvertToPo`;
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };
    return fetch(CREATE_CUSTOMER, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const editPoCustomProductApi = (request) => {
    const CREATE_CUSTOMER = `${baseUrl}staff/purchaseOrderProject/editCustomProduct`;
    const parameters = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };
    return fetch(CREATE_CUSTOMER, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const convertToPoApi = (request) => {
    const CREATE_CUSTOMER = `${baseUrl}staff/purchaseOrder/convertBoqToPo`;
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };
    return fetch(CREATE_CUSTOMER, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const getPoMailDetailsApi = (id) => {
    let BILL_OF_QUALITY_LIST = `${baseUrl}staff/purchaseOrder/sendMail/${id}`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(BILL_OF_QUALITY_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};
export const sendPoMailApi = (request) => {
    const CREATE_CUSTOMER = `${baseUrl}staff/purchaseOrder/sendMail`;
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };
    return fetch(CREATE_CUSTOMER, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}

