import { getProduct } from "../actions";
import { outwardConstants, productConstants } from "../constants";

const initialState = {
    loading: false,
    data: [],
    error: [],
    token: "",
    success_msg: "",
    page: "create",
    singleProduct: null,
    bulkProducts: []
};

export default function productReducer(state = initialState, { type, response }) {
    switch (type) {
        case productConstants.GET_PRODUCTS:
        case productConstants.GET_BLUK_PRODUCTS:
            return {
                ...state,
                loading: true,
            };
        case productConstants.GET_PRODUCTS_SUCCESS:
            return {
                ...state,
                loading: false,
                products_list: response.data.content,
                allData: response.data,
                groupProduct: "add"

            };
        case productConstants.GET_PRODUCTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
                success_msg: "",
            };

        case productConstants.CREATE_PRODUCT_INIT:
        case productConstants.UPDATE_PRODUCT_INIT:
        case productConstants.ADD_STOCK:
            return {
                ...state,
                loading: true,
                success_msg: "",
            };
        case productConstants.ADD_STOCK_SUCCESS:
            return {
                ...state,
                loading: false,
                success_msg: response,
            };
        case productConstants.CREATE_PRODUCT_SUCCESS:
        case productConstants.UPDATE_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                success_msg: response,
            };
        case productConstants.CREATE_PRODUCT_FAILURE:
        case productConstants.UPDATE_PRODUCT_FAILURE:
        case productConstants.ADD_STOCK_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            };

        case productConstants.GET_PRODUCT_UNIT:
            return {
                ...state,
                loading: true,
            };
        case productConstants.GET_PRODUCT_UNIT_SUCCESS:
            return {
                ...state,
                loading: false,
                product_units: response.data,
            };
        case productConstants.GET_BLUK_PRODUCTS_SUCCESS:
            return {
                ...state,
                loading: false,
                bulkProducts: response.data,
            };
        case productConstants.GET_PRODUCT_UNIT_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            };
        case productConstants.GET_SINGLE_PRODUCT:
            return {
                ...state,
                loading: true,
                page: "",
                groupProduct: "add"
            };
        case productConstants.GET_SINGLE_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                singleProduct: response.data,
                groupProduct: "get",
                page: "edit",
                img: response.data.imageUrl
            };
        case productConstants.GET_SINGLE_PRODUCT_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            };
        case productConstants.GET_PRODUCTS_HISTORY:
            return {
                ...state,
                loading: true,
            };
        case productConstants.GET_PRODUCTS_HISTORY_SUCCESS:
            return {
                ...state,
                loading: false,
                product_history: response.data,
            };

        case productConstants.GET_PRODUCTS_HISTORY_FAILURE:
        case productConstants.GET_BLUK_PRODUCTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            };
        case productConstants.CLEAR_SINGLE_PRODUCT:
        case productConstants.CLEAR_STOCK_PRODUCT:
        case outwardConstants.CHANGE_ADD_PAGE:
            return {
                ...state,
                singleProduct: null,
            };
        case productConstants.GET_STOCK:
            return {
                ...state,
                singleProduct: getProduct(),
            };
        case productConstants.CHANGE_PAGE:
            return {
                ...state,
                loading: false,
                page: "create",
                img: ""
            }

        default:
            return state;
    }
}
