import React, { useRef } from "react";
import { Modal, Row } from "react-bootstrap";
import copy from "../../../assets/images/copy.svg";
import back from "../../../assets/images/out.svg";
import mail from "../../../assets/images/mail.svg";
import edit from "../../../assets/images/edit.svg";
import check from "../../../assets/images/check.svg";
import download from "../../../assets/images/download.svg";
import trash from "../../../assets/images/trash1.svg";
import circle from "../../../assets/images/x-circle.svg";
import file from "../../../assets/images/file.svg";
import inward from "../../../assets/images/inward2.svg";
import { reduxForm } from 'redux-form';
import { connect } from "react-redux";
import ReactToPrint from 'react-to-print';
import Logo from "../../../assets/images/main.svg";

function VendorPoView(props) {
    const componentRef = useRef();
    const { show, onHide, data, previewPoText, goTo, load, item } = props;
    let status = data;

    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="modal-staff preview">
                    <div className="modal-staff head">
                        <div>
                            <div onClick={onHide}>
                                <i class="fas fa-arrow-left"></i>
                            </div>
                            <h4 className="mod_head">
                                {item?.purchaseOrderNumber || item?.purchaseOrderReferenceId || "Q-1421"}
                            </h4>
                        </div>
                        <div>
                            {status === "DRAFT" ? <button type="button" className="select-template generate" disabled={load} onClick={props.generate} >
                                <img src={check} alt="" />   Generate
                            </button> : null}
                            {status === "DRAFT" ? <button className="select-template delete_quot" disabled={load} onClick={() => props.delete(data)}>
                                <img src={trash} alt="" />   Delete Po
                            </button> : null}
                            {status === "GENERATED" ? <button type="button" className="select-template sent" disabled={load} onClick={props.send} >
                                <img src={check} alt="" />   Mark as Sent
                            </button> : null}
                            {status === "GENERATED" || status === "SENT" || status === "VERIFIED" || status === "PARTIALLY VERIFIED" || status === "PARTIALLY RECEIVED" || status === "RECEIVED" || status === "REVISED" || status === "DRAFT" ? <button type="button" className="select-template clone" disabled={load} onClick={props.clone}>
                                <img src={copy} alt="" />   Clone
                            </button> : null}
                            {status === "GENERATED" || status === "SENT" || status === "DRAFT" ? <button className="select-template edit_quot" style={{ marginTop: "15px" }} onClick={() => goTo()}>
                                <img src={status === "GENERATED" || status === "SENT" ? file : edit} alt="" />
                                {status === "GENERATED" || status === "SENT" ? "Revise" : "Edit Po"}
                            </button> : null}
                            {status === "VERIFIED" || status === "PARTIALLY VERIFIED" || status === "PARTIALLY RECEIVED" ? <button className="select-template edit_quot" style={{ marginTop: "15px" }} onClick={props.inwardOpen}>
                                <img src={inward} alt="" /> Inward
                            </button> : null}
                            {status === "GENERATED" || status === "SENT" || status === "VERIFIED" || status === "PARTIALLY VERIFIED" || status === "PARTIALLY RECEIVED" || status === "RECEIVED" || status === "REVISED" ?
                                <ReactToPrint
                                    trigger={() => <button className="select-template download"> <img src={download} alt="" /> Download</button>}
                                    content={() => componentRef.current}
                                /> : null}
                            {status === "GENERATED" ? <button type="button" className="select-template send_mail" onClick={props.sendMail} disabled={load}>
                                <img src={mail} alt="" width="16px" />  Send Email
                            </button> : null}
                            {status === "GENERATED" || status === "SENT" || status === "VERIFIED" || status === "PARTIALLY VERIFIED" ? < button className="select-template delete_quot cancel" onClick={props.cancel}>
                                <img src={circle} alt="" /> Cancel
                            </button> : null}
                            {status === "SENT" || status === "PARTIALLY VERIFIED" || status === "PARTIALLY RECEIVED" ? <button type="button" className="select-template verify_po" onClick={props.verify} >
                                <img src={check} alt="" width="13px" />  Verify
                            </button> : null}
                            <img src={back} alt="" onClick={onHide} style={{ marginTop: "5px" }} className="back_img" />
                        </div>
                    </div>
                    <Row className="preview-img " style={{ minHeight: "700px" }}>
                        <div className="go-hr"></div>
                        {previewPoText !== "" ? <div className="template-two-design" ref={componentRef} dangerouslySetInnerHTML={{ __html: `${previewPoText}` }} /> :
                            <div>
                                <img src={Logo} alt=".." className="spin rt-imgss" width="100px" />
                                <h2 className="rt-img">Please Wait...</h2>
                            </div>}
                    </Row>
                </div>
            </Modal>
        </>
    )
}

function mapStateToProps(state) {
    return {
        previewPoText: state.po.previewPoText,
        load: state.po.load
    }
}

const Form = reduxForm({
    form: "ViewForm",
    enableReinitialize: true,
    destroyOnUnmount: false,
})(VendorPoView);

export default connect(mapStateToProps)(Form);
