import React from "react";
import { Card } from "react-bootstrap";
import Navbar from "../../../layouts/Navbar";
import Sidebar from "../../../layouts/Sidebar";
import Spinner from "../../../Components/loader";
import Re from "../../../assets/images/violet1.svg";
import { Link } from "react-router-dom";
import { displayCurrency, getstaffExecutive, getSingleExecutive } from "../../../actions";
import { connect } from "react-redux";
import Pagination from "../../../Components/Pagination";
import SalesExecutiveFilter from "./filter";


const role = localStorage.getItem('userRole');
class StaffExecutive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      all_staff_executive: [],
      allData: [],
      show: false,
      target: null,
      pageNo: 0,
      filterData: [],
      single_list: [],
    };

    this.onChange = this.onChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getStaffExe = this.getStaffExe.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(getstaffExecutive(0));
  }
  componentDidUpdate(prevProps) {
    if (prevProps.staffexecutive_list !== this.props.staffexecutive_list) {
      this.setState({
        all_staff_executive: this.props.staffexecutive_list,
      });
    }
    if (prevProps.allData !== this.props.allData) {
      this.setState({ allData: this.props.allData });
    }
  }
  onChange(pageNo) {
    const { filterData } = this.state;
    this.setState({
      pageNo: pageNo
    })
    this.props.dispatch(getstaffExecutive(pageNo, filterData));
  }
  handleSubmit(filterData) {
    const { pageNo } = this.state;
    this.setState({
      filterData: filterData
    })
    this.props.dispatch(getstaffExecutive(pageNo, filterData));
    this.setState({
      show: false,
    })
  }
  handleClick = (event) => {
    if (!this.state.show) {
      this.setState({
        show: true,
        target: event.target
      })
    } else {
      this.setState({
        show: false,
      })
    }
  };
  getStaffExe() {
    this.setState({
      show: false,
      filterData: []
    })
    this.props.dispatch(getstaffExecutive(0));
  }

  handleEdit(single) {
    this.props.dispatch(getSingleExecutive(single.salesExecutiveId));
  }

  render() {
    const { all_staff_executive, allData, show, target } = this.state;

    let data = {
      totalElements: allData.totalElements,
      totalPages: allData.totalPages,
      offset: allData?.pageable?.offset,
      numberOfElements: allData.numberOfElements,
      num: (allData?.number) + 1
    }
    return (
      <div>
        <Navbar />
        <div className="dashboard-page">
          <div className="dashboard-sidebar">
            <Sidebar />
          </div>
          <div className="dashboard-main">
            <Card className="staff-page">
              <div className="page-head-split">
                <div className="head-colums">
                  <div className="head-part-tab">
                    <img src={Re} alt="..." className="head-imgs" />
                    <h5>Reports</h5>
                  </div>
                  <div className="tab-links">
                    <Link to={"/" + role + "/sale_executive"} className="active">Sales Executive</Link>
                    <Link to={"/" + role + "/sale_executive/customer_executive"}>Customer</Link>
                    <Link to={"/" + role + "/sale_executive/purchase_order"}>Purchase Order</Link>
                    <Link to={"/" + role + "/sale_executive/oa"}>OA</Link>
                  </div>
                  <SalesExecutiveFilter
                    onSubmit={this.handleSubmit}
                    getStaffExe={this.getStaffExe}
                    handleClick={this.handleClick}
                    show={show}
                    target={target}
                    str={this.props.str}
                  />
                </div>
              </div>
              <div className="table-class">
                <table class="table table-borderless">
                  <thead>
                    <tr>
                      {/* <th scope="col">Staff ID</th> */}
                      <th scope="col">Name</th>
                      <th scope="col" style={{ textAlign: "center" }} >Quotations Generated</th>
                      <th style={{ textAlign: "center" }}>Confirmed Quotations</th>
                      <th style={{ textAlign: "right" }}>Confirmed Amount</th>
                      <th scope="col" style={{ textAlign: "right" }}>Estimation Amount</th>
                    </tr>
                  </thead>
                  <tbody className="new-link-staff">
                    {all_staff_executive && all_staff_executive.length > 0 ? (
                      all_staff_executive.map((one) => (
                        <tr key={one.salesExecutiveId} onClick={() => window.location.hash = "/" + role + "/sale_executive/executivepage/" + one.salesExecutiveId + "/" + one.salesExecutiveName} style={{ cursor: "pointer" }}>
                          < td onClick={() => this.handleEdit(one)}>{one.salesExecutiveName}</td>
                          <td onClick={() => this.handleEdit(one)} style={{ textAlign: "center" }} >{one.totalQuotations}</td>
                          <td onClick={() => this.handleEdit(one)} style={{ textAlign: "center" }}>{one.totalConfirmedQuotations}</td>
                          <td onClick={() => this.handleEdit(one)} style={{ textAlign: "right" }}>{displayCurrency(one.estimationConfirmedAmount)}</td>
                          <td onClick={() => this.handleEdit(one)} style={{ textAlign: "right" }}>{displayCurrency(one.estimationAmount)} </td>
                        </tr>
                      ))
                    ) : !this.props.loading ?
                      <h1 className="empty-data">No Records Found</h1> :
                      <Spinner />
                    }
                  </tbody>
                </table>
              </div>
            </Card>
            <Pagination data={data} onChange={this.onChange} />
          </div>
        </div >
      </div >
    );
  }
}


const mapStateToProps = (state) => {

  return {
    loading: state.staffexecutive.loading,
    staffexecutive_list: state.staffexecutive.staffexecutive_list,
    page: state.staffexecutive.page,
    allData: state.staffexecutive.allData,
    single_list: state.staffexecutive.single_list,
  };
};
export default connect(mapStateToProps)(StaffExecutive);






