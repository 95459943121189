import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Navbar from "../../../layouts/Navbar";
import Sidebar from "../../../layouts/Sidebar";
import Orange from "../../../assets/images/qu.svg";
import PreviewTemplate from "./PreviewTemplate";
import { getIgnoreFields, getQutationTemplates, clearSingleCustomer, clearValues } from "../../../actions";
const authRole = localStorage.getItem('userRole');

class SelectTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      templates: [],
      show: false,
      temp: []
    }
    this.clickHandler = this.clickHandler.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }
  componentDidMount() {
    this.props.dispatch(getQutationTemplates());
  }
  componentDidUpdate(prevProps) {
    if (prevProps.quotationTemplates !== this.props.quotationTemplates) {
      const temp = this.props.quotationTemplates.filter((item, i) => {
        if (i < 4) {
          return item;
        }
      });
      this.setState({
        templates: temp
      });
    }
    localStorage.removeItem("newQuotation");
  }
  handleShow(item) {
    this.setState({
      show: true,
      temp: item
    })
  }
  handleClose() {
    this.setState({
      show: false
    })
  }
  clickHandler(item) {
    this.props.dispatch(clearSingleCustomer());
    this.props.dispatch(clearValues());
    window.location.hash = `${authRole}/quotations/create-quotation/customerDetails/${item.templateId}/null`;
  }

  render() {
    const { templates, show, temp } = this.state;

    return (
      <div>
        <Navbar />
        <div className="dashboard-page">
          <div className="dashboard-sidebar">
            <Sidebar />
          </div>
          <div className="dashboard-main">
            <Card className="staff-page">
              <img src={Orange} alt="..." className="head-img" />
              <h5 className='po_link' onClick={() => window.location.hash = "/" + authRole + "/quotations"}>Quotations</h5>
              <i className="fas fa-arrow-right crt"></i>
              <h6>Select Template</h6>
              <Row className="template">
                {templates.map((item, i) => (
                  <div key={i}>
                    <Card className="template-card">
                      <img src={item.templateSampleUrl} onClick={() => this.handleShow(item)} width="230px" />
                      <p>{item.templateName}</p>
                      <button className="select-template" onClick={() => this.clickHandler(item)}>
                        Select Template
                      </button>
                    </Card>
                  </div>
                ))}
              </Row>
            </Card>

          </div>
        </div>
        <PreviewTemplate title={temp.templateName} authRole={authRole} templateId={temp.templateId} temp={temp.templateSampleUrl} show={show} onHide={this.handleClose} />

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.qutation.loading,
    quotationTemplates: state.qutation.quotationTemplates
  }
}

export default connect(mapStateToProps)(SelectTemplate);