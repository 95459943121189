import { poConstants } from "../constants";

const initialState = {
    loading: false,
    load: false,
    productLoad: false,
    poProjectData: [],
    poData: [],
    verticesDropdown: [],
    boqProjectDropdown: [],
    projectSuccess: [],
    poProductSuccess: [],
    deletePoProduct: [],
    deletePoProject: [],
    deletePo: [],
    ignoreData: [],
    footerList: [],
    footerSucess: [],
    deleteFooter: [],
    previewPoText: "",
    generateData: [],
    sendData: [],
    confirmProducts: [],
    cloneData: [],
    reviseData: [],
    verifyProducts: [],
    cancelData: [],
    verifyData: [],
    inwardData: [],
    addInwardData: [],
    page: "",
    convertProducts: [],
    customProductSuccess: [],
    convertPoSuccess: [],
    mailData: [],
    poMailSuccess: [],
    inwardProductsSuccess: []
};

export default function poReducer(state = initialState, { type, response }) {
    switch (type) {
        case poConstants.GET_PO_PRODUCTS:
        case poConstants.GET_VERTICS:
        case poConstants.GET_BOQ_PROJECTS:
        case poConstants.UPDATE_PO_IGNORE_FIELDS:
        case poConstants.GET_PO_FOOTER:
        case poConstants.CREATE_PO_FOOTER:
        case poConstants.UPDATE_PO_FOOTER:
        case poConstants.DELETE_PO_FOOTER:
        case poConstants.PREVIEW_PO:
        case poConstants.GET_CONVERT_PRODUCTS:
        case poConstants.GET_PO_BY_ID:
        case poConstants.GET_PO_VERIFIED_PRODUCTS:
        case poConstants.GET_PO_INWARD:
        case poConstants.GET_MAIL_DETAILS:
            return {
                ...state,
                loading: true,
            };
        case poConstants.CREATE_PO_PROJECT:
        case poConstants.UPDATE_PO_PROJECT:
        case poConstants.DELETE_PO_PROJECT:
        case poConstants.SENT_PO:
        case poConstants.DELETE_PO:
        case poConstants.GENERATE_PO:
        case poConstants.CLONE_PO:
        case poConstants.REVISE_PO:
        case poConstants.CANCEL_PO:
        case poConstants.VERIFY_PO:
        case poConstants.ADD_PO_INWARD:
        case poConstants.GET_PO_CONVERTED_PRODUCTS:
        case poConstants.CONVERT_TO_PO:
        case poConstants.SENT_PO_MAIL:
        case poConstants.CREATE_PO_INWARD_PRODUCTS:
            return {
                ...state,
                load: true,
            };
        case poConstants.CREATE_PO_PRODUCT:
        case poConstants.UPDATE_PO_PRODUCT:
        case poConstants.UPDATE_CUSTOM_PRODUCT:
        case poConstants.DELETE_PO_PRODUCT:
            return {
                ...state,
                productLoad: true,
            };
        case poConstants.GET_PO_INWARD_SUCCESS:
            return {
                ...state,
                loading: false,
                inwardData: response.data,
            };

        case poConstants.GET_PO_PROJECTS_SUCCESS:
            return {
                ...state,
                loading: false,
                poProjectData: response.data,
            };
        case poConstants.GET_VERTICS_SUCCESS:
            return {
                ...state,
                loading: false,
                verticesDropdown: response.data,
            };
        case poConstants.GET_BOQ_PROJECTS_SUCCESS:
            return {
                ...state,
                loading: false,
                boqProjectDropdown: response.data,
            };
        case poConstants.PREVIEW_PO_SUCCESS:
            return {
                ...state,
                loading: false,
                previewPoText: response,
            };
        case poConstants.GET_CONVERT_PRODUCTS_SUCCESS:
            return {
                ...state,
                loading: false,
                confirmProducts: response.data,
            };
        case poConstants.GET_MAIL_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                mailData: response.data,
            };
        case poConstants.CREATE_PO_PROJECT_SUCCESS:
        case poConstants.UPDATE_PO_PROJECT_SUCCESS:
            return {
                ...state,
                load: false,
                projectSuccess: response,
            };
        case poConstants.DELETE_PO_PROJECT_SUCCESS:
            return {
                ...state,
                load: false,
                deletePoProject: response,
            };
        case poConstants.ADD_PO_INWARD_SUCCESS:
            return {
                ...state,
                load: false,
                addInwardData: response,
            };
        case poConstants.CREATE_PO_INWARD_PRODUCTS_SUCCESS:
            return {
                ...state,
                load: false,
                inwardProductsSuccess: response,
            };
        case poConstants.SENT_PO_MAIL_SUCCESS:
            return {
                ...state,
                load: false,
                poMailSuccess: response,
            };
        case poConstants.CREATE_PO_PRODUCT_SUCCESS:
        case poConstants.UPDATE_PO_PRODUCT_SUCCESS:
            return {
                ...state,
                productLoad: false,
                poProductSuccess: response,
            };
        case poConstants.UPDATE_CUSTOM_PRODUCT_SUCCESS:
            return {
                ...state,
                productLoad: false,
                customProductSuccess: response,
            };
        case poConstants.DELETE_PO_PRODUCT_SUCCESS:
            return {
                ...state,
                productLoad: false,
                deletePoProduct: response,
            };
        case poConstants.DELETE_PO_SUCCESS:
            return {
                ...state,
                load: false,
                deletePo: response,
            };
        case poConstants.UPDATE_PO_IGNORE_FIELDS_SUCCESS:
            return {
                ...state,
                loading: false,
                ignoreData: response,
            };
        case poConstants.GET_PO_CONVERTED_PRODUCTS_SUCCESS:
            return {
                ...state,
                load: false,
                convertProducts: response,
            };
        case poConstants.GENERATE_PO_SUCCESS:
            return {
                ...state,
                load: false,
                generateData: response,
            };
        case poConstants.GET_PO_FOOTER_SUCCESS:
            return {
                ...state,
                loading: false,
                footerList: response.data,
            };
        case poConstants.CREATE_PO_FOOTER_SUCCESS:
        case poConstants.UPDATE_PO_FOOTER_SUCCESS:
            return {
                ...state,
                loading: false,
                footerSucess: response,
            };
        case poConstants.DELETE_PO_FOOTER_SUCCESS:
            return {
                ...state,
                loading: false,
                deleteFooter: response,
            };
        case poConstants.GET_PO_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                poData: response.data,
            };
        case poConstants.SENT_PO_SUCCESS:
            return {
                ...state,
                load: false,
                sendData: response,
            };
        case poConstants.CLONE_PO_SUCCESS:
            return {
                ...state,
                load: false,
                cloneData: response,
            };
        case poConstants.REVISE_PO_SUCCESS:
            return {
                ...state,
                load: false,
                reviseData: response,
            };
        case poConstants.CANCEL_PO_SUCCESS:
            return {
                ...state,
                load: false,
                cancelData: response,
            };
        case poConstants.VERIFY_PO_SUCCESS:
            return {
                ...state,
                load: false,
                verifyData: response,
            };
        case poConstants.CONVERT_TO_PO_SUCCESS:
            return {
                ...state,
                load: false,
                convertPoSuccess: response,
            };
        case poConstants.CLEAR_PO_DETAILS:
            return {
                ...state,
                loading: false,
                poData: {},
            };
        case poConstants.GET_PO_VERIFIED_PRODUCTS_SUCCESS:
            return {
                ...state,
                loading: false,
                verifyProducts: response,
            };
        case poConstants.CREATE_PO_PROJECT_FAILURE:
        case poConstants.UPDATE_PO_PROJECT_FAILURE:
        case poConstants.DELETE_PO_PROJECT_FAILURE:
        case poConstants.DELETE_PO_FAILURE:
        case poConstants.GENERATE_PO_FAILURE:
        case poConstants.SENT_PO_FAILURE:
        case poConstants.REVISE_PO_FAILURE:
        case poConstants.CLONE_PO_FAILURE:
        case poConstants.CANCEL_PO_FAILURE:
        case poConstants.VERIFY_PO_FAILURE:
        case poConstants.ADD_PO_INWARD_FAILURE:
        case poConstants.GET_CONVERT_PRODUCTS_FAILURE:
        case poConstants.CONVERT_TO_PO_FAILURE:
        case poConstants.SENT_PO_MAIL_FAILURE:
        case poConstants.CREATE_PO_INWARD_PRODUCTS_FAILURE:
            return {
                ...state,
                load: false,
            };
        case poConstants.CREATE_PO_PRODUCT_FAILURE:
        case poConstants.UPDATE_PO_PRODUCT_FAILURE:
        case poConstants.UPDATE_CUSTOM_PRODUCT_FAILURE:
        case poConstants.DELETE_PO_PRODUCT_FAILURE:
            return {
                ...state,
                productLoad: false,
            };
        case poConstants.CLEAR_PREVIEW:
            return {
                ...state,
                previewPoText: ""
            };
        case poConstants.CHANGE_PAGE:
            return {
                ...state,
                page: "edit"
            };
        case poConstants.CHANGE_ADD_PAGE:
            return {
                ...state,
                page: "add"
            };
        case poConstants.CLEAR_PO_PROJECT:
            return {
                ...state,
                poProjectData: []
            };
        case poConstants.CLEAR_MAIL_DETAILS:
            return {
                ...state,
                mailData: []
            };
        case poConstants.CLEAR_VERIFY_PRODUCTS:
            return {
                ...state,
                verifyProducts: []
            };
        case poConstants.GET_PO_PROJECTS_FAILURE:
        case poConstants.GET_VERTICS_FAILURE:
        case poConstants.GET_BOQ_PROJECTS_FAILURE:
        case poConstants.UPDATE_PO_IGNORE_FIELDS_FAILURE:
        case poConstants.GET_PO_FOOTER_FAILURE:
        case poConstants.CREATE_PO_FOOTER_FAILURE:
        case poConstants.UPDATE_PO_FOOTER_FAILURE:
        case poConstants.DELETE_PO_FOOTER_FAILURE:
        case poConstants.PREVIEW_PO_FAILURE:
        case poConstants.GET_CONVERT_PRODUCTS_FAILURE:
        case poConstants.GET_PO_BY_ID_FAILURE:
        case poConstants.GET_PO_VERIFIED_PRODUCTS_FAILURE:
        case poConstants.GET_PO_INWARD_FAILURE:
        case poConstants.GET_MAIL_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            };
        default:
            return state;
    }
}
