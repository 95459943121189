import React from 'react';
import validate from '../validate';
import plus from "../../../../assets/images/plus-circle2.svg";
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import {
    getProductById,
    getProduct,
    addQuotationProducts,
    dateFormat,
    clearSingleProduct,
    getBulkProducts,
    getGroupDiscount,
    displayCurrency,
    setProduct,
} from "../../../../actions";
import { editGroupProduct } from '../services/servics';
import { Typeahead } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';

const renderField = ({ input, placeholder, maxLength, defaultValue, type, readOnly, meta: { touched, error } }) => (
    <div>
        <label></label>
        <div>
            <input {...input} placeholder={placeholder} type={type} className="form-control" maxLength={maxLength}
                defaultValue={defaultValue}
                readOnly={readOnly} />
            {touched && error && <p className="require">{error}</p>}
        </div>
    </div>
);


class RowForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            quantity: 0,
            discount: 0,
            productId: 0,
            popup: this.props.show,
            changed: false,
            unitCost: 0,
            items: [],
            total: 0,
            groupId: this.props.groupId,
            singleProduct: this.props.singleProduct,
            disabled: true
        }
        this.myRef = React.createRef();
        this.customerChange = this.customerChange.bind(this);
        this.inputChange = this.inputChange.bind(this);
        this.getQuantity = this.getQuantity.bind(this);
        this.getDiscount = this.getDiscount.bind(this);
        this.addGroupProduct = this.addGroupProduct.bind(this);
        this.clearSingleProductTrigger = this.clearSingleProductTrigger.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.getUnitCost = this.getUnitCost.bind(this);
        this.scrollToDiv = this.scrollToDiv.bind(this);
    }
    scrollToDiv = () => {
        // window.scrollTo(0, window.pageYOffset + 100);
        if (this.props.scrolled) {
            const div = document.getElementById('table-bluee');
            if (div !== null) {
                document.getElementById('table-bluee').scrollIntoView({ behavior: 'smooth', block: "start" });
            }
        } else {
            window.scroll({
                top: window.pageYOffset + 100,
                left: 0,
                behavior: 'smooth'
            });
        }
    }
    getUnitCost(e) {
        this.setState({
            unitCost: e.target.value
        })
        const { initialValues } = this.props;
        const { discount, quantity } = this.state;
        let qua = quantity === 0 ? initialValues.Quantity : quantity;
        let dis = discount === 0 ? initialValues?.discount : discount;
        let discount1 = Number(e.target.value * (dis * qua)) / 100;
        this.setState({
            total: (e.target.value * qua) - discount1
        })
    }
    getQuantity(e) {
        this.setState({
            quantity: e.target.value
        })
        const { discount } = this.state;
        const { initialValues } = this.props;
        let date1 = new Date();
        let oldDate = dateFormat(initialValues?.effectiveOnDate);
        let currentDate = dateFormat(date1);
        let checkDate = currentDate < oldDate;
        let price = checkDate ? initialValues?.currentPrice : initialValues?.effectivePrice;
        let discount1 = (price * Number(discount !== 0 ? discount : initialValues?.discount * e.target.value)) / 100;
        this.setState({
            total: (price * e.target.value) - discount1
        })
    }
    getDiscount(e) {
        this.setState({
            discount: e.target.value
        })
        const { quantity, changed } = this.state;
        const { initialValues, str } = this.props;
        let qua = quantity === 0 ? initialValues.Quantity : quantity;
        let date1 = new Date();
        let oldDate = dateFormat(initialValues?.effectiveOnDate);
        let currentDate = dateFormat(date1);
        let checkDate = currentDate < oldDate;
        let price;
        if (str === "edit" && !changed) {
            price = initialValues?.unitCost;
        } else {
            price = checkDate ? initialValues?.currentPrice : initialValues?.effectivePrice
        }
        let discount1 = (price * Number(e.target.value * qua)) / 100;
        this.setState({
            total: (price * qua) - discount1
        })
    }
    customerChange(e) {
        this.setState({
            total: 0,
            // quantity: 0,
            // discount: 0,
        })
        this.props.reset();
        this.state.items?.map((item) => {
            if (item?.productName === e[0]) {
                this.props.dispatch(getProductById(item?.productId));
            }
        });
    }
    inputChange(text) {
        const ele = document.getElementById("async-example");
        if (ele !== null) {
            ele.style.transform = "translate3d(0px, -310px, 0px)";
            ele.style.height = "300px";
        }
        this.props.dispatch(getBulkProducts(text));
    }
    changeHandler() {
        this.props.reset();
        setProduct({})
        this.setState({
            changed: true
        })
    }
    componentDidUpdate(prevProps) {
        if (prevProps.groupProductsSuccess !== this.props.groupProductsSuccess) {
            this.setState({
                groupProductsSuccess: this.props.groupProductsSuccess,
                disabled: true,
                total: 0,
                discount: 0,
                unitCost: 0,
                changed: false
            });
            this.props.getList();
            toast(this.props.groupProductsSuccess.data);
            // dispatch(reset("RowForm"));
            const { initialValues } = this.props;
            initialValues.articleId = "";
            initialValues.unitName = "";
            initialValues.currentPrice = "";
            initialValues.effectivePrice = "";
            this.props.dispatch(clearSingleProduct());
            this.props.reset();
            this.props.getAmounts();
            //clear typehead
            // document.getElementsByClassName("rbt-close")[0].click();
        } if (prevProps.bulkProducts !== this.props.bulkProducts) {
            this.setState({
                items: this.props.bulkProducts.content,
            });
        } if (prevProps.singleProduct !== this.props.singleProduct) {
            const { quantity, changed, discount } = this.state;
            const { initialValues, str } = this.props;
            if (str === "edit") {
                let qua = quantity === 0 ? initialValues.Quantity : quantity;
                let date1 = new Date();
                let oldDate = dateFormat(initialValues?.effectiveOnDate);
                let currentDate = dateFormat(date1);
                let checkDate = currentDate < oldDate;
                let price;
                if (str === "edit" && !changed) {
                    price = initialValues?.unitCost;
                } else {
                    price = checkDate ? initialValues?.currentPrice : initialValues?.effectivePrice
                }
                let discount1 = (price * Number(discount !== 0 ? discount : initialValues?.discount * qua)) / 100;
                this.setState({
                    total: (price * qua) - discount1
                })
            }
        }
    }
    componentDidMount() {
        this.props.dispatch(getBulkProducts(""));
    }
    async addGroupProduct(formData, dispatch) {
        const { data } = this.props;
        this.scrollToDiv();
        if (this.props.str === "edit") {
            if (!formData.Quantity) {
                throw new SubmissionError({
                    Quantity: 'Please fill the field *',
                })
            }
            const { initialValues } = this.props;
            let oldDate = dateFormat(initialValues?.effectiveOnDate);
            let date1 = new Date();
            let currentDate = dateFormat(date1);
            let checkDate = currentDate < oldDate;
            let price = checkDate ? formData?.currentPrice : formData.effectivePrice;
            let submitData = {
                "productId": this.state.changed ? formData.productId : data?.productId,
                "quotationGroupProductId": this.props.data.groupProductId,
                "quantity": Number(formData.Quantity),
                "discount": Number(formData.discount),
                "unitPrice": this.state.changed ? price : Number(formData.unitCost),
            }
            var productData = await editGroupProduct(submitData);
            if (productData?.data?.status === 200) {
                toast(productData.data.data);
                this.props.onHide();
                this.props.reset();
                this.props.getList();
                this.setState({
                    changed: false,
                    total: 0,
                    discount: 0,
                    unitCost: 0
                })
            } else {
                toast(productData.data.data);
            }
        } else {
            if (formData.productId !== undefined) {
                const { initialValues } = this.props;
                let date1 = new Date();
                let oldDate = dateFormat(initialValues?.effectiveOnDate);
                let currentDate = dateFormat(date1);
                let checkDate = currentDate < oldDate;
                let price = checkDate ? initialValues?.currentPrice : initialValues?.effectivePrice;
                let data = {
                    "groupId": this.props.groupId,
                    "productId": formData.productId,
                    "quantity": formData.Quantity,
                    "discount": formData.discount,
                    "unitCost": (this.state.unitCost) !== 0 ? Number(this.state.unitCost) : price,
                }
                this.setState({
                    disabled: false
                })
                document.getElementsByClassName("rbt-close")[0].click();
                this.props.dispatch(addQuotationProducts(data, this.props.groupId));
            } else {
                toast("Choose a product")
            }
        }
    }
    clearSingleProductTrigger() {
        this.props.dispatch(clearSingleProduct());
    }

    render() {
        const { handleSubmit, show, onHide, reset, initialValues, str, data } = this.props;
        const { total, disabled, changed } = this.state;
        let date1 = new Date();
        let oldDate = dateFormat(initialValues?.effectiveOnDate);
        let currentDate = dateFormat(date1);
        let checkDate = currentDate < oldDate;
        return (
            <>
                <button onClick={() => {
                    reset();
                    this.setState({
                        total: 0,
                        popup: true,
                        quantity: "",
                        discount: "",
                        changed: false
                    })
                    initialValues.articleId = "";
                    initialValues.unitName = "";
                    initialValues.currentPrice = "";
                    initialValues.effectivePrice = "";
                    this.clearSingleProductTrigger();
                }} className="group_product_btn" style={{ display: "none" }} >
                    <img src={plus} alt="" />
                    Add Product
                </button>
                {show ? <div
                    className='mini-popup product_pop'
                    show={show}
                    id="mini_pop"
                    onHide={() => {
                        this.setState({
                            total: 0,
                            quantity: 0,
                            discount: 0,
                            changed: false
                        })
                        initialValues.articleId = "";
                        initialValues.unitName = "";
                        initialValues.currentPrice = "";
                        initialValues.effectivePrice = "";
                        reset();
                        onHide();
                    }}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered >
                    <div className="modal-staff-here main">
                        <form onSubmit={handleSubmit(this.addGroupProduct)}>
                            <Row className="modal-row" >
                                <Col md={1} >
                                    <i class="fas fa-arrow-left" style={{ marginTop: "20px" }} onClick={() => {
                                        this.setState({
                                            popup: false,
                                            total: 0,
                                            quantity: 0,
                                            discount: 0,
                                            changed: false,
                                        })
                                        initialValues.articleId = "";
                                        initialValues.unitName = "";
                                        initialValues.currentPrice = "";
                                        initialValues.effectivePrice = "";
                                        reset();
                                        onHide();
                                    }}></i>
                                </Col>
                                <Col md={11} style={{ marginTop: "10px", marginLeft: "-70px" }}>
                                    <label class="form-control-placeholder type-forms" for="name" >Product Name <span>*</span></label>
                                    {str === "edit" && !changed ?
                                        <div onClick={this.changeHandler}>
                                            <p className="border-area" dangerouslySetInnerHTML={{ __html: data?.productName }}></p>
                                        </div> :
                                        this.state.items?.length >= 0 ?
                                            <div>
                                                <Typeahead
                                                    onChange={this.customerChange}
                                                    ref={(ref) => this._typeahead = ref}
                                                    id="async-example"
                                                    labelKey={this.state.items.map(item => item.productName)}
                                                    onInputChange={this.inputChange}
                                                    options={this.state.items.map(item => item.productName)}
                                                    clearButton={true}
                                                    instanceRef={this.myRef}
                                                    style={{ width: "105%" }}
                                                />
                                                <div className="triangle-bottom" style={{ marginTop: "-22px", right: "-15px" }}></div>
                                            </div>
                                            : <></>}
                                </Col>
                                {/* <Col md={1}>
                                    <img src={back} alt="" onClick={onHide} className="close_img" />
                                </Col> */}
                                <Col md={1} style={{ marginLeft: "40px" }}>
                                    <label class="form-control-placeholder" for="name" >Qty <span>*</span></label>
                                    <Field
                                        name="Quantity"
                                        type="text"
                                        component={renderField}
                                        onChange={this.getQuantity}
                                        maxLength="4"
                                    />
                                </Col>
                                <Col md={1}>
                                    <label class="form-control-placeholder" for="name" >Dis</label>
                                    <Field
                                        name="discount"
                                        type="text"
                                        component={renderField}
                                        onChange={this.getDiscount}
                                        maxLength="4"
                                    />
                                </Col>
                                <Col md={1}>
                                    <label class="form-control-placeholder" for="name" >  Unit Cost </label>
                                    {str === "edit" && !changed ?
                                        <Field
                                            name="unitCost"
                                            onChange={this.getUnitCost}
                                            type="text"
                                            component={renderField}
                                        /> : <Field
                                            name={checkDate ? "currentPrice" : "effectivePrice"}
                                            type="text"
                                            onChange={this.getUnitCost}
                                            component={renderField}
                                        />}
                                </Col>
                                <Col md={6}>
                                    <div className='add_product_new'>
                                        <div>
                                            <p>Old  Cost</p>
                                            <h3>{initialValues?.actualCost}</h3>
                                        </div>
                                        <div>
                                            <p>Article ID</p>
                                            <h3>{initialValues?.articleId || 0}</h3>
                                        </div>
                                        <div>
                                            <p>Unit Name</p>
                                            <h3>{initialValues?.unitName}</h3>
                                        </div>
                                        {/* <div>
                                            <p>Unit Cost</p>
                                            <h3>{str === "edit" && !changed ? initialValues?.unitCost : checkDate ? initialValues?.currentPrice : initialValues?.effectivePrice}</h3>
                                        </div> */}
                                        <div>
                                            <p>Total Cost</p>
                                            <h3 style={{ textAlign: "right" }}>{total === 0 ? isNaN(initialValues?.totalCost) ? "" : displayCurrency(initialValues?.totalCost) : displayCurrency(total)}</h3>
                                        </div>
                                    </div>
                                </Col>
                                <Field
                                    name="productId"
                                    type="hidden"
                                    component={renderField}
                                />
                                <Col md={2} style={{ marginTop: "4px" }}>
                                    {disabled ? <button type="submit" className="modal-btn sub-modal-btn" style={{ width: "130%" }}>
                                        {str === "add" ? "Add" : "Update"}  Product
                                    </button> : <button type="button" className="modal-btn sub-modal-btn" style={{ width: "130%" }}>Loading....</button>}
                                </Col>
                                <Col md={5}></Col>

                            </Row>
                        </form>
                    </div>
                </div> : null}
            </>
        );
    }
}

function mapStateToProps(state) {
    let item = state.product.singleProduct;
    let productItem = getProduct();
    let newItem = Object.keys(productItem).length !== 0 ? productItem : {};
    let data = {
        'articleId': newItem?.articleId,
        'productName': newItem.productName,
        'Quantity': newItem.quantity,
        'discount': newItem.discount,
        'unitName': newItem?.unit,
        'productId': newItem?.productId,
        "unitCost": newItem?.unitCost,
        'currentPrice': newItem?.unitCost,
        "effectivePrice": newItem?.unitCost,
        'totalCost': newItem?.totalCost,
        "actualCost": newItem?.actualCost
    };
    let initValues = item !== null ?
        {
            'articleId': item?.articleId,
            'unitName': item?.unitName,
            'productId': item?.productId,
            'currentPrice': item?.currentPrice,
            'effectivePrice': item?.effectivePrice,
            'totalStock': item?.totalStock,
            'Quantity': newItem?.quantity,
            "actualCost": item?.actualPrice

        } : data;

    if (item !== null) {
        initValues.discount = getGroupDiscount();
    }

    return {
        initialValues: initValues,
        bulkProducts: state.product.bulkProducts,
        products_list: state.product.products_list,
        singleProduct: state.product.singleProduct,
        productPage: state.qutation.productPage,
        page: state.product.page,
        groupProductsSuccess: state.qutation.groupProductsSuccess,
    }
}

const AddProductForm = reduxForm({
    form: "RowForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate
})(RowForm);

export default connect(mapStateToProps)(AddProductForm);

