import { authToken, baseUrl } from "../actions";


export const getReportOaApi = (request) => {
    const ADD_STOCK_API = `${baseUrl}staff/reports/orderAcceptance`;
    const parameters = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.data)
    };

    return fetch(ADD_STOCK_API, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    })
};