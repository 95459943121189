import React from "react";
import { connect } from "react-redux";
import Re from "../../../assets/images/violet1.svg";
import { Row, Col, Card } from "react-bootstrap";
import { getReportPurchase, displayCurrency } from "../../../actions";
import "../../../assets/css/button.css";
import "../../../assets/css/popup.css";
import Spinner from "../../../Components/loader";
import Pagination from "../../../Components/Pagination";
import PurchaseFilter from "./purchaseFilter";
import { Link } from "react-router-dom";
import Navbar from "../../../layouts/Navbar";
import Sidebar from "../../../layouts/Sidebar";

const role = localStorage.getItem('userRole');
class PurchaseOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            all_report_purchase: [],
            allData: [],
            show: false,
            target: null,
            pageNo: 0,
            filterData: [],
        };
        this.onChange = this.onChange.bind(this);
        this.getCustomerExe = this.getCustomerExe.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(getReportPurchase(0));
    }

    handleClick = (event) => {
        if (!this.state.show) {
            this.setState({
                show: true,
                target: event.target
            })
        } else {
            this.setState({
                show: false,
            })
        }
    };


    handleSubmit(filterData) {
        const { pageNo } = this.state;
        this.setState({
            filterData: filterData
        })
        this.props.dispatch(getReportPurchase(pageNo, filterData));
        this.setState({
            show: false,
        })
    }

    getCustomerExe() {
        this.setState({
            show: false,
            filterData: []
        })
        this.props.dispatch(getReportPurchase(0));
    }


    componentDidUpdate(prevProps) {
        if (prevProps.report_Purchase !== this.props.report_Purchase) {
            this.setState({
                all_report_purchase: this.props.report_Purchase,
            });
        }
        if (prevProps.allData !== this.props.allData) {
            this.setState({ allData: this.props.allData });
        }
    }

    onChange(pageNo) {
        const { filterData } = this.state;
        this.setState({
            pageNo: pageNo
        })
        this.props.dispatch(getReportPurchase(pageNo, filterData));
    }

    render() {
        const { all_report_purchase, allData, show, target } = this.state;

        let data = {
            totalElements: allData.totalElements,
            totalPages: allData.totalPages,
            offset: allData?.pageable?.offset,
            numberOfElements: allData.numberOfElements,
            num: (allData?.number) + 1
        }
        return (
            <>
                <div>
                    <Navbar />
                    <div className="dashboard-page">
                        <div className="dashboard-sidebar">
                            <Sidebar />
                        </div>
                        <div className="dashboard-main">
                            <Card className="staff-page">
                                <div className="page-head-split">
                                    <div className="head-colums">
                                        <div className="head-part-tab">
                                            <img src={Re} alt="..." className="head-imgs" />
                                            <h5>Reports</h5>
                                        </div>
                                        <div className="tab-links">
                                            <Link to={"/" + role + "/sale_executive"} >Sales Executive</Link>
                                            <Link to={"/" + role + "/sale_executive/customer_executive"}>Customer</Link>
                                            <Link to={"/" + role + "/sale_executive/purchase_order"} className="active">Purchase Order</Link>
                                            <Link to={"/" + role + "/sale_executive/oa"}>OA</Link>
                                        </div>
                                        <PurchaseFilter
                                            onSubmit={this.handleSubmit}
                                            getCustomerExe={this.getCustomerExe}
                                            handleClick={this.handleClick}
                                            show={show} target={target}
                                            str={this.props.str}
                                        />
                                    </div>
                                </div>


                                <div className="table-class">
                                    <table class="table table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope="col">Vendor Id</th>
                                                <th scope="col">First Name</th>
                                                {/* <th scope="col" >Last Name</th> */}
                                                <th style={{ textAlign: "center" }}>Purchase Order Sent</th>
                                                <th style={{ textAlign: "center" }}>Purchase Order Total Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {all_report_purchase.content && all_report_purchase.content.length > 0 ? (
                                                all_report_purchase.content.map((one) => (
                                                    <tr key={one.vendorId}>
                                                        <td>{one.vendorId}</td>
                                                        <td>{one.vendorFirstName}</td>
                                                        {/* <td>{one.vendorLastName}</td> */}
                                                        <td style={{ textAlign: "center" }}>{one.purchaseOrderSent}</td>
                                                        <td style={{ textAlign: "center" }}>{one.purchaseOrderTotalAmount}</td>
                                                    </tr>
                                                ))
                                            ) : !this.props.loading ?
                                                <h1 className="empty-data">No Records Found</h1> :
                                                <Spinner />
                                            }
                                        </tbody>
                                    </table>
                                </div></Card>
                            <Pagination data={data} onChange={this.onChange} />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.purchase.loading,
        report_Purchase: state.purchase.report_Purchase,
        page: state.purchase.page,
        allData: state.purchase.allData,
    };
};
export default connect(mapStateToProps)(PurchaseOrder);

