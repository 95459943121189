import React from 'react'
import { Field, reduxForm } from 'redux-form';
import { Row, Col, Modal } from "react-bootstrap";
import validate from '../validate';

const renderSelectField = ({ input, meta: { touched, error }, children }) => (
  <div>
    <div>
      <select {...input} className='form-control form-select mt-4'>
        {children}
      </select>
      {touched && error && <p className="require" style={{ marginTop: "-23px" }}>{error}</p>}
    </div>
  </div>
);

const renderField = ({ input, placeholder, maxLength, defaultValue, type, readOnly, meta: { touched, error } }) => (
  <div>
    <input {...input} placeholder={placeholder} type={type} className="form-control" maxLength={maxLength}
      defaultValue={defaultValue}
      readOnly={readOnly} />
    {touched && error && <p className="require" style={{ marginTop: "-23px" }}>{error}</p>}
  </div>
);

const GroupForm = props => {
  const { handleSubmit, show, onHide, str, locations, groups } = props;

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered >
      <div className="modal-staff">
        <div className="head">
          <div>
            <i class="fas fa-arrow-left" onClick={onHide}></i>
            <h4>{str === "edit" ? "UPDATE" : "ADD NEW"} GROUP</h4>
          </div>
        </div>
        <Row className='modal-unit'>
          <Col md={12} className="edit-product">
            <form onSubmit={handleSubmit}>
              <label class="form-control-placeholder" for="name">Select One Area <span>*</span></label>
              <Field name="locationId" component={renderSelectField} className="form-control form-select"  >
                <option value="" disabled={true} hidden={true}></option>
                {locations?.map((item, i) =>
                  <option value={item?.locationId} key={i}>{item?.area}</option>)}
              </Field>
              <div className="triangle-bottom"></div>
              <label class="form-control-placeholder" style={{ marginTop: "65px" }} for="name">Select One Group <span>*</span></label>
              <Field name="groupName" component={renderSelectField} className="form-control form-select"  >
                <option value="" disabled={true} hidden={true}></option>
                {groups?.map((item, i) =>
                  <option value={item?.groupName} key={i}>{item?.groupName}</option>)}
              </Field>
              <div className="triangle-bottom" style={{ marginTop: "62px" }}></div>
              <label class="form-control-placeholder" style={{ marginTop: "130px" }} for="name">Enter Discount <span>*</span></label>
              <Field name="discount" component={renderField} type="text" />
              <button type="submit" className="modal-btn">

                {str === "edit" ? "Update" : "Add"} Group
              </button>
            </form>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}

export default reduxForm({
  form: 'GroupForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate // a unique identifier for this form
})(GroupForm)

