export const billofquantityConstants = {
    GET_BILL_OF_QUANTITY: "GET_BILL_OF_QUANTITY_LIST",
    GET_BILL_OF_QUANTITY_SUCCESS: "GET_BILL_OF_QUANTITY_LIST_SUCCESS",
    GET_BILL_OF_QUANTITY_FAILURE: "GET_BILL_OF_QUANTITY_LIST_FAILURE",

    CREATE_BOQ: "CREATE_BOQ",
    CREATE_BOQ_SUCCESS: "CREATE_BOQ_SUCCESS",
    CREATE_BOQ_FAILURE: "CREATE_BOQ_FAILURE",

    GET_CONVERT_BOQ_TO_PO: "GET_CONVERT_BOQ_TO_PO_LIST",
    GET_CONVERT_BOQ_TO_PO_SUCCESS: "GET_CONVERT_BOQ_TO_PO_LIST_SUCCESS",
    GET_CONVERT_BOQ_TO_PO_FAILURE: "GET_CONVERT_BOQ_TO_PO_LIST_FAILURE",

    GET_CONFIRM_QUOTATION_DROPDOWN: "GET_CONFIRM_QUOTATION_DROPDOWN",
    GET_CONFIRM_QUOTATION_DROPDOWN_SUCCESS: "GET_CONFIRM_QUOTATION_DROPDOWN_LIST_SUCCESS",
    GET_CONFIRM_QUOTATION_DROPDOWN_FAILURE: "GET_CONFIRM_QUOTATION_DROPDOWN_LIST_FAILURE",

    GET_CONFIRM_QUOTATION_PRODUCTS: "GET_CONFIRM_QUOTATION_PRODUCTS",
    GET_CONFIRM_QUOTATION_PRODUCTS_SUCCESS: "GET_CONFIRM_QUOTATION_PRODUCTS_LIST_SUCCESS",
    GET_CONFIRM_QUOTATION_PRODUCTS_FAILURE: "GET_CONFIRM_QUOTATION_PRODUCTS_LIST_FAILURE",

    CREATE_BILL_OF_QUANTITY_INIT: "CREATE_BILL_OF_QUANTITY_INIT",
    CREATE_BILL_OF_QUANTITY_SUCCESS: "CREATE_BILL_OF_QUANTITY_SUCCESS",
    CREATE_BILL_OF_QUANTITY_FAILURE: "CREATE_BILL_OF_QUANTITY_FAILURE",

    SINGLE_BILL_OF_QUANTITY_INIT: "SINGLE_BILL_OF_QUANTITY_INIT",
    SINGLE_BILL_OF_QUANTITY_SUCCESS: "SINGLE_BILL_OF_QUANTITY_SUCCESS",
    SINGLE_BILL_OF_QUANTITY_FAILURE: "SINGLE_BILL_OF_QUANTITY_FAILURE",

    UPDATE_BILL_OF_QUANTITY_INIT: "UPDATE_BILL_OF_QUANTITY_INIT",
    UPDATE_BILL_OF_QUANTITY_SUCCESS: "UPDATE_BILL_OF_QUANTITY_SUCCESS",
    UPDATE_BILL_OF_QUANTITY_FAILURE: "UPDATE_BILL_OF_QUANTITY_FAILURE",

    GET_BOQ_PROJECTS: "GET_BOQ_PROJECTS_LIST",
    GET_BOQ_PROJECTS_SUCCESS: "GET_BOQ_PROJECTS_LIST_SUCCESS",
    GET_BOQ_PROJECTS_FAILURE: "GET_BOQ_PROJECTS_LIST_FAILURE",

    CREATE_BOQ_PROJECT: "CREATE_BOQ_PROJECT",
    CREATE_BOQ_PROJECT_SUCCESS: "CREATE_BOQ_PROJECT_SUCCESS",
    CREATE_BOQ_PROJECT_FAILURE: "CREATE_BOQ_PROJECT_FAILURE",

    EDIT_BOQ_PROJECT: "EDIT_BOQ_PROJECT",
    EDIT_BOQ_PROJECT_SUCCESS: "EDIT_BOQ_PROJECT_SUCCESS",
    EDIT_BOQ_PROJECT_FAILURE: "EDIT_BOQ_PROJECT_FAILURE",

    CREATE_BOQ_PRODUCT: "CREATE_BOQ_PRODUCT",
    CREATE_BOQ_PRODUCT_SUCCESS: "CREATE_BOQ_PRODUCT_SUCCESS",
    CREATE_BOQ_PRODUCT_FAILURE: "CREATE_BOQ_PRODUCT_FAILURE",

    EDIT_BOQ_PRODUCT: "EDIT_BOQ_PRODUCT",
    EDIT_BOQ_PRODUCT_SUCCESS: "EDIT_BOQ_PRODUCT_SUCCESS",
    EDIT_BOQ_PRODUCT_FAILURE: "EDIT_BOQ_PRODUCT_FAILURE",

    DELETE_BOQ_PRODUCT: "DELETE_BOQ_PRODUCT",
    DELETE_BOQ_PRODUCT_SUCCESS: "DELETE_BOQ_PRODUCT_SUCCESS",
    DELETE_BOQ_PRODUCT_FAILURE: "DELETE_BOQ_PRODUCT_FAILURE",

    PREVIEW_BOQ: "PREVIEW_BOQ",
    PREVIEW_BOQ_SUCCESS: "PREVIEW_BOQ_SUCCESS",
    PREVIEW_BOQ_FAILURE: "PREVIEW_BOQ_FAILURE",

    GET_BOQ_BY_ID: "GET_BOQ_BY_ID",
    GET_BOQ_BY_ID_SUCCESS: "GET_BOQ_BY_ID_SUCCESS",
    GET_BOQ_BY_ID_FAILURE: "GET_BOQ_BY_ID_FAILURE",

    GENERATE_BOQ: "GENERATE_BOQ",
    GENERATE_BOQ_SUCCESS: "GENERATE_BOQ_SUCCESS",
    GENERATE_BOQ_FAILURE: "GENERATE_BOQ_FAILURE",

    SENT_BOQ: "SENT_BOQ",
    SENT_BOQ_SUCCESS: "SENT_BOQ_SUCCESS",
    SENT_BOQ_FAILURE: "SENT_BOQ_FAILURE",

    CLONE_BOQ: "CLONE_BOQ",
    CLONE_BOQ_SUCCESS: "CLONE_BOQ_SUCCESS",
    CLONE_BOQ_FAILURE: "CLONE_BOQ_FAILURE",

    DELETE_BOQ: "DELETE_BOQ",
    DELETE_BOQ_SUCCESS: "DELETE_BOQ_SUCCESS",
    DELETE_BOQ_FAILURE: "DELETE_BOQ_FAILURE",

    REVISE_BOQ: "REVISE_BOQ",
    REVISE_BOQ_SUCCESS: "REVISE_BOQ_SUCCESS",
    REVISE_BOQ_FAILURE: "REVISE_BOQ_FAILURE",

    UPDATE_CUSTOM_BOQ_PRODUCT: "UPDATE_CUSTOM_BOQ_PRODUCT",
    UPDATE_CUSTOM_BOQ_PRODUCT_SUCCESS: "UPDATE_CUSTOM_BOQ_PRODUCT_SUCCESS",
    UPDATE_CUSTOM_BOQ_PRODUCT_FAILURE: "UPDATE_CUSTOM_BOQ_PRODUCT_FAILURE",

    GET_PROJECT_OUTWARD: "GET_PROJECT_OUTWARD_LIST",
    GET_PROJECT_OUTWARD_SUCCESS: "GET_PROJECT_OUTWARD_LIST_SUCCESS",
    GET_PROJECT_OUTWARD_FAILURE: "GET_PROJECT_OUTWARD_LIST_FAILURE",

    GET_INTERNAL_TRANSFER_PRODUCT: "GET_INTERNAL_TRANSFER_PRODUCT_LIST",
    GET_INTERNAL_TRANSFER_PRODUCT_SUCCESS: "GET_INTERNAL_TRANSFER_PRODUCT_LIST_SUCCESS",
    GET_INTERNAL_TRANSFER_PRODUCT_FAILURE: "GET_INTERNAL_TRANSFER_PRODUCT_LIST_FAILURE",

    UPDATE_INTERNAL_TRANSFER: "UPDATE_INTERNAL_TRANSFER",
    UPDATE_INTERNAL_TRANSFER_SUCCESS: "UPDATE_INTERNAL_TRANSFER_SUCCESS",
    UPDATE_INTERNAL_TRANSFER_FAILURE: "UPDATE_INTERNAL_TRANSFER_FAILURE",

    DELIVER_BOQ: "DELIVER_BOQ",
    DELIVER_BOQ_SUCCESS: "DELIVER_BOQ_SUCCESS",
    DELIVER_BOQ_FAILURE: "DELIVER_BOQ_FAILURE",

    GET_BOQ_PRODUCT_DETAILS: "GET_BOQ_PRODUCT_DETAILS",
    GET_BOQ_PRODUCT_DETAILS_SUCCESS: "GET_BOQ_PRODUCT_DETAILS_SUCCESS",
    GET_BOQ_PRODUCT_DETAILS_FAILURE: "GET_BOQ_PRODUCT_DETAILS_FAILURE",

    GET_BOQ_MAIL_DETAILS: "GET_BOQ_MAIL_DETAILS_LIST",
    GET_BOQ_MAIL_DETAILS_SUCCESS: "GET_BOQ_MAIL_DETAILS_LIST_SUCCESS",
    GET_BOQ_MAIL_DETAILS_FAILURE: "GET_BOQ_MAIL_DETAILS_LIST_FAILURE",

    SENT_BOQ_MAIL: "SENT_BOQ_MAIL",
    SENT_BOQ_MAIL_SUCCESS: "SENT_BOQ_MAIL_SUCCESS",
    SENT_BOQ_MAIL_FAILURE: "SENT_BOQ_MAIL_FAILURE",
    
    PREVIEW_PACKING_SLIP: "PREVIEW_PACKING_SLIP",
    PREVIEW_PACKING_SLIP_SUCCESS: "PREVIEW_PACKING_SLIP_SUCCESS",
    PREVIEW_PACKING_SLIP_FAILURE: "PREVIEW_PACKING_SLIP_FAILURE",

    CLEAR_BOQ_ID_DATA: "CLEAR_BOQ_ID_DATA",
    CHANGE_PAGE: "CHANGE_PAGE",
    CHANGE_ADD_PAGE: "CHANGE_ADD_PAGE",
    CLEAR_BOQ_PREVIEW: "CLEAR_BOQ_PREVIEW",
    CLEAR_BOQ_PROJECT: "CLEAR_BOQ_PROJECT",
    CLEAR_CONFIRM_QUOT_DROPDOWN: "CLEAR_CONFIRM_QUOT_DROPDOWN",
}