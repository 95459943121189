import { billofquantityConstants, customerConstants, qutationConstants } from "../constants";

const initialState = {
    loading: false,
    success_msg: "",
    data: [],
    error: [],
    status: 0,
    token: "",
    customer: [],
    page: "create",
    sortType: ""
};

export default function customerReducer(state = initialState, { type, response }) {
    switch (type) {
        case customerConstants.GET_CUSTOMERS:
            return {
                ...state,
                loading: true,
            };
        case customerConstants.GET_CUSTOMERS_SUCCESS:
            return {
                ...state,
                loading: false,
                customers_list: response.data.content,
                allData: response.data,

            };
        case customerConstants.GET_CUSTOMERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
                success_msg: "",
            };

        case customerConstants.CREATE_CUSTOMER_INIT:
        case customerConstants.UPDATE_CUSTOMER_INIT:
            return {
                ...state,
                loading: true,
                success_msg: "",
            }
        case customerConstants.CREATE_CUSTOMER_SUCCESS:
        case customerConstants.UPDATE_CUSTOMER_SUCCESS:
            return {
                ...state,
                loading: false,
                success_msg: response,
                status: response.status
            }
        case customerConstants.CREATE_CUSTOMER_FAILURE:
        case customerConstants.UPDATE_CUSTOMER_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            }

        case customerConstants.GET_CUSTOMER_INIT:
            return {
                ...state,
                loading: true,
                page: ""
            }
        case customerConstants.GET_CUSTOMER_SUCCESS:
            return {
                ...state,
                loading: false,
                customer: response.data,
                status: response.status,
                page: "edit"
            }
        case customerConstants.GET_CUSTOMER_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            }

        case customerConstants.CHANGE_PAGE:
            return {
                ...state,
                loading: false,
                page: "create"
            }
        case customerConstants.CLEAR_CUSTOMER_VALUES:
        case billofquantityConstants.CLEAR_BOQ_ID_DATA:
            return {
                customer: {},
            }

        default:
            return state;
    }
}
