import React from 'react'
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { connect } from "react-redux";
import { createPoInwardProducts } from '../../../actions';
import { toast } from 'react-toastify';
import parse from 'html-react-parser';
import plus from "../../../assets/images/pl.svg";

class BulkUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            quotationId: "",
            items: [],
            inwardData: [],
            load: false,
            inwardValues: []
        }
        this.productHandler = this.productHandler.bind(this);
        this.selectHandler = this.selectHandler.bind(this);
        this.selectProHandler = this.selectProHandler.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
    }
    selectHandler(e) {
        const { openedItem } = this.state;
        let newItems = openedItem.map((i) => i = e.target.checked);
        this.setState({
            openedItem: newItems,
        })
    }
    selectProHandler(e, index) {
        const { openedItem } = this.state;
        let newItems = openedItem.map((item, i) => index === i ? e.target.checked : item);
        this.setState({
            openedItem: newItems
        })
    }
    changeHandler = (e, index) => {
        const { data } = this.state;
        let a = Number(data[index].balance);
        let b = Number(e.target.value);
        if (b <= a) {
            data[index].inwardQuantity = Number(e.target.value);
            // data[index].balance = data[index].quantity - Number(e.target.value);
            this.setState({
                data: data
            })
        } else {
            toast("Pls enter the number less than or equal to  " + a);
        }
    }
    productHandler(e) {
        this.props.inwardData?.map((item) => {
            if (item?.purchaseOrderId == e.target.value) {
                const values = item?.projectProductList.map((val) => val.inwardQuantity);
                this.setState({
                    data: item?.projectProductList,
                    purchaseOrderId: item?.purchaseOrderId,
                    inwardValues: values
                })
            }
        });
    }
    submitHandler() {
        const { data } = this.state;
        let pro = [];
        data?.map((item, i) => {
            let items = {};
            if (Number(item.inwardQuantity) !== 0) {
                items["projectProductId"] = item.purchaseProjectProductId;
                items["stockQty"] = Number(item.inwardQuantity);
                pro.push(items);
            }
        });
        this.setState({
            load: true
        })
        this.props.dispatch(createPoInwardProducts(pro));
    }
    componentDidUpdate(prevProps) {
        if (prevProps.inwardProductsSuccess !== this.props.inwardProductsSuccess) {
            this.setState({
                load: false
            })
            if (this.props.inwardProductsSuccess.status === 200) {
                this.setState({
                    data: []
                })
                toast(this.props.inwardProductsSuccess.data);
                this.props.onHide();
                this.props.listData();
            } else {
                toast(this.props.inwardProductsSuccess.data);
            }
        }
    }

    render() {
        const { show, onHide, inwardData, handleShow } = this.props;
        const { data, load, inwardValues } = this.state;
        return (
            <>
                <button className="add-stock" onClick={handleShow}>
                    <img src={plus} alt="" /> Bulk Upload
                </button>

                <Modal
                    show={show}
                    size="xl"
                    onHide={() => {
                        onHide();
                    }}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <div className="modal-staff">
                        <div className="head">
                            <div>
                                <i class="fas fa-arrow-left" onClick={() => {
                                    onHide();
                                }}></i>
                                <h4>BULK INWARD PRODUCT</h4>
                            </div>
                        </div>
                        <div className='space-parts'>
                            <select name="referenceId" className='form-control form-select mt-4' onChange={(e) => this.productHandler(e)} >
                                <option value="">Select One</option>
                                {inwardData ?? inwardData?.length > 0 ? inwardData?.map((unit, i) => (
                                    <option value={unit?.purchaseOrderId} key={i}>{unit?.purchaseOrderReferenceNumber}</option>)) : ""}
                            </select>
                            <div className="triangle-bottom" style={{ margin: "60px 20px 0 0" }}></div>
                        </div>
                        <div className="table-class space-part">
                            <table class="table table-borderless">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            Project
                                        </th>
                                        <th scope="col">
                                            Article No.
                                        </th>
                                        <th scope="col">
                                            Product
                                        </th>
                                        <th scope="col" className='txt-center'>
                                            Qty
                                        </th>
                                        <th scope="col" className='txt-center' >
                                            Inward
                                        </th>
                                        <th scope="col" className='txt-center'>
                                            Balance
                                        </th>
                                        <th scope="col" className='txt-center'>
                                            Available Stock
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='hide_td'>
                                    {data?.length !== 0 ? data?.map((item, i) => (
                                        <tr key={i} >
                                            <td className="new_bulk_size">
                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> {item.projectName || "-"}</Tooltip>}>
                                                    <span className="d-inline-block">
                                                        {item.projectName || "-"}
                                                    </span>
                                                </OverlayTrigger>
                                            </td>
                                            <td>{item?.articleId || "-"}</td>
                                            <td className="new_bulk_size">
                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> {parse(item?.productName) || "-"}</Tooltip>}>
                                                    <span className="d-inline-block">
                                                        {parse(item?.productName) || "-"}
                                                    </span>
                                                </OverlayTrigger>
                                            </td>
                                            <td className='txt-center'>{item?.quantity}</td>
                                            <td className='txt-center'><input type="text"
                                                className='convert_po_input'
                                                value={item?.inwardQuantity}
                                                onChange={(e) => this.changeHandler(e, i)} /></td>
                                            <td className='txt-center'> {`${inwardValues[i] - item.inwardQuantity === 0 ? 0 : inwardValues[i] - item.inwardQuantity}`} </td>
                                            <td className='txt-center'>{`${(item.inwardQuantity) + item?.availableStock}`}</td>
                                        </tr >
                                    )) : <h1 className="empty-data">No Records Found</h1>
                                    }
                                </tbody>
                            </table >
                        </div >
                        <center style={{ padding: "15px" }}>
                            {!load ?
                                <button className="customer-btn col-md-6" onClick={() => this.submitHandler()}>Add To Stock</button>
                                : <button className="customer-btn col-md-6">Loading...</button>}
                        </center >
                    </div >
                </Modal >
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        convertBoqList: state.boq.convertBoqList,
        inwardData: state.po.inwardData,
        load: state.po.load,
        confirmQuotDropdown: state.boq.confirmQuotDropdown,
        confirmQuotProduct: state.boq.confirmQuotProduct,
        addInwardData: state.po.addInwardData,
        inwardProductsSuccess: state.po.inwardProductsSuccess
    }
}

export default connect(mapStateToProps)(BulkUpload);
