export const qutationConstants = {
    GET_QUTATION: "GET_QUTATION_LIST",
    GET_QUTATION_SUCCESS: "GET_QUTATION_LIST_SUCCESS",
    GET_QUTATION_FAILURE: "GET_QUTATION_LIST_FAILURE",

    GET_IGNORE_FILEDS: "GET_IGNORE_FILEDS_LIST",
    GET_IGNORE_FILEDS_SUCCESS: "GET_IGNORE_FILEDS_LIST_SUCCESS",
    GET_IGNORE_FILEDS_FAILURE: "GET_IGNORE_FILEDS_LIST_FAILURE",

    GET_QUTATION_BY_ID: "GET_QUTATION_BY_ID",
    GET_QUTATION_BY_ID_SUCCESS: "GET_QUTATION_BY_ID_SUCCESS",
    GET_QUTATION_BY_ID_FAILURE: "GET_QUTATION_BY_ID_FAILURE",

    CLONE_QUTATION: "CLONE_QUTATION_REQUEST",
    CLONE_QUTATION_SUCCESS: "CLONE_QUTATION_REQUEST_SUCCESS",
    CLONE_QUTATION_FAILURE: "CLONE_QUTATION_REQUEST_FAILURE",

    DELETE_QUTATION: "DELETE_QUTATION_REQUEST",
    DELETE_QUTATION_SUCCESS: "DELETE_QUTATION_REQUEST_SUCCESS",
    DELETE_QUTATION_FAILURE: "DELETE_QUTATION_REQUEST_FAILURE",

    GET_QUTATION_TEMPLATES: "GET_QUTATION_TEMPLATES",
    GET_QUTATION_TEMPLATES_SUCCESS: "GET_QUTATION_TEMPLATES_SUCCESS",
    GET_QUTATION_TEMPLATES_FAILURE: "GET_QUTATION_TEMPLATES_FAILURE",

    CREATE_QUOTATION_INIT: "CREATE_QUOTATION_INIT",
    CREATE_QUOTATION_SUCCESS: "CREATE_QUOTATION_SUCCESS",
    CREATE_QUOTATION_FAILURE: "CREATE_QUOTATION_FAILURE",

    UPDATE_QUOTATION_INIT: "UPDATE_QUOTATION_INIT",
    UPDATE_QUOTATION_SUCCESS: "UPDATE_QUOTATION_SUCCESS",
    UPDATE_QUOTATION_FAILURE: "UPDATE_QUOTATION_FAILURE",

    GET_LOCATIONLIST_INIT: "GET_LOCATIONLIST_INIT",
    GET_LOCATIONLIST_SUCCESS: "GET_LOCATIONLIST_SUCCESS",
    GET_LOCATIONLIST_FAILURE: "GET_LOCATIONLIST_FAILURE",

    ADD_LOCATION_QUOTATION_INIT: "ADD_LOCATION_QUOTATION_INIT",
    ADD_LOCATION_QUOTATION_SUCCESS: "ADD_LOCATION_QUOTATION_SUCCESS",
    ADD_LOCATION_QUOTATION_FAILURE: "ADD_LOCATION_QUOTATION_FAILURE",

    CLONE_LOCATION: "CLONE_LOCATION",
    CLONE_LOCATION_SUCCESS: "CLONE_LOCATION_SUCCESS",
    CLONE_LOCATION_FAILURE: "CLONE_LOCATION_FAILURE",

    CLONE_GROUP: "CLONE_GROUP",
    CLONE_GROUP_SUCCESS: "CLONE_GROUP_SUCCESS",
    CLONE_GROUP_FAILURE: "CLONE_GROUP_FAILURE",

    EDIT_LOCATION_QUOTATION_INIT: "EDIT_LOCATION_QUOTATION_INIT",
    EDIT_LOCATION_QUOTATION_SUCCESS: "EDIT_LOCATION_QUOTATION_SUCCESS",
    EDIT_LOCATION_QUOTATION_FAILURE: "EDIT_LOCATION_QUOTATION_FAILURE",

    DELETE_LOCATION_QUOTATION_INIT: "DELETE_LOCATION_QUOTATION_INIT",
    DELETE_LOCATION_QUOTATION_SUCCESS: "DELETE_LOCATION_QUOTATION_SUCCESS",
    DELETE_LOCATION_QUOTATION_FAILURE: "DELETE_LOCATION_QUOTATION_FAILURE",

    GET_GROUPS_QUOTATION_INIT: "GET_GROUPS_QUOTATION_INIT",
    GET_GROUPS_QUOTATION_SUCCESS: "GET_GROUPS_QUOTATION_SUCCESS",
    GET_GROUPS_QUOTATION_FAILURE: "GET_GROUPS_QUOTATION_FAILURE",

    ADD_GROUP_QUOTATION_INIT: "ADD_GROUP_QUOTATION_INIT",
    ADD_GROUP_QUOTATION_SUCCESS: "ADD_GROUP_QUOTATION_SUCCESS",
    ADD_GROUP_QUOTATION_FAILURE: "ADD_GROUP_QUOTATION_FAILURE",

    UPDATE_GROUP_QUOTATION_INIT: "UPDATE_GROUP_QUOTATION_INIT",
    UPDATE_GROUP_QUOTATION_SUCCESS: "UPDATE_GROUP_QUOTATION_SUCCESS",
    UPDATE_GROUP_QUOTATION_FAILURE: "UPDATE_GROUP_QUOTATION_FAILURE",

    DELETE_GROUP_QUOTATION_INIT: "DELETE_GROUP_QUOTATION_INIT",
    DELETE_GROUP_QUOTATION_SUCCESS: "DELETE_GROUP_QUOTATION_SUCCESS",
    DELETE_GROUP_QUOTATION_FAILURE: "DELETE_GROUP_QUOTATION_FAILURE",

    CREATE_FOOTER_QUOTATION_INIT: "CREATE_FOOTER_QUOTATION_INIT",
    CREATE_FOOTER_QUOTATION_SUCCESS: "CREATE_FOOTER_QUOTATION_SUCCESS",
    CREATE_FOOTER_QUOTATION_FAILURE: "CREATE_FOOTER_QUOTATION_FAILURE",

    ADD_TERMS_QUOTATION_INIT: "ADD_TERMS_QUOTATION_INIT",
    ADD_TERMS_QUOTATION_SUCCESS: "ADD_TERMS_QUOTATION_SUCCESS",
    ADD_TERMS_QUOTATION_FAILURE: "ADD_TERMS_QUOTATION_FAILURE",

    GET_EXECUTIVES_INIT: "GET_EXECUTIVES_INIT",
    GET_EXECUTIVES_SUCCESS: "AGET_EXECUTIVES_SUCCESS",
    AGET_EXECUTIVES_FAILURE: "GET_EXECUTIVES_FAILURE",

    ADD_SALESEXE_QUOTATION_INIT: "ADD_SALESEXE_QUOTATION_INIT",
    ADD_SALESEXE_QUOTATION_SUCCESS: "ADD_SALESEXE_QUOTATION_SUCCESS",
    ADD_SALESEXE_QUOTATION_FAILURE: "ADD_SALESEXE_QUOTATION_FAILURE",

    PREVIEW_QUOTATION_INIT: "PREVIEW_QUOTATION_INIT",
    PREVIEW_QUOTATION_SUCCESS: "PREVIEW_QUOTATION_SUCCESS",
    PREVIEW_QUOTATION_FAILURE: "PREVIEW_QUOTATION_FAILURE",

    GENERATE_QUOTATION_INIT: "GENERATE_QUOTATION_INIT",
    GENERATE_QUOTATION_SUCCESS: "GENERATE_QUOTATION_SUCCESS",
    GENERATE_QUOTATION_FAILURE: "GENERATE_QUOTATION_FAILURE",

    GET_QUOTATION_STATUS: "GET_QUOTATION_STATUS",
    GET_QUOTATION_STATUS_SUCCESS: "GET_QUOTATION_STATUS_SUCCESS",
    GET_QUOTATION_STATUS_FAILURE: "GET_QUOTATION_STATUS_FAILURE",

    UPDATE_QUOTATION_STATUS: "UPDATE_QUOTATION_STATUS",
    UPDATE_QUOTATION_STATUS_SUCCESS: "UPDATE_QUOTATION_STATUS_SUCCESS",
    UPDATE_QUOTATION_STATUS_FAILURE: "UPDATE_QUOTATION_STATUS_FAILURE",

    GET_FOOTERLIST_INIT: "GET_FOOTERLIST_INIT",
    GET_FOOTERLIST_SUCCESS: "GET_FOOTERLIST_SUCCESS",
    GET_FOOTERLIST_FAILURE: "GET_FOOTERLIST_FAILURE",

    ADD_IGNORE_FIELDS_INIT: "ADD_IGNORE_FIELDS_INIT",
    ADD_IGNORE_FIELDS_SUCCESS: "ADD_IGNORE_FIELDS_SUCCESS",
    ADD_IGNORE_FIELDS_FAILURE: "ADD_IGNORE_FIELDS_FAILURE",

    GET_GROUP_PRODUCTS: "GET_GROUP_PRODUCTS",
    GET_GROUP_PRODUCTS_SUCCESS: "GET_GROUP_PRODUCTS_SUCCESS",
    GET_GROUP_PRODUCTS_FAILURE: "GET_GROUP_PRODUCTS_FAILURE",

    ADD_GROUP_PRODUCTS: "ADD_GROUP_PRODUCTS",
    ADD_GROUP_PRODUCTS_SUCCESS: "ADD_GROUP_PRODUCTS_SUCCESS",
    ADD_GROUP_PRODUCTS_FAILURE: "ADD_GROUP_PRODUCTS_FAILURE",

    DELETE_GROUP_PRODUCTS: "DELETE_GROUP_PRODUCTS",
    DELETE_GROUP_PRODUCTS_SUCCESS: "DELETE_GROUP_PRODUCTS_SUCCESS",
    DELETE_GROUP_PRODUCTS_FAILURE: "DELETE_GROUP_PRODUCTS_FAILURE",

    DOWNLOAD_QUTATION: "DOWNLOAD_QUTATION_REQUEST",
    DOWNLOAD_QUTATION_SUCCESS: "DOWNLOAD_QUTATION_REQUEST_SUCCESS",
    DOWNLOAD_QUTATION_FAILURE: "DOWNLOAD_QUTATION_REQUEST_FAILURE",


    CHANGE_GROUP_QUOTATION_INIT: "CHANGE_GROUP_QUOTATION_INIT",
    UPDATE_GROUP_QUOTATION_INIT: "UPDATE_GROUP_QUOTATION_INIT",


    CLEAR_CUSTOMER_DETAILS: "CLEAR_CUSTOMER_DETAIL",
    CLEAR_QUOTATION_BODY: "CLEAR_QUOTATION_BODY"
}