import React from 'react'
import { Field, reduxForm, reset, SubmissionError } from 'redux-form';
import check from "../../../../assets/images/check2.svg";
import { Row, Col, Card, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import AddPoProduct from "./AddProducts";
import validate from "./validate";
import { connect } from "react-redux";
import Navbar from "../../../../layouts/Navbar";
import Sidebar from "../../../../layouts/Sidebar";
import Orange from "../../../../assets/images/green2.svg";
import plus from "../../../../assets/images/plus_green.svg";
import plus2 from "../../../../assets/images/plus-circle2.svg";
import edit from "../../../../assets/images/edit_btn.svg";
import del from "../../../../assets/images/del_icon.svg";
import EditPoProject from "./editProject";
import VenderPoView from '../../vendorpo/VenderPoView';
import Select from 'react-select';
import {
    createPoProject,
    getPoById,
    deletePoProject,
    getExecutives,
    getPoProjectDetail,
    getVerticesDropdown,
    updatePoIgnoreFields,
    setProduct,
    changePoPage,
    sendPo,
    cancelPo,
    clearPoVerifyProducts,
    getProductById,
    changePoAddPage,
    generatePo,
    deletePo,
    previewPo,
    clonePo,
    revisePo,
    getPoVerifyProduts,
    clearSingleProduct,
    getPoInwardList,
    getTitleStr,
    getPoMailDetails,
    sendPoMail,
    getAllUnits,
    setRf,
    getRf
} from '../../../../actions';
import { toast } from 'react-toastify';
import ViewProducts from './ViewProducts';
import PoCustomProducts from "./AddCustomProduct";
import DeletePopUp from '../../vendorpo/deletePopup';
import { getBoqProjects } from './services';
import VerifyProducts from '../../vendorpo/verifyProducts';
import InwardProducts from '../../vendorpo/Inward';
import CloneStatus from "../../../../Components/cloningStatus";
import ClonePopup from "../../../../Components/clonePopup";
import EmailPopup from '../../../../Components/emailPopup';

const role = localStorage.getItem('userRole');

const renderSelectField = ({ input, disabled, meta: { touched, error }, children }) => (
    <div>
        <div>
            <select {...input} className='form-control form-select mt-4' disabled={disabled} >
                {children}
            </select>
            {touched && error && <p className="require">{error}</p>}
        </div>
    </div>
);

class ProductDetailsForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: "",
            projectData: this.props.projectData?.projectProductDetails,
            openedItem: [],
            productPopup: false,
            customPopup: false,
            projects: [],
            deleteStr: '',
            productItem: {},
            projectName: "",
            projectId: "",
            validate: false,
            projectPopup: false,
            status: "DRAFT",
            editShow: false,
            str: '',
            customStr: '',
            data: {},
            verifyPopup: false,
            deletePopup: false,
            inwardPopup: false,
            cloned: false,
            clonePopup: false,
            cloneStr: "",
            cloneShow: false,
            emailPopup: false,
            cc: [],
            emailPopup: false,
            ignoredFields: this.props.projectData?.ignoreFieldList && this.props.projectData.ignoreFieldList?.length > 0 ? this.props.projectData.ignoreFieldList : ['FOOTER', 'TERMS_CONDITION']
        }
        this.setContent = this.setContent.bind(this);
        this.createProject = this.createProject.bind(this);
        this.productOpen = this.productOpen.bind(this);
        this.productClose = this.productClose.bind(this);
        this.projectOpen = this.projectOpen.bind(this);
        this.projectClose = this.projectClose.bind(this);
        this.deleteItemOpen = this.deleteItemOpen.bind(this);
        this.deleteItemClose = this.deleteItemClose.bind(this);
        this.deleteProjectHandler = this.deleteProjectHandler.bind(this);
        this.hideColumnHandler = this.hideColumnHandler.bind(this);
        this.prevPage = this.prevPage.bind(this);
        this.productHandler = this.productHandler.bind(this);
        this.customProductOpen = this.customProductOpen.bind(this);
        this.customProductClose = this.customProductClose.bind(this);
        this.editVendorShow = this.editVendorShow.bind(this);
        this.editVendorClose = this.editVendorClose.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.getBoqprojectsDropdown = this.getBoqprojectsDropdown.bind(this);
        this.goToPoPage = this.goToPoPage.bind(this);
        this.generatePoHandler = this.generatePoHandler.bind(this);
        this.sendPohandler = this.sendPohandler.bind(this);
        this.clonePoHandler = this.clonePoHandler.bind(this);
        this.revisePoHandler = this.revisePoHandler.bind(this);
        this.cancelPoHandler = this.cancelPoHandler.bind(this);
        this.deletePoHandler = this.deletePoHandler.bind(this);
        this.verifyShowPopup = this.verifyShowPopup.bind(this);
        this.verifyClosePopup = this.verifyClosePopup.bind(this);
        this.inwardOpen = this.inwardOpen.bind(this);
        this.inwardClose = this.inwardClose.bind(this);
        this.sendMailOpen = this.sendMailOpen.bind(this);
        this.sendMailClose = this.sendMailClose.bind(this);
        this.sendPoMailSubmit = this.sendPoMailSubmit.bind(this);
        this.getCCs = this.getCCs.bind(this);
    }
    async getBoqprojectsDropdown() {
        var footerData = await getBoqProjects();
        if (footerData?.data?.status === 200) {
            let items = [];
            footerData?.data?.data.map((item) => {
                let data = {};
                data["value"] = item?.boqProjectId;
                data["label"] = `${item.boqProjectName} ${item.firstName}`;
                items.push(data);
            })
            this.setState({
                projects: items
            })
        }
    }
    goToPoPage() {
        const { status } = this.state;

        if (status === "DRAFT") {
            this.setState({
                editShow: false
            })
        } else {
            this.revisePoHandler();
        }
    }
    generatePoHandler() {
        this.setState({
            editShow: false
        })
        this.props.dispatch(generatePo(this.props.match.params.id));
    }
    changeHandler(e) {
        if (e.value === 0) {
            this.setState({
                projectId: e.value
            })
        } else {
            this.setState({
                projectId: e.value
            })
        }
        this.setState({
            projectName: e.label,

        })
    }
    prevPage() {
        window.location.hash = `/${role}/vendor_po/vendor-detail/${this.props.match.params.id}`;
    }
    editVendorShow() {
        this.setState({
            editShow: true,
        })
    }
    editVendorClose() {
        const { status } = this.state;
        if (status === "DRAFT") {
            this.setState({
                editShow: false
            })
        } else {
            window.location.hash = `/${role}/vendor_po`;
        }
    }
    sendPohandler() {
        this.props.dispatch(sendPo(this.props.match.params.id));
    }
    clonePoHandler() {
        this.setState({
            cloned: true,
            clonePopup: true,
            cloneStr: "Cloning Purchase Order...Please wait..."
        })
        this.props.dispatch(clonePo(this.props.match.params.id));
    }
    revisePoHandler() {
        this.setState({
            cloned: false,
            clonePopup: true,
            cloneStr: "Revising Purchase Order...Please wait..."
        })
        this.props.dispatch(revisePo(this.props.match.params.id));
    }
    cancelPoHandler() {
        this.props.dispatch(cancelPo(this.props.match.params.id));
    }
    verifyShowPopup() {
        this.props.dispatch(getPoVerifyProduts(this.props.match.params.id));
        this.setState({
            verifyPopup: true
        })
    }
    verifyClosePopup() {
        this.props.dispatch(clearPoVerifyProducts());
        this.setState({
            verifyPopup: false
        })
    }
    inwardOpen() {
        this.props.dispatch(getPoInwardList());
        this.setState({
            inwardPopup: true,
        })
    }
    inwardClose() {
        this.setState({
            inwardPopup: false
        })
    }
    setContent(val, index) {
        !val ? this.state.openedItem[index] = true : this.state.openedItem[index] = false;
        this.setState({
            deleteStr: "PROJECT"
        })
    }
    productHandler(values) {
        this.props.dispatch(changePoPage());
        this.props.dispatch(clearSingleProduct());
        setProduct(values);
        if (values.productName?.startsWith("<")) {
            this.props.dispatch(getProductById(values.productId));
            let data = {
                'articleId': values?.articleId,
                'productName': values.productName,
                'Quantity': values.quantity,
                'unitId': values?.unit?.unitId,
                'productId': values?.productId,
                'unitCost': values?.unitPrice,
                'totalStock': values?.availableStock,
                'totalCost': values.totalPrice,
                "purchaseProjectProductId": values?.purchaseProjectProductId
            }
            this.setState(prevState => ({
                customPopup: !prevState.customPopup,
                productPopup: false,
                str: "edit",
                productItem: data
            }));
        } else {
            this.props.dispatch(getAllUnits());
            this.setState(prevState => ({
                productPopup: !prevState.productPopup,
                customPopup: false,
                str: "edit",
                productItem: values
            }));
        }
    }
    deleteItemOpen(item) {
        this.setState({
            deletePopup: true,
            projectId: item?.projectId ?? 0
        })
    }
    deleteItemClose() {
        this.setState({
            deletePopup: false
        })
    }
    deletePoHandler() {
        this.props.dispatch(deletePo(this.props.match.params.id));
    }
    createProject(values) {
        if (this.props.match.params.id === "add") {
            toast("You are not allow to create project...Fill the previous section first");
        } else {
            const { projectName } = this.state;
            if (projectName !== "") {
                if (projectName !== "Various Projects  ") {
                    if (!values.vertexId) {
                        throw new SubmissionError({
                            vertexId: 'Please fill the field *',
                        })
                    } else if (!values?.salesExecutiveId) {
                        throw new SubmissionError({
                            salesExecutiveId: 'Please fill the field *',
                        })
                    }
                    let data = {
                        "projectName": projectName,
                        "vertexId": Number(values.vertexId),
                        "purchaseOrderId": Number(this.props.match.params.id),
                        "salesExecutiveId": Number(values.salesExecutiveId),
                    }
                    this.props.dispatch(createPoProject(data));
                } else {
                    let data = {
                        "projectName": projectName?.trim(),
                        "purchaseOrderId": Number(this.props.match.params.id),
                        "isInStock": 1,
                        "vertexId": 0,
                        "salesExecutiveId": 0,
                    }
                    this.props.dispatch(createPoProject(data));
                }
            } else {
                toast("Please Select Project");
            }
        }
    }
    projectOpen(item) {
        this.setState({
            projectPopup: true,
            data: item,
            projectId: item.projectId
        })
    }
    projectClose() {
        this.setState({
            projectPopup: false
        })
    }
    productOpen(item) {
        setProduct({})
        this.props.dispatch(changePoAddPage());
        this.setState(prevState => ({
            str: "add",
            productPopup: !prevState.productPopup,
            projectId: item.projectId,
            productItem: {},
        }));
    }
    productClose() {
        this.setState(prevState => ({
            productPopup: !prevState.productPopup,
        }));
    }
    customProductOpen(item) {
        this.props.dispatch(changePoAddPage());
        this.setState(prevState => ({
            str: "add",
            customPopup: !prevState.customPopup,
            projectId: item.projectId,
            productItem: {},
        }));
    }
    customProductClose() {
        this.setState(prevState => ({
            customPopup: !prevState.customPopup,
        }));
    }
    hideColumnHandler(event) {
        const target = event?.target;
        var value = target?.value;
        let fields = this.state.ignoredFields;
        if (fields.includes(value)) {
            let newColumns = fields.filter(item => item !== value);
            this.setState({
                ignoredFields: newColumns
            });
        } else {
            fields.push(value);
            this.setState({
                ignoredFields: fields
            });
        }
    }
    deleteProjectHandler() {
        this.props.dispatch(deletePoProject(this.state.projectId))
    }
    sendMailOpen() {
        this.setState({
            emailPopup: true,
            editShow: false,
        })
        this.props.dispatch(getPoMailDetails(Number(this.props.match.params.id)));
    }
    sendMailClose() {
        this.setState({
            emailPopup: false
        })
    }
    getCCs(val) {
        this.setState({
            cc: val
        })
    }
    sendPoMailSubmit(values, dispatch) {
        const { cc } = this.state;
        const newItems = cc?.map((item) => item.value);
        let data = {
            "from": values.from,
            "to": values.to,
            "cc": newItems,
            "id": Number(this.props.match.params.id),
            "subject": values.subject
        }
        this.props.dispatch(sendPoMail(data));
        dispatch(reset("SecondaryEmailForm"));
    }
    nextPage() {
        let data = {
            "purchaseOrderId": this.props.match.params.id,
            "ignoredFields": this.state.ignoredFields
        }
        this.props.dispatch(updatePoIgnoreFields(data));
    }
    componentDidUpdate(prevProps) {
        if (prevProps.projectData !== this.props.projectData) {
            this.setState({
                projectData: this.props.projectData.projectProductDetails,
                ignoredFields: this.props.projectData.ignoreFieldList !== null &&
                    this.props.projectData.ignoreFieldList?.length > 0 ? this.props.projectData.ignoreFieldList : ['FOOTER', 'TERMS_CONDITION']
            });
            let data = [];
            this.props.projectData?.projectProductDetails?.map((item, i) => {
                // openedItem[i] && 
                if (item.productList?.length > 0) {
                    data[i] = true;
                } else {
                    data[i] = false;
                }
            });
            this.setState({
                openedItem: data
            })
        } if (prevProps.projectSuccess !== this.props.projectSuccess) {
            if (this.props.projectSuccess.status === 200) {
                this.setState({
                    projectPopup: false,
                    projectName: "",
                    validate: false
                })
                toast(this.props.projectSuccess.message);
                this.props.reset();
                this.props.dispatch(getPoProjectDetail(this.props.match.params.id));
            } else {
                toast(this.props.projectSuccess.data);
            }
        } if (prevProps.generateData !== this.props.generateData) {
            this.props.dispatch(previewPo(this.props.match.params.id));
            if (this.props.generateData.status === 200) {
                this.setState({
                    status: "GENERATED",
                    editShow: true
                })
                toast(this.props.generateData.data);
            } else {
                toast(this.props.generateData.data);
            }
        } if (prevProps.deletePoProject !== this.props.deletePoProject) {
            if (this.props.deletePoProject.status === 200) {
                this.setState({
                    deletePopup: false
                })
                toast(this.props.deletePoProject.message);
                this.props.dispatch(getPoProjectDetail(this.props.match.params.id));
            } else {
                toast(this.props.deletePoProject.data);
            }
        } if (prevProps.deletePoProduct !== this.props.deletePoProduct) {
            if (this.props.deletePoProduct.status === 200) {
                toast(this.props.deletePoProduct.message);
                this.props.dispatch(getPoProjectDetail(this.props.match.params.id));
            } else {
                toast(this.props.deletePoProduct.data);
            }
        } if (prevProps.ignoreData !== this.props.ignoreData) {
            if (this.props.ignoreData.status === 200) {
                this.props.dispatch(getPoProjectDetail(this.props.match.params.id));
                if (!this.state.ignoredFields.includes("FOOTER")) {
                    window.location.hash = "/" + role + "/vendor_po/footer/" + this.props.match.params.id;
                } else if (!this.state.ignoredFields.includes("TERMS_CONDITION")) {
                    window.location.hash = "/" + role + "/vendor_po/termsAndConditions/" + this.props.match.params.id;
                } else {
                    this.setState({
                        editShow: true,
                        deleteStr: "PO"
                    })
                    this.props.dispatch(previewPo(this.props.match.params.id));
                }
            } else {
                toast(this.props.ignoreData.data);
            }
        } if (prevProps.deletePo !== this.props.deletePo) {
            if (this.props.deletePo.status === 200) {
                toast(this.props.deletePo.data);
                setTimeout(() => window.location.hash = `/${role}/purchase_order/vendor-po`, 500);
                this.setState({
                    deletePopup: false,
                    editShow: false
                })
            } else {
                toast(this.props.deletePo.data);
            }
        } if (prevProps.sendData !== this.props.sendData) {
            if (this.props.sendData.status === 200) {
                toast(this.props.sendData.data);
                this.setState({
                    // editShow: false,
                    status: "SENT"
                })
            } else {
                toast(this.props.sendData.data);
            }
        } if (prevProps.cloneData !== this.props.cloneData) {
            if (this.props.cloneData.status === 200) {
                this.setState({
                    editShow: false,
                    clonePopup: false,
                    cloneShow: true,
                    cloneStr: "Purchase Order Cloned Successfully"
                })
                setTimeout(() => this.setState({ cloneShow: false, cloneStr: "", cloned: null }), 1500);
                setTimeout(() => window.location.hash = `/${role}/vendor_po`, 1800);
            } else {
                this.setState({
                    clonePopup: false,
                })
                toast(this.props.cloneData.data);
            }
        } if (prevProps.reviseData !== this.props.reviseData) {
            if (this.props.reviseData.status === 200) {
                toast(this.props.reviseData.message);
                this.setState({
                    editShow: false,
                    clonePopup: false,
                    cloneShow: true,
                    cloneStr: "Purchase Order Revised Successfully"
                })
                setTimeout(() => this.setState({ cloneShow: false, cloneStr: "", cloned: null }), 1500);
                setTimeout(() => window.location.hash = `/${role}/vendor_po`, 1800);
            } else {
                this.setState({
                    clonePopup: false,
                })
                toast(this.props.reviseData.data);
            }
        } if (prevProps.cancelData !== this.props.cancelData) {
            if (this.props.cancelData.status === 200) {
                toast(this.props.cancelData.data);
                this.listAllData();
                this.setState({
                    editShow: false
                })
            } else {
                toast(this.props.cancelData.data);
            }
        } if (prevProps.verifyData !== this.props.verifyData) {
            if (this.props.verifyData.status === 200) {
                this.props.dispatch(clearPoVerifyProducts());
                toast(this.props.verifyData.data);
                this.setState({
                    verifyPopup: false,
                    status: "VERIFIED"
                })
            } else {
                toast(this.props.verifyData.data);
            }
        } if (prevProps.poMailSuccess !== this.props.poMailSuccess) {
            const { poMailSuccess } = this.props;
            if (poMailSuccess.status === 200) {
                this.setState({
                    emailPopup: false,
                    cc: [],
                    status: "SENT"
                });
                toast(poMailSuccess.data);
                setTimeout(() => {
                    this.setState({
                        editShow: true,
                    })
                })
            } else {
                toast(poMailSuccess.data);
            }
        }
    }
    componentDidMount() {
        if (this.props.match.params.id !== "add") {
            this.props.dispatch(getPoProjectDetail(this.props.match.params.id));
            this.props.dispatch(getPoById(this.props.match.params.id));
        }
        this.props.dispatch(getVerticesDropdown());
        this.getBoqprojectsDropdown();
        // this.props.dispatch(getBoqProjectDropdown());
        this.props.dispatch(getExecutives());
    }

    render() {
        const { handleSubmit, load, poData } = this.props;
        const { productPopup,
            projectPopup,
            projectData,
            deletePopup,
            status,
            ignoredFields,
            data,
            str,
            productItem,
            customPopup,
            editShow,
            deleteStr,
            projects,
            verifyPopup,
            inwardPopup
        } = this.state;

        return (
            <div >
                <Navbar />
                <div className="dashboard-page">
                    <div className="dashboard-sidebar">
                        <Sidebar />
                    </div>
                    <div className="dashboard-main">
                        <Card className="staff-page">
                            <img src={Orange} alt="..." className="head-img" />
                            <h5 className='po_link' onClick={() => window.location.hash = "/" + role + "/vendor_po"}>Purchase Order</h5>
                            <i className="fas fa-arrow-right" style={{ marginTop: "10px" }}></i>
                            <h6>{getTitleStr()}</h6>
                            <hr className="line-new"></hr>
                            <div className="order-page-title">
                                <Link to={"/" + role + "/vendor_po/vendor-detail/" + this.props.match.params.id} >
                                    Vendor Details
                                </Link>
                                <Link to={"/" + role + "/vendor_po/product-detail/" + this.props.match.params.id} className="active">
                                    Product Details
                                </Link>
                                {ignoredFields?.includes("FOOTER") ? <></> : <Link to={"/" + role + "/vendor_po/footer/" + this.props.match.params.id} >
                                    Footer
                                </Link>}
                                {ignoredFields?.includes("TERMS_CONDITION") ? <></> : <Link to={"/" + role + "/vendor_po/termsAndConditions/" + this.props.match.params.id} >
                                    Terms & Conditions
                                </Link>}
                            </div>
                            <div className="purchase-order">
                                <section className="po-header">
                                    <Row className='ignore_field_body' >
                                        <Col md={3}>
                                            <h4>Project Groups</h4>
                                        </Col>
                                        <Col md={6}>
                                            <h4 >Hide Columns:</h4>
                                        </Col>
                                        <Col md={3}>
                                            <div className="qutation_check">
                                                <div className="customer-check">
                                                    <Form.Check checked={ignoredFields &&
                                                        ignoredFields?.includes("FOOTER")
                                                        ? true : false} inline label="Footer" onChange={this.hideColumnHandler} name="group1" type="checkbox" defaultValue="FOOTER" className="form-check-label" />
                                                </div>
                                                <div className="customer-check">
                                                    <Form.Check checked={ignoredFields &&
                                                        ignoredFields?.includes("TERMS_CONDITION")
                                                        ? true : false} inline label="Terms & Conditions" onChange={this.hideColumnHandler} name="group1" type="checkbox" defaultValue="TERMS_CONDITION" className="form-check-label" />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </section>
                                <div className="qutation-page" style={{ marginRight: "30px" }}>
                                    {projectData && projectData.length > 0 ? projectData?.map((loc, index) => {
                                        return (
                                            <div style={{ marginBottom: "20px" }}>
                                                <Row key={index} >
                                                    <Col md={3}>
                                                        <div>
                                                            <label class="form-control-label" for="name">Project Name <span>*</span></label>
                                                            <Form.Group controlId="enterarea">
                                                                <Form.Control
                                                                    type="text"
                                                                    name="projectName"
                                                                    value={loc.projectName}
                                                                    readOnly
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <label class="form-control-label" for="name">Vertex Name <span>*</span></label>
                                                        <Form.Group controlId="enterdwg">
                                                            <Form.Control
                                                                type="text"
                                                                name="vertex"
                                                                value={loc.vertex}
                                                                readOnly
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3}>
                                                        <label class="form-control-label" for="name">Sales Executive Name <span>*</span></label>
                                                        <Form.Group controlId="enterdimention">
                                                            <Form.Control
                                                                type="text"
                                                                name="salesExecutive"
                                                                value={loc.salesExecutive}
                                                                readOnly
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3} className="project_body" >
                                                        {loc?.productList?.length > 0 ? <button className='view_po_btn' onClick={() => this.setContent(this.state.openedItem[index], index)}>
                                                            {this.state.openedItem[index] ? "Hide" : "View"}
                                                        </button> : <></>}
                                                        <button className={loc?.productList?.length === 0 ? 'add_po_btn new' : 'add_po_btn'} onClick={() => this.productOpen(loc)}>
                                                            <img src={plus} alt=".../" />
                                                            Add Product
                                                        </button>
                                                        <div onClick={() => this.projectOpen(loc)} className="edit_trash" style={{ margin: "0 10px" }}>
                                                            <img src={edit} alt="" />
                                                        </div>
                                                        <div className="del_po" onClick={() => this.deleteItemOpen(loc)}>
                                                            <img src={del} alt="" />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                {this.state.openedItem[index] ?
                                                    <>
                                                        <ViewProducts
                                                            data={loc.productList}
                                                            purchaseId={this.props.match.params.id}
                                                            handleShow={this.productHandler}
                                                        />
                                                        <div className='blue_body' id="table-bluee" style={{ width: "103%", marginBottom: "20px" }}>
                                                            <button onClick={() => {
                                                                this.productOpen(loc);
                                                            }} className="group_product_btn" style={{ marginRight: "10px" }}>
                                                                <img src={plus2} alt="" />
                                                                Add Product</button>
                                                            <button className="group_product_btns" onClick={() => this.customProductOpen(loc)}><img src={plus2} alt="" />Add Custom Product</button>
                                                        </div>
                                                    </>
                                                    : <></>}
                                            </div>
                                        )
                                    }) : <></>}
                                    <form onSubmit={handleSubmit(this.createProject)} >
                                        <Row >
                                            <Col md={3}>
                                                <label></label>
                                                <Select
                                                    onChange={this.changeHandler}
                                                    options={projects}
                                                    isSearchable={true}
                                                    placeholder="Select Project..."
                                                />
                                            </Col>
                                            <Col md={3}>
                                                <Field name="vertexId" component={renderSelectField} className="form-control form-select" disabled={this.state.projectName === "Various Projects  " ? true : false}>
                                                    <option value="" disabled={true}>Select one Vertices *</option>
                                                    {this.props.verticesDropdown?.map(unit =>
                                                        <option value={unit.vertexId} key={unit.vertexId}>{unit.vertexName}</option>)}
                                                </Field>
                                                <div className="triangle-bottom"></div>
                                            </Col>
                                            <Col md={3}>
                                                <Field name="salesExecutiveId" component={renderSelectField} className="form-control form-select" disabled={this.state.projectName === "Various Projects  " ? true : false}>
                                                    <option value="" disabled={true}>Select one Sales Executive *</option>
                                                    {this.props.salesExecutives?.map(unit =>
                                                        <option value={unit.userId} key={unit.userId}>{unit.salesExecutiveName}</option>)}
                                                </Field>
                                                <div className="triangle-bottom"></div>
                                            </Col>
                                            <Col md={3}>
                                                {!load ? <button type="submit" className="po_btn"  >
                                                    <img src={check} alt="" /> Create Project
                                                </button> : <button type="button" className="po_btn"  >
                                                    Loading ...
                                                </button>}
                                            </Col>
                                        </Row>
                                    </form>
                                    <AddPoProduct
                                        show={productPopup}
                                        purchaseId={this.props.match.params.id}
                                        onHide={this.productClose}
                                        openPopup={this.productOpen}
                                        projectId={this.state.projectId}
                                        str={str}
                                        data={productItem}
                                    />
                                    <EditPoProject
                                        show={projectPopup}
                                        purchaseId={this.props.match.params.id}
                                        onHide={this.projectClose}
                                        initialValues={data}
                                        projectId={this.state.projectId}
                                        projects={projects}
                                        projectData={projectData}
                                    />
                                    <DeletePopUp
                                        show={deletePopup}
                                        onHide={this.deleteItemClose}
                                        deleteItem={deleteStr === "PO" ? this.deletePoHandler : this.deleteProjectHandler}
                                    />
                                    <PoCustomProducts
                                        show={customPopup}
                                        onHide={this.customProductClose}
                                        purchaseId={Number(this.props.match.params.id)}
                                        projectId={this.state.projectId}
                                        initialValues={productItem}
                                        data={productItem}
                                        str={str}
                                    />
                                    <VenderPoView
                                        data={status}
                                        show={editShow}
                                        item={poData}
                                        onHide={this.editVendorClose}
                                        goTo={this.goToPoPage}
                                        generate={this.generatePoHandler}
                                        delete={this.deleteItemOpen}
                                        sendMail={this.sendMailOpen}
                                        send={this.sendPohandler}
                                        clone={this.clonePoHandler}
                                        revise={this.revisePoHandler}
                                        verify={this.verifyShowPopup}
                                        cancel={this.cancelPoHandler}
                                        inwardOpen={this.inwardOpen}
                                    />
                                    <VerifyProducts
                                        show={verifyPopup}
                                        onHide={this.verifyClosePopup}
                                        data={poData}
                                    />
                                    <InwardProducts
                                        show={inwardPopup}
                                        id={Number(this.props.match.params.id)}
                                        onHide={this.inwardClose}
                                        listAllData={() => console.log("")}
                                    />
                                    <CloneStatus
                                        show={this.state.clonePopup}
                                        str={this.state.cloneStr}
                                        onHide={false}
                                    />
                                    <ClonePopup
                                        show={this.state.cloneShow}
                                        str={this.state.cloneStr}
                                        onHide={false}
                                        cloned={this.state.cloned}
                                    />
                                    <EmailPopup
                                        show={this.state.emailPopup}
                                        onHide={this.sendMailClose}
                                        onSubmit={this.sendPoMailSubmit}
                                        getCC={this.getCCs}
                                    />
                                </div>
                                <Row >
                                    <Col md={3} >
                                        <button className="cancel-btn" onClick={() => this.prevPage()} style={{ marginTop: "50px" }}>Cancel</button>
                                    </Col>
                                    <Col md={6} >
                                    </Col>
                                    <Col md={3} >
                                        <button type="button" disabled={projectData?.length === 0} onClick={() => this.nextPage()} className="modal-btn save" style={{ marginTop: "50px", marginLeft: "-25px" }}>Save & Proceed</button>
                                    </Col>
                                </Row>
                            </div>
                            <br />
                            <br />
                            <br />

                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        initialValues: {},
        poData: state.po.poData,
        ignoreFields: state.po.poProjectData.ignoreFieldList !== null ? state.po.poProjectData.ignoreFieldList : [],
        projectData: state.po.poProjectData,
        verticesDropdown: state.po.verticesDropdown,
        boqProjectDropdown: state.po.boqProjectDropdown,
        salesExecutives: state.qutation.salesExecutives,
        generateData: state.po.generateData,
        deletePo: state.po.deletePo,
        projectSuccess: state.po.projectSuccess,
        deletePoProject: state.po.deletePoProject,
        deletePoProduct: state.po.deletePoProduct,
        ignoreData: state.po.ignoreData,
        load: state.po.load,
        sendData: state.po.sendData,
        deletePo: state.po.deletePo,
        cloneData: state.po.cloneData,
        reviseData: state.po.reviseData,
        cancelData: state.po.cancelData,
        verifyData: state.po.verifyData,
        confirmQuotDropdown: state.boq.confirmQuotDropdown,
        convertProducts: state.po.convertProducts,
        poMailSuccess: state.po.poMailSuccess,
    }
}
const VendorDetailsForm = reduxForm({
    form: 'purchaseOrderFormRedux',
    destroyOnUnmount: false,
    enableReinitialize: true,
    // validate
})(ProductDetailsForm)

export default connect(mapStateToProps)(VendorDetailsForm);
