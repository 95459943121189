import { customerConstants } from "../constants";

export const getCustomersList = (pageNo, searchKey, sort) => {
    return {
        type: customerConstants.GET_CUSTOMERS,
        pageNo,
        searchKey,
        sort
    }
}
export const createCustomer = (formData) => {
    return {
        type: customerConstants.CREATE_CUSTOMER_INIT,
        formData
    }
}
export const getCustomer = (customerId) => {
    return {
        type: customerConstants.GET_CUSTOMER_INIT,
        customerId
    }
}
export const updateCustomer = (formData) => {
    return {
        type: customerConstants.UPDATE_CUSTOMER_INIT,
        formData
    }
}

export const clearValuesOfCustomer = () => {
    return {
        type: customerConstants.CLEAR_CUSTOMER_VALUES,
    }
}
export const changePage = () => {
    return {
        type: customerConstants.CHANGE_PAGE,
    }
}

export const clearValues = () => {
    return {
        type: customerConstants.CLEAR_CUSTOMER_VALUES,
    }
}