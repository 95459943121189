import { put, call, takeEvery } from "redux-saga/effects";
import { reportOaConstants } from "../constants";
import { getReportOaApi } from "../services";

export function* reportPurchaseSaga(payload) {
    try {
        const response = yield call(getReportOaApi, payload);
        yield put({ type: reportOaConstants.GET_REPORT_OA_SUCCESS, response });
    } catch (error) {
        yield put({ type: reportOaConstants.GET_REPORT_OA_FAILURE, error });
    }
}

export default function* reportOa() {
    yield takeEvery(reportOaConstants.GET_REPORT_OA, reportPurchaseSaga);
}