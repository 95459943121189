import React, { Fragment } from "react"
import ReactPaginate from "react-paginate";

const Pagination = ({ data = {}, onChange }) => {
    const {
        totalElements,
        totalPages,
        offset,
        numberOfElements,
        num
    } = data;

    const handlePageClick = (e) => {
        onChange(e.selected)
    }

    return <Fragment>
        {totalElements !== 0 || totalElements !== undefined ? <div className="pagi">
            <div className="count-one">
                <p className="page-page">Showing {(offset + 1) ?? 1} to {(numberOfElements !== 10 ? totalElements : numberOfElements * num) ?? 10} of {totalElements} entries</p>
            </div>
            <div className="count-two">
                <ReactPaginate
                    previousLabel="Previous"
                    nextLabel="Next"
                    breakLabel="..."
                    breakClassName="break-me"
                    pageCount={totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName="pagination"
                    activeClassName="active"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                />
            </div>
        </div> : null
        }
    </Fragment >
}

export default Pagination