import { fork } from "redux-saga/effects";
import authSaga from "./authSaga";
import staffSaga from "./staffSaga";
import qutationSaga from "./qutationSaga";
import productSaga from "./productionSaga";
import templateSaga from "./templateSaga";
import customerSaga from "./customerSaga";
import dashboardSaga from "./dashboardSaga";
import staffexecutiveSaga from "./staffexecutiveSaga";
import reportcustomer from "./reportcustomerSaga";
import unitSaga from "./unitSaga";
import setting from "./settingSaga";
import adminSaga from "./adminSaga";
import vendorSaga from "./vendorSaga";
import projectsSaga from "./projectsSaga";
import vendorpoSaga from "./vendorpoSaga";
import reportPurchase from "./reportPurchaseSaga";
import groupSaga from "./groupSaga";
import inwardSaga from "./inwardSaga";
import purchaseOrderSaga from "./purchaseOrderSaga";
import outwardSaga from "./outwardSaga";
import boqSaga from "./boqSaga";
import deliverSaga from "./deliverSaga";
import reportOa from "./reportOaSaga";

export default function* rootSaga() {
  yield fork(authSaga);
  yield fork(staffSaga);
  yield fork(productSaga);
  yield fork(qutationSaga);
  yield fork(templateSaga);
  yield fork(customerSaga);
  yield fork(dashboardSaga);
  yield fork(staffexecutiveSaga);
  yield fork(reportcustomer);
  yield fork(unitSaga);
  yield fork(setting);
  yield fork(adminSaga);
  yield fork(vendorSaga);
  yield fork(projectsSaga);
  yield fork(vendorpoSaga);
  yield fork(boqSaga);
  yield fork(reportPurchase);
  yield fork(groupSaga);
  yield fork(inwardSaga);
  yield fork(purchaseOrderSaga);
  yield fork(outwardSaga);
  yield fork(deliverSaga);
  yield fork(deliverSaga);
  yield fork(reportOa)
}
