const validate = values => {
    const errors = {}
    const requiredFields = [
        'productName',
        'thresholdValue',
        'unitId'
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Please fill the field *'
        }
    })

    // if ((values.productName && /[^a-zA-Z0-9- !@#$%*?()/,.:]/.test(values.productName))) {
    //     errors.productName = "Not Valid *";
    // }
    // if (values?.productName?.trim().length === 0) {
    //     errors.productName = "Please fill the field * ";
    //   }
    if (!values.newPrice) {
        errors.newPrice = 'Please fill the field * '
    } if ((values.newPrice && /[^0-9.\\s]/gi.test(values.newPrice))) {
        errors.newPrice = 'only digits allowed * '
    } if ((values.thresholdValue && /[^0-9\\s]/gi.test(values.thresholdValue))) {
        errors.thresholdValue = 'only digits allowed * '
    } 
    // if ((values.articleId && /[^a-zA-Z0-9- !@#$%*?/(),.:]/i.test(values.articleId))) {
    //     errors.articleId = 'Not Valid * '
    // }
    // if (!values.imageUrl) {
    //     errors.imageUrl = 'Please Upload a Product Photo *'
    // }
    return errors
}

export default validate